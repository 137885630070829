import { tutorialsActions } from "src/features/tutorials/shared/state/slice";
import { TutorialName } from "src/features/tutorials/shared/state/types";

export const completeStreamsSwipesTutorial = () =>
  tutorialsActions.completeTutorial(TutorialName.STREAMS_SWIPES);

export const startStreamsSwipesTutorial = () =>
  tutorialsActions.startTutorial(TutorialName.STREAMS_SWIPES);

export const completeWebToAppClickToDownloadTutorial = () =>
  tutorialsActions.completeTutorial(TutorialName.WEB_TO_APP_CLICK_TO_DOWNLOAD);
