/* eslint-disable react/jsx-no-literals */
import React, { memo } from "react";
import { Link } from "react-router-dom";
import {
  linkToArtistsClub,
  linkToBroadcasterAgreement,
  linkToCommunityGuidelines,
  linkToCopyright,
  linkToFAQ,
  linkToPrivacyPolicy,
} from "src/ui/navigation/links";

const LinkToPrivacyPolicy: React.FC = ({ children = "Privacy Policy" }) => (
  <Link to={linkToPrivacyPolicy} target="_blank">
    {children}
  </Link>
);

const LinkToCopyright = memo(({ children = "Copyright Policy" }) => (
  <Link to={linkToCopyright} target="_blank">
    {children}
  </Link>
));
LinkToCopyright.displayName = "LinkToCopyright";

const LinkToBroadcasterAgreement = memo(() => (
  <Link to={linkToBroadcasterAgreement} target="_blank">
    Broadcaster Terms
  </Link>
));
LinkToBroadcasterAgreement.displayName = "LinkToBroadcasterAgreement";

const LinkToCommunityGuidelines = memo(() => (
  <Link to={linkToCommunityGuidelines} target="_blank">
    Community Guidelines
  </Link>
));
LinkToCommunityGuidelines.displayName = "LinkToCommunityGuidelines";

const LinkToFAQ = memo(() => (
  <Link to={linkToFAQ} target="_blank">
    FAQ page
  </Link>
));
LinkToFAQ.displayName = "LinkToFAQ";

const LinkToArtistProgram = memo(() => (
  <Link to={linkToArtistsClub} target="_blank">
    Artist Program
  </Link>
));
LinkToCommunityGuidelines.displayName = "LinkToCommunityGuidelines";

const MailToSupport = memo(() => (
  <a href="mailto:support@tango.me">support@tango.me</a>
));
MailToSupport.displayName = "MailToSupport";

const MailToLegal = memo(() => (
  <a href="mailto:legal@tango.me">legal@tango.me</a>
));
MailToLegal.displayName = "MailToLegal";

const MailToTangoLive = memo(() => (
  <a href="mailto:live@tango.me">live@tango.me</a>
));
MailToTangoLive.displayName = "MailToTangoLive";

const LinkToTangoWebsite = memo(() => (
  <a href="https://www.tango.me/" target="_blank" rel="noopener noreferrer">
    https://www.tango.me/
  </a>
));
LinkToTangoWebsite.displayName = "LinkToTangoWebsite";

const MailToCopyright = memo(() => (
  <a href="mailto:copyright@tango.me">copyright@tango.me</a>
));
MailToCopyright.displayName = "MailToCopyright";

const LinkToTermsOfUse: React.FC = ({ children = "https://www.tango.me/" }) => (
  <a
    href="https://www.tango.me/terms-of-use"
    target="_blank"
    rel="noopener noreferrer nofollow"
  >
    {children}
  </a>
);

const LinkToGoogleCookies = memo(() => (
  <a
    href="https://support.google.com/chrome/answer/95647?hl=en"
    target="_blank"
    rel="noopener noreferrer nofollow"
  >
    Google Chrome
  </a>
));
LinkToGoogleCookies.displayName = "LinkToGoogleCookies";

const LinkToIECookies = memo(() => (
  <a
    href="https://support.microsoft.com/en-us/help/260971/description-of-cookies"
    target="_blank"
    rel="noopener noreferrer nofollow"
  >
    Internet Explorer
  </a>
));
LinkToIECookies.displayName = "LinkToIECookies";

const LinkToFirefoxCookies = memo(() => (
  <a
    href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
    target="_blank"
    rel="noopener noreferrer nofollow"
  >
    Mozilla Firefox
  </a>
));
LinkToFirefoxCookies.displayName = "LinkToFirefoxCookies";

const LinkToSafariDesktopCookies = memo(() => (
  <a
    href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
    target="_blank"
    rel="noopener noreferrer nofollow"
  >
    Safari (Desktop)
  </a>
));
LinkToSafariDesktopCookies.displayName = "LinkToSafariDesktopCookies";

const LinkToSafariMobileCookies = memo(() => (
  <a
    href="https://support.apple.com/en-us/HT201265"
    target="_blank"
    rel="noopener noreferrer nofollow"
  >
    Safari (Mobile)
  </a>
));
LinkToSafariMobileCookies.displayName = "LinkToSafariMobileCookies";

const LinkToAndroidCookies = memo(() => (
  <a
    href="https://support.google.com/nexus/answer/54068?visit_id=637249861772874734-2281104728&hl=en&rd=1"
    target="_blank"
    rel="noopener noreferrer nofollow"
  >
    Android Browser
  </a>
));
LinkToAndroidCookies.displayName = "LinkToAndroidCookies";

const LinkToIAB = memo(() => (
  <a
    href="https://www.iab.com/"
    target="_blank"
    rel="noopener noreferrer nofollow"
  >
    The Interactive Advertising Bureau (US)
  </a>
));
LinkToIAB.displayName = "LinkToIAB";

const LinkToIABEurope = memo(() => (
  <a
    href="https://iabeurope.eu/"
    target="_blank"
    rel="noopener noreferrer nofollow"
  >
    The Interactive Advertising Bureau (EU)
  </a>
));
LinkToIABEurope.displayName = "LinkToIABEurope";

const LinkToYourOnlineChoices = memo(() => (
  <a
    href="https://www.youronlinechoices.com/"
    target="_blank"
    rel="noopener noreferrer nofollow"
  >
    European Interactive Digital Advertising Alliance (EU)
  </a>
));
LinkToYourOnlineChoices.displayName = "LinkToYourOnlineChoices";

const LinkToFTCTestimonialsAndEndorsements = memo(() => (
  <a
    href="https://www.ftc.gov/sites/default/files/attachments/press-releases/ftc-publishes-final-guides-governing-endorsements-testimonials/091005revisedendorsementguides.pdf"
    target="_blank"
    rel="noreferrer noopener"
  >
    Guidelines Concerning the Use of Testimonials and Endorsements in
    Advertising
  </a>
));
LinkToFTCTestimonialsAndEndorsements.displayName =
  "LinkToFTCTestimonialsAndEndorsements";

const LinkToFTCDisclosuresGuide = memo(() => (
  <a
    href="https://www.ftc.gov/system/files/documents/plain-language/bus41-dot-com-disclosures-information-about-online-advertising.pdf"
    target="_blank"
    rel="noreferrer noopener"
  >
    Disclosures Guide
  </a>
));
LinkToFTCDisclosuresGuide.displayName = "LinkToFTCDisclosuresGuide";

const LinkToFTCNativeAdvertising = memo(() => (
  <a
    href="https://www.ftc.gov/tips-advice/business-center/guidance/native-advertising-guide-businesses"
    target="_blank"
    rel="noreferrer noopener"
  >
    Native Advertising Guidelines
  </a>
));
LinkToFTCNativeAdvertising.displayName = "LinkToFTCNativeAdvertising";

const LinkToEuropeanAdequacyDecisions = memo(() => (
  <a
    href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en"
    target="_blank"
    rel="noopener noreferrer nofollow"
  >
    https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en.
  </a>
));
LinkToEuropeanAdequacyDecisions.displayName = "LinkToEuropeanAdequacyDecisions";

const LinkToOFACListSanctions = memo(() => (
  <a
    href="https://home.treasury.gov/policy-issues/financial-sanctions/sanctions-programs-and-country-information"
    target="_blank"
    rel="noopener noreferrer nofollow"
  >
    OFAC list sanctions
  </a>
));
LinkToOFACListSanctions.displayName = "LinkToOFACListSanctions";

const LinkToEurLex = memo(() => (
  <a
    href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?uri=CELEX:32021D0914&locale=en"
    target="_blank"
    rel="noopener noreferrer nofollow"
  >
    https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?uri=CELEX:32021D0914&locale=en.
  </a>
));
LinkToEurLex.displayName = "LinkToEurLex";

export {
  LinkToAndroidCookies,
  LinkToArtistProgram,
  LinkToBroadcasterAgreement,
  LinkToCommunityGuidelines,
  LinkToCopyright,
  LinkToEurLex,
  LinkToEuropeanAdequacyDecisions,
  LinkToFAQ,
  LinkToFTCDisclosuresGuide,
  LinkToFTCNativeAdvertising,
  LinkToFTCTestimonialsAndEndorsements,
  LinkToFirefoxCookies,
  LinkToGoogleCookies,
  LinkToIAB,
  LinkToIABEurope,
  LinkToIECookies,
  LinkToOFACListSanctions,
  LinkToPrivacyPolicy,
  LinkToSafariDesktopCookies,
  LinkToSafariMobileCookies,
  LinkToTangoWebsite,
  LinkToTermsOfUse,
  LinkToYourOnlineChoices,
  MailToCopyright,
  MailToLegal,
  MailToSupport,
  MailToTangoLive,
};
