import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import loadProfileWithStream from "state/flows/loadProfileWithStream";
import { profilesCacheSelectors, streamsCacheSelectors } from "state/selectors";

export default (
  nickname: string,
  accountId: string | undefined,
  withErrorCatch = true
) => {
  const dispatch = useDispatch();
  const profileId = useSelector((state) =>
    profilesCacheSelectors.getProfileIdByNickname(state, nickname)
  );
  const streamId = useSelector((state) =>
    streamsCacheSelectors.getLivingPublicOrPrivateStreamIdOfBroadcaster(
      state,
      accountId || profileId || ""
    )
  );

  useEffect(() => {
    if (accountId) {
      return;
    }
    dispatch(
      loadProfileWithStream({
        nickname,
        withErrorCatch,
      })
    );
  }, [nickname, accountId, withErrorCatch, dispatch]);

  return {
    profileId: accountId || profileId,
    streamId,
  };
};
