import React, { forwardRef } from "react";
import classnames from "classnames";
import { Breakpoints } from "src/features/ofac/imports/enums";
import {
  LegalNavigationAccordion,
  LegalNavigationSection,
  legalNavigationPrivacyPolicyConfig,
  legalNavigationTermsOfUseConfig,
  useBreakpointPrecise,
} from "src/features/ofac/imports/ui";
import styles from "./LegalNavigation.scss";

const restrictedLegalNavigationConfig = [
  legalNavigationTermsOfUseConfig,
  legalNavigationPrivacyPolicyConfig,
];

export const LegalNavigation = forwardRef<HTMLElement>((_, ref) => {
  const breakpoint = useBreakpointPrecise();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;

  const NavigationComponent = isDesktop
    ? LegalNavigationSection
    : LegalNavigationAccordion;

  return (
    <nav
      className={classnames(styles[breakpoint], styles.nav)}
      data-testid="legal-navigation"
      ref={ref}
    >
      <NavigationComponent navigationConfig={restrictedLegalNavigationConfig} />
    </nav>
  );
});

LegalNavigation.displayName = "LegalNavigation";
