import { datadogLogs } from "@datadog/browser-logs";
import { v4 as uuidv4 } from "uuid";
import { makeOneTimeScriptLoader } from "src/utils/scriptUtils";

const loadSiftSDK = makeOneTimeScriptLoader({
  src: "https://cdn.sift.com/s.js",
  nodeId: "sift-sdk-script",
});

export class SiftWrapper {
  sessionId?: string;
  userId?: string;

  private getSessionId(): string {
    if (!this.sessionId) {
      this.sessionId = `session_${uuidv4()}_${Date.now()}`;
    }

    return this.sessionId;
  }

  init(beaconKey: string) {
    loadSiftSDK()
      .then(() => {
        if (window._sift) {
          window._sift.push(["_setAccount", beaconKey]);
          window._sift.push(["_setSessionId", this.getSessionId()]);
          window._sift.push(["_trackPageview"]);

          this.setUserId();
        } else {
          throw new Error("Sift SDK not found in window");
        }
      })
      .catch((error) => {
        datadogLogs.logger.error(
          "Failed to initialize Sift",
          { userId: this.userId, error },
          error
        );
      });
  }

  setUserId(userId?: string) {
    if (userId) {
      this.userId = userId;
    }

    if (window._sift && this.userId) {
      window._sift.push(["_setUserId", this.userId]);
    }
  }

  unsetUserId() {
    this.userId = undefined;

    if (window._sift) {
      window._sift.push(["_unsetUserId"]);
    }
  }
}

const siftWrapper = new SiftWrapper();

export default siftWrapper;
