import React from "react";
import ReactDOM from "react-dom";
import { IntlProvider as ReactIntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PersistGate } from "redux-persist/integration/react";
import setupAppLovin from "@analytics/setupAppLovin";
import setupAppsFlyer from "@analytics/setupAppsFlyer";
import setupDatadog from "@analytics/setupDatadog/setupDatadog";
import setupFB from "@analytics/setupFB";
import setupGtm from "@analytics/setupGtm";
import setupMSAds from "@analytics/setupMSAds";
import setupSnapchat from "@analytics/setupSnapchat";
import setupTikTok from "@analytics/setupTikTok";
import setupTwitter from "@analytics/setupTwitter";
import {
  getGoogleClientId,
  getIsResilientToDomMutationsWorkaroundEnabled,
  getSnapchatPixelId,
  getTiktokPixelId,
  loadEnvironment,
} from "environment";
import parseSearchParams from "src/analytics/parseSearchParams/parseSearchParams";
import { SnapchatMethods } from "src/core/analytics/enumsSnapchat";
import { setAppsFlyerOneLinkURL } from "src/core/analytics/utils/analyticsAppsFlyerOneLinkURL";
import { AutoLoginGate } from "src/features/autoLogin/exports/components";
import {
  CountryAccessError,
  checkCountryAccess,
} from "src/features/ofac/exports/api";
import { RestrictedApp } from "src/features/ofac/exports/components";
import { pickLocale } from "src/loadTranslations";
import configureStore from "src/state/configureStore";
import App from "src/ui/App";
import acmeCheckLostMessage from "src/utils/acmeCheckLostMessage";
import { setInMemoryStore } from "src/utils/getStoreHandler";
import { initializeLocalStorage } from "src/utils/initializeLocalStorage";
import resilientToDomMutationsWorkaround from "src/utils/resilientToDomMutationsWorkaround";
import { detectDeviceType, setDeviceLocale } from "state/flows/deviceInfo";
import { BreakpointProvider } from "ui/Breakpoint";
import ErrorBoundary from "ui/ErrorBoundary";
import IntlProvider from "ui/IntlProvider";
import ErrorView from "ui/common/errorView/ErrorView";
import setupIntercom from "ui/intercom/setupIntercom";
import validateSessionDetails from "utils/validateSession";
import "generated/modernizr";
import "./index.scss";

const twcVersion = `${GENERATED_APP_INFO.fullVersion}`;
window.twcVersion = twcVersion;
console.log(`Tango Web Client v${twcVersion}`); // eslint-disable-line no-console
const locale = pickLocale();

initializeLocalStorage();

const loadEnvironmentPromise = loadEnvironment();

loadEnvironmentPromise
  .then(checkCountryAccess)
  .then(async () => {
    const isProd = process.env.NODE_ENV !== "development";
    setupDatadog();

    if (isProd) {
      setupGtm();

      if (getIsResilientToDomMutationsWorkaroundEnabled()) {
        resilientToDomMutationsWorkaround();
      }
      resilientToDomMutationsWorkaround();
    }

    await validateSessionDetails();

    const { store, persistor } = configureStore();

    acmeCheckLostMessage(store.dispatch, store.getState);
    parseSearchParams();

    store.dispatch(detectDeviceType());
    store.dispatch(setDeviceLocale(locale));
    store.subscribe(() => {
      setInMemoryStore(store.getState());
    });

    ReactDOM.render(
      <Provider store={store}>
        <GoogleOAuthProvider clientId={getGoogleClientId()}>
          <BreakpointProvider>
            <IntlProvider>
              <ErrorBoundary>
                <PersistGate loading={null} persistor={persistor}>
                  <AutoLoginGate>
                    <App />
                  </AutoLoginGate>
                </PersistGate>
              </ErrorBoundary>
            </IntlProvider>
          </BreakpointProvider>
        </GoogleOAuthProvider>
      </Provider>,
      document.getElementById("root")
    );

    setupAppsFlyer().then(() => {
      setAppsFlyerOneLinkURL();
    });

    if (isProd) {
      setupTikTok().then(() => {
        window.ttq?.load?.(getTiktokPixelId());
        window.ttq?.page?.();
      });
      setupIntercom();
      setupFB();
      setupSnapchat().then(() => {
        window.snaptr?.(SnapchatMethods.INIT, getSnapchatPixelId());
      });
      setupTwitter();
      setupMSAds();
      setupAppLovin();
    }
  })
  .catch((err) => {
    if (err instanceof CountryAccessError) {
      const { store } = configureStore();
      store.dispatch(detectDeviceType());
      store.dispatch(setDeviceLocale(locale));

      ReactDOM.render(
        <Provider store={store}>
          <BreakpointProvider>
            <IntlProvider>
              <RestrictedApp />
            </IntlProvider>
          </BreakpointProvider>
        </Provider>,
        document.getElementById("root")
      );

      return;
    }

    console.error("Failed to start up the app", err); //eslint-disable-line
    ReactDOM.render(
      <BreakpointProvider>
        <ReactIntlProvider textComponent="span" locale={locale}>
          <ErrorView testId="ui-error" />
        </ReactIntlProvider>
      </BreakpointProvider>,
      document.getElementById("root")
    );
  });
