import React, { Suspense, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import AnalyticsUtils, { USERNAME_KEY } from "@analytics/AnalyticsUtils";
import useExternalReferrer from "@analytics/externalReferrer/useExternalReferrer";
import { ScreenViewReportingContextProvider } from "@analytics/screenView/ScreenViewReportingContext";
import UserPropertiesReporter from "@analytics/userProperties/UserPropertiesReporter";
import BIHistoryListen from "src/core/analytics/components/BIHistoryListen";
import { WebViewController } from "src/core/webview/WebViewController";
import { AutoLoginSessionMonitor } from "src/features/autoLogin/exports/components";
import useVisitMarketing from "src/features/marketing/exports/hooks";
import { GoogleOneTapInitializer } from "src/features/signin/exports/components";
import lazy from "src/utils/lazyWithPageReload";
import { getCountryByIp } from "state/actionCreators/countryByIp";
import { loadTabsConfig } from "state/actionCreators/tabsConfig";
import { history } from "state/configureStore";
import { RootState } from "state/delegate";
import { applyConfigOverridesFromSearchString } from "state/flows/loadConfiguration";
import {
  connectionManagerSelectors,
  countryByIpSelectors,
  getLocaleCountryCode,
  hasValidAccount,
  loginSelectors,
  userSelectors,
  visitorSelectors,
} from "state/selectors";
import LpSoundTestController from "ui/LpSoundTestController";
import PaymentsTestController from "ui/PaymentsTestController";
import VideoTestController from "ui/VideoTestController";
import LazyBottomScreenController from "ui/bottomScreen/LazyBottomScreenController";
import CaptchaController from "ui/captcha/CaptchaController";
import CookiesDisclaimer from "ui/common/cookie/CookiesDisclaimer";
import BaseTitle from "ui/common/documentTitle/BaseTitle";
import { EmojiProvider } from "ui/common/emoji/EmojiContext";
import ErrorView from "ui/common/errorView/ErrorView";
import HelmetController from "ui/helmet/HelmetController";
import useAfterPurchaseTackManager from "ui/hooks/useAfterPurchaseTackManager";
import useLoadServerOwnedConfig from "ui/hooks/useLoadServerOwnedConfig";
import usePersistentState from "ui/hooks/usePersistentState";
import useSiftSetup from "ui/hooks/useSiftSetup";
import useSplashScreen from "ui/hooks/useSplashScreen";
import useBootIntercom from "ui/intercom/useBootIntercom";
import LazyModalController from "ui/modal/LazyModalController";
import { PurchaseToasterContextProvider } from "ui/modal/modalViews/buyCoins/PurchaseToasterContext";
import RootRouter from "ui/navigation/RootRouter";
import DeepLinkGate from "ui/navigation/deepLink/DeepLinkGate";
import TaskManager from "ui/navigation/deepLink/TaskManager";
import { MuteContextProvider } from "ui/scenes/stream/MuteContext";
import Snackbar from "ui/snackbar/Snackbar";
import { ToastController } from "ui/toast/ToastController/ToastController";
import VideoTokenLoader from "ui/videoTokenLoader/VideoTokenLoader";
import { useImpactConversionEmit } from "utils/impactSetupUtils";
import { ThemeProvider } from "./Theme";
import DeviceRotationWarning from "./common/DeviceRotationWarning";
import useDatadogRUMSetup from "./hooks/useDatadogRUMSetup";
import LoginGate from "./loginGate/LoginGate";
import NotificationsController from "./notification/NotificationsController";

const WebsocketController = lazy(
  () =>
    import(
      /* webpackChunkName: "websocket" */ "./websocket/WebsocketController"
    )
);

const UserDataUpdater = lazy(
  () =>
    import(
      /* webpackChunkName: "userDataUpdater" */
      "./userData/UserDataUpdater"
    )
);

const selector = (state: RootState) => ({
  hasValidAccount: hasValidAccount(state),
  isLoggedIn: loginSelectors.isLoggedIn(state),
  isLimitedSession: loginSelectors.isLimitedSession(state),
  isAuthorizationFailed: loginSelectors.isAuthorizationFailed(state),
  isAuthorized: loginSelectors.isAuthorized(state), // TODO: move to isLoggedIn once guest is disabled (https://tango-me.atlassian.net/browse/WEB-6577)
  locale: getLocaleCountryCode(state),
  accountId: userSelectors.getMyAccountId(state),
  username: connectionManagerSelectors.getUsername(state),
  countryByIpMeta: countryByIpSelectors.meta(state),
  countryByIpData: countryByIpSelectors.data(state),
  visitorData: visitorSelectors.data(state),
});

const App = () => {
  const dispatch = useDispatch();
  const {
    isLoggedIn,
    hasValidAccount,
    isAuthorized,
    isAuthorizationFailed,
    locale,
    accountId,
    isLimitedSession,
    countryByIpMeta,
    countryByIpData,
    username,
    visitorData,
  } = useSelector(selector, shallowEqual);
  const [storedUsername, setUsername] = usePersistentState({
    key: USERNAME_KEY,
    session: true,
    listenForUpdates: true,
    isSkipParse: true,
    restoreOldValue: true,
    initialValue: "",
  });
  useLoadServerOwnedConfig();
  useAfterPurchaseTackManager();
  useDatadogRUMSetup();
  useSiftSetup();

  useEffect(() => {
    AnalyticsUtils.updateForegroundId();
    AnalyticsUtils.updateInteractionId();

    dispatch(applyConfigOverridesFromSearchString());
  }, []);

  useEffect(() => {
    if (
      (isAuthorized || isLoggedIn) &&
      !countryByIpData &&
      !countryByIpMeta.loading
    ) {
      dispatch(getCountryByIp());
    }
  }, [
    countryByIpData,
    countryByIpMeta.loading,
    dispatch,
    isAuthorized,
    isLoggedIn,
  ]);

  useEffect(() => {
    if (storedUsername !== username) {
      setUsername(username);
    }
  }, [username, storedUsername]);

  useBootIntercom();
  useExternalReferrer();
  useSplashScreen(
    !isAuthorizationFailed && !hasValidAccount && !visitorData.isVisitor
  );
  useEffect(() => {
    if (!countryByIpMeta.stale && !countryByIpMeta.loading) {
      dispatch(loadTabsConfig({ locale }));
    }
  }, [locale, accountId, countryByIpMeta.stale, countryByIpMeta.loading]);

  useImpactConversionEmit();
  useVisitMarketing();

  if (isAuthorizationFailed) {
    return <ErrorView testId="authorization-error-view" />;
  }

  return (
    <ThemeProvider>
      <EmojiProvider>
        <UserPropertiesReporter />
        <ScreenViewReportingContextProvider>
          <ConnectedRouter history={history}>
            <BIHistoryListen />
            <LoginGate>
              <AutoLoginSessionMonitor />
              <HelmetController />
              <WebViewController />
              <GoogleOneTapInitializer />
              <PaymentsTestController />
              <VideoTestController />
              <LpSoundTestController />
              {isLoggedIn && <VideoTokenLoader />}
              <Snackbar>
                <PurchaseToasterContextProvider>
                  <DeepLinkGate>
                    <MuteContextProvider>
                      <RootRouter />
                    </MuteContextProvider>
                    <BaseTitle />
                    <LazyModalController />
                    <LazyBottomScreenController />
                    <Suspense fallback={null}>
                      <WebsocketController />
                    </Suspense>
                    <NotificationsController />
                    <ToastController />
                    <CookiesDisclaimer />
                    <DeviceRotationWarning />
                    {isLoggedIn && hasValidAccount && !isLimitedSession && (
                      <Suspense fallback={null}>
                        <UserDataUpdater />
                      </Suspense>
                    )}
                    <CaptchaController />
                    <TaskManager />
                  </DeepLinkGate>
                </PurchaseToasterContextProvider>
              </Snackbar>
            </LoginGate>
          </ConnectedRouter>
        </ScreenViewReportingContextProvider>
      </EmojiProvider>
    </ThemeProvider>
  );
};

export default App;
