import { getProxycadorHost } from "environment";
import fetch from "./utils/enhancedFetch";

export interface UploadedMedia {
  contentType: string;
  mediaId: string;
  thumbnailUrl: string;
  url: string;
}

export const uploadImage = ({
  image,
  thumbnail,
}: {
  image: Blob;
  thumbnail: Blob;
}): Promise<UploadedMedia> => {
  const formData = new FormData();
  formData.append("file", image);
  formData.append("thumbnail", thumbnail);
  return fetch(`${getProxycadorHost()}/proxycador/api/public/v1/upload`, {
    method: "POST",
    body: formData,
    credentials: "include",
  }).then((res) => res.json());
};

export const uploadVideo = async (file: File): Promise<UploadedMedia> => {
  const formData = new FormData();
  formData.append("file", file);

  return fetch(`${getProxycadorHost()}/proxycador/api/public/v1/upload`, {
    method: "POST",
    body: formData,
    credentials: "include",
  }).then((res) => res.json());
};
