export const LINK_FROM_WEB_CHAT_PARAM = "linkFromWebChatParam";
const LINK_FROM_WEB_PARAM = "linkFromWebParam";
const TRACKING_ID = "tracking_id";

export const getSearchParam = (param: string) =>
  new URLSearchParams(window.location.search).get(param);

const emulateStandaloneMode =
  getSearchParam("emulateStandaloneMode") === "true";
// @ts-ignore available in ios safari only, missing in types;
const isIosStandalone: boolean = window.navigator.standalone;
const isAndroidStandalone =
  window.matchMedia && window.matchMedia("(display-mode: standalone)").matches;

export const isStandalone =
  isIosStandalone || isAndroidStandalone || emulateStandaloneMode;

export const cleanupQueryWithKeys = (keys: string[]) => {
  const params = new URLSearchParams(document.location.search);
  keys.forEach((x) => params.delete(x));
  history.replaceState(
    null,
    "",
    `${document.location.pathname}${params.toString() ? `?${params}` : ""}`
  );
};

export const addOpenFromWebParam = (url: URL) => {
  url.searchParams.append(LINK_FROM_WEB_PARAM, "1");

  return url;
};

export const addOpenFromWebChatParam = (url: URL) => {
  url.searchParams.append(LINK_FROM_WEB_CHAT_PARAM, "1");

  return url;
};

export const addTriggerIdParam = (url: URL, value: string) => {
  url.searchParams.append(TRACKING_ID, value);

  return url;
};

export const isLinkOpenedFromWeb = (link: string) =>
  new URL(link).searchParams.get(LINK_FROM_WEB_PARAM) !== null;
