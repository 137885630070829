import { defineMessages } from "react-intl";

export const messages = defineMessages({
  termsOfUse: {
    id: "legalNav.termsOfUse",
    defaultMessage: "Terms of Use",
  },
  privacyPolicy: {
    id: "legalNav.privacyPolicy",
    defaultMessage: "Privacy Policy",
  },
  copyright: {
    id: "legalNav.copyright",
    defaultMessage: "Copyright",
  },
  thirdPartyLicense: {
    id: "legalNav.thirdPartyLicense",
    defaultMessage: "Third Party License",
  },
  broadcasterAgreement: {
    id: "legalNav.broadcasterAgreement",
    defaultMessage: "Broadcaster Agreement",
  },
  communityGuidelines: {
    id: "legalNav.communityGuidelines",
    defaultMessage: "Community Guidelines",
  },
  publisherTermsAndConditions: {
    id: "legalNav.publisherTermsAndConditions",
    defaultMessage: "Publisher Terms & Conditions",
  },
  bugBountyProgram: {
    id: "legalNav.bugBountyProgram",
    defaultMessage: "Bug Bounty Program",
  },
  dsaInformation: {
    id: "legalNav.dsaInformation",
    defaultMessage: "DSA Statement",
  },
  faq: {
    id: "legalNav.faq",
    defaultMessage: "Frequently Asked Questions",
  },
});
