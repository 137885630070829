import { MessageConfig } from "chat/exports/types";

export const getGroupMessageClassnames = (
  styles: Record<string, string>,
  messageConfig?: MessageConfig
) => {
  if (!messageConfig) {
    return {};
  }

  const { isMyMessage, isFirstInGroup, isLastInGroup } = messageConfig;

  return {
    [styles.firstInGroup]: isFirstInGroup,
    [styles.lastInGroup]: isLastInGroup,
    [styles.myMessage]: isMyMessage,
  };
};
