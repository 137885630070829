import { createReducer } from "@reduxjs/toolkit";
import { createTransform } from "redux-persist";
import { PersistState, PersistedState } from "redux-persist/es/types";
import { ConfigurationStateKey } from "src/enums";
import * as configurationActions from "../actionCreators/configuration";

export interface ConfigurationState {
  isServerConfigFailed: boolean;
  isServerConfigLoaded: boolean;
  isServerConfigLoading: boolean;
  overrideConfig: Record<string, string>;
  serverConfig: Record<string, string>;
}

type InboundConfigurationState =
  | ConfigurationState[keyof ConfigurationState]
  | PersistState;

type InboundStateKey = keyof (ConfigurationState & PersistedState);

const isPersistState = (
  state: InboundConfigurationState,
  key: InboundStateKey
): state is PersistState => key === "_persist";

const whiteListTransform = createTransform(
  (inboundState: InboundConfigurationState, key: InboundStateKey) => {
    if (isPersistState(inboundState, key)) {
      return inboundState;
    }

    if (key === ConfigurationStateKey.SERVER_CONFIG) {
      return {
        "live.sort.algorithm.version": (
          inboundState as ConfigurationState[ConfigurationStateKey.SERVER_CONFIG]
        )["live.sort.algorithm.version"],
      };
    }

    if (key === ConfigurationStateKey.IS_SERVER_CONFIG_LOADED) {
      return false;
    }
  }
);

export const persistConfig = { transforms: [whiteListTransform] };

export default createReducer<ConfigurationState>(
  {
    serverConfig: {},
    overrideConfig: {},
    isServerConfigLoaded: false,
    isServerConfigLoading: false,
    isServerConfigFailed: false,
  },
  (builder) => {
    builder
      .addCase(
        configurationActions.overrideServerOwnedConfig,
        (state, action) => {
          state.overrideConfig = Object.assign(
            state.overrideConfig,
            action.payload
          );
        }
      )
      .addCase(configurationActions.loadingServerOwnedConfig, (state) => {
        state.isServerConfigLoading = true;
      })
      .addCase(
        configurationActions.loadedServerOwnedConfig,
        (state, action) => {
          state.serverConfig = action.payload;
          state.isServerConfigLoaded = true;
          state.isServerConfigLoading = false;
        }
      )
      .addCase(configurationActions.serverOwnedConfigFailed, (state) => {
        state.isServerConfigLoading = false;
        state.isServerConfigFailed = true;
      });
  }
);

export const selectors = {
  getConfigParamByKey: (
    state: ConfigurationState,
    key: string,
    defaultValue: string
  ) => state.overrideConfig[key] || state.serverConfig[key] || defaultValue,
  getIsServerConfigLoaded: (state: ConfigurationState) =>
    state.isServerConfigLoaded,
  getIsServerConfigLoading: (state: ConfigurationState) =>
    state.isServerConfigLoading,
  getIsServerConfigFailed: (state: ConfigurationState) =>
    state.isServerConfigFailed,
};
