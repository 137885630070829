import React, { memo } from "react";
import { SCREEN_NAME } from "@analytics/enums";
import { ScreenViewReporter } from "@analytics/screenView/ScreenViewReportingContext";
import FormattedMessageTitle from "ui/common/documentTitle/FormattedMessageTitle";
import { messages as legalMessages } from "./common/messages";
import { tableConfig } from "./common/tableConfigs";
import { PostDate } from "./components";
import {
  LinkToAndroidCookies,
  LinkToFirefoxCookies,
  LinkToGoogleCookies,
  LinkToIAB,
  LinkToIABEurope,
  LinkToIECookies,
  LinkToSafariDesktopCookies,
  LinkToSafariMobileCookies,
  LinkToTermsOfUse,
  LinkToYourOnlineChoices,
  MailToLegal,
} from "./components/links";
import Table from "./components/table/Table";
import { Headline, ListItem, Paragraph } from "./components/typography";
import styles from "./common/LegalArticle.scss";

const { personalInformationCategories, processingPersonInformation } =
  tableConfig.privacyPolicy;

/* eslint-disable react/jsx-no-literals */
const PrivacyPolicy: React.FC = () => (
  <>
    <ScreenViewReporter name={SCREEN_NAME.LEGAL_PRIVACY_POLICY} />
    <FormattedMessageTitle {...legalMessages.privacyPolicy} />

    <article className={styles.article}>
      <PostDate>Last Modified: 29.06.2022</PostDate>

      <Headline level={1}>Privacy Policy</Headline>

      <Headline level={2}>Introduction.</Headline>
      <Paragraph>
        TangoMe Inc. (“Company”, “we”, “our” or “us”), is the operator of the
        website: <LinkToTermsOfUse /> and its subdomains ( “Website” ), the
        application Tango (the “App”) and any related features (collectively,
        the “Services”).
      </Paragraph>
      <Paragraph>
        Company is committed to protecting your privacy rights and making our
        practices regarding processing of Personal Information (as defined
        below) more transparent and fair.
      </Paragraph>
      <Paragraph>
        This Privacy Policy (“Policy” ) was designed to help you understand the
        information we collect, store, use and share, and it applies whenever
        you visit our Website, our App or otherwise use or access our Services.
      </Paragraph>
      <Paragraph>
        You are not legally required to provide us with any Personal
        Information, but without it we will not be able to provide you with the
        full range or with the best experience of using our Services.
      </Paragraph>
      <Paragraph>
        This Privacy Policy is integrated into and forms part of the Company's{" "}
        <LinkToTermsOfUse>Terms of Use</LinkToTermsOfUse>, and is incorporated
        therewith by reference. Any term not defined herein, shall have the
        meaning ascribed to it under the Terms of Use.
      </Paragraph>
      <Paragraph>
        We strongly urge you to read this Policy and make sure that you fully
        understand and agree to it. By using the Services, including our Website
        or App, you signify your acceptance of this Policy. If you do not agree
        to the terms of this Policy, please do not use our Services. Your
        continued use of the Services following the posting of changes to this
        Policy will mean that you accept such changes.
      </Paragraph>

      <Headline level={2}>What Types of Data We Collect?</Headline>
      <Paragraph>
        We collect two types of data from you: personal information ( "Personal
        Information") and non-Personal Information. Personal Information means
        any information which may potentially allow your identification with
        reasonable means (for example, email address or name). Non-Personal
        Information, by contrast, can be defined as any information that does
        not relate to an identified or identifiable natural person. This may
        include, for example, your aggregated usage information and technical
        information transmitted by your device (for example, the device you use,
        the type of browser and operating system your device uses, language
        preference, access time, etc.). This section sets out how and when we
        collect those types of data from you.
      </Paragraph>
      <Paragraph subParagraph>Personal information.</Paragraph>
      <ul className={styles.ul}>
        <ListItem>
          Registration Information. During your registration to the Services we
          request certain personal information from you, including your name,
          email address, mobile phone number, social media identifiers and your
          contacts details.
        </ListItem>
        <ListItem>
          Contact list. We periodically access your contact list or address book
          on your mobile device to find and keep track of mobile phone numbers
          of other users of the Service, based on your permission.
        </ListItem>
        <ListItem>
          Payment Information. When you decide to make a purchase or withdraw
          funds from our Services, you will be required to provide us with your
          billing information. The information you will need to submit depends
          on which billing method you choose. For example, if you pay with a
          credit card, we will collect your card information.
        </ListItem>
        <ListItem>
          Governmental-issued ID. In certain instances, you may be required to
          provide a copy of a governmental-issued ID, for identity verification
          process. Please note that this information is required for certain
          features of our Services.
        </ListItem>
        <ListItem>
          Device identifiers and device data. When you visit or access the
          Services, we also collect your IP address, UDID, your precise
          geolocation and other information transmitted by your device,
          including among other things the type of browser and operating system
          your device uses.
        </ListItem>
        <ListItem>
          Log-in history and usage information. In order to enhance the
          functionality of the Services and to provide you with a better user
          experience, we collect technical information transmitted by your
          device when you use the Services, including information related to
          your behavior and use of the Services.
        </ListItem>
        <ListItem>
          Location information. The Company collects information about your
          general location (such as city and country). For example, we may use
          the IP address to identify your general location. This information
          does not tell us where your device is precisely located. This
          information is sent as a normal part of internet traffic. In addition,
          we also collect implicit location information, which allows us to
          infer that you are either interested in a place or that you might be
          at the place – this information does not actually tell us where your
          device is precisely located. In addition, the Company collects the
          precise location of your device (using GPS signals, device sensors,
          Wi-Fi access points, Bluetooth signals, Beacons signals and cell tower
          ids that can be used to derive or estimate precise location, or other
          geo-location data), when location services have been enabled by the
          end user (you typically have to choose to turn on device-based
          location services).
        </ListItem>
        <ListItem>
          Communication Information. When you send us an email or contact us via
          the support in our Services, we collect the Personal Information you
          provide us. This may include your name, email address and any other
          information you choose to provide.
        </ListItem>
      </ul>
      <Paragraph subParagraph>Non Personal Information.</Paragraph>
      <Paragraph>
        We process, use or share non-Personal Information, aggregated
        information or Personal Information in non-human readable form (e.g.
        anonymous or aggregated information concerning the way our user
        community interacts with the Services) in any of the above
        circumstances, as well as for the purpose of providing and improving our
        Services, aggregate statistics, marketing purposes and conduct business
        and marketing analysis, and enhance your experience with the Service.
      </Paragraph>
      <Paragraph>
        We may anonymize or de-identify the information collected by the
        Services or via other means so that the information cannot, on its own,
        personally identify you. Our use and disclosure of such aggregated or
        de-identified information is not subject to any restrictions under this
        Policy, and we may disclose it to others without limitation and for any
        purpose, such as for advertising or marketing purposes.
      </Paragraph>
      <Paragraph>
        If we combine Personal Information with Anonymous Information, the
        combined information will be treated as Personal Information for as long
        as it remains combined.
      </Paragraph>

      <Headline level={2}>Tracking Technologies.</Headline>
      <Paragraph>
        When you visit or access our Services, we use cookies, pixels, beacons,
        local storage and similar technologies ( "Tracking Technologies"). These
        allow us to automatically collect information about you, your device,
        and your online behavior, in order to enhance your navigation in our
        Services, improve our Service's performance, perform analytics,
        customize your experience and offer you, for example, tailored content
        and advertisements that better correspond with your interests.
      </Paragraph>
      <Paragraph subParagraph>
        What types of Tracking Technologies do we use?
      </Paragraph>
      <ol className={styles.alphabeticalBracketed}>
        <ListItem>
          Strictly Necessary Tracking Technologies – these Tracking Technologies
          are automatically placed on your computer or device when you access
          our Services or take certain actions on our App or Website. These
          Tracking Technologies are essential to enable you to navigate around
          and use the features of our Services. We do not need to obtain your
          consent in order to use these Tracking Technologies;
        </ListItem>
        <ListItem>
          Tracking and Advertising Tracking Technologies – these Tracking
          Technologies collect information about your browsing habits in order
          to make advertising more relevant to you and your interests. They are
          also used to limit the number of times you see an advertisement as
          well as help measure the effectiveness of an advertising campaign. The
          Tracking Technologies remember the websites you visit and that
          information is shared with other parties such as advertisers and/or
          publishers. Publishers, advertisers, and third-party ad networks may
          also utilize Tracking Technologies or similar technologies to deliver
          ads and monitor the performance of such ads. The collection of
          information through Tracking Technologies by such third parties will
          be governed by their own privacy policies/cookies policies and
          principles, which the Company does not control;
        </ListItem>
        <ListItem>
          Functionality Tracking Technologies – these Tracking Technologies
          allow our Services to remember choices you make (such as your
          language) and provide enhanced and personalized features. For example,
          these Tracking Technologies are used for authentication (to remember
          when you are logged-in) and support other features of our Services;
        </ListItem>
        <ListItem>
          Performance Tracking Technologies – these Tracking Technologies
          collect information about your online activity (for example the
          duration of your visit on our Services), including behavioural data
          and content engagement metrics. These Tracking Technologies are used
          for analytics, research and to perform statistics (based on aggregated
          information).
        </ListItem>
      </ol>
      <Paragraph subParagraph>
        How and by whom Tracking Technologies are stored on your device?
      </Paragraph>
      <Paragraph>
        We store Tracking Technologies on your device when you visit or access
        our Services (for example, when you are visiting our Website) – these
        are called "First Party Tracking Technologies". In addition, Tracking
        Technologies are stored by other third parties (for example, our
        analytics service providers, business partners, and advertisers), who
        run content on our Services – these are called "Third Party Tracking
        Technologies". Both types of Tracking Technologies may be stored either
        for the duration of your visit on our Services or for repeat visits.
      </Paragraph>
      <Paragraph>
        There are various ways in which you can manage and control your Tracking
        Technologies settings. You can change your preferences using our cookie
        settings tool (however, please note that this tool may only be available
        in certain jurisdictions). Other methods of managing your Tracking
        Technology preferences includes: changing your browser settings to send
        a “Do-Not-Track” signal. In such case, your browser will send us a
        special signal to stop tracking your activity; However, please note that
        certain features of the Website may not work properly or effectively if
        you delete or disable cookies.
      </Paragraph>
      <Paragraph>
        To learn more about how can manage your cookies, below is a list of
        useful links that can provide you with more information on how to manage
        your cookies:
      </Paragraph>
      <ul className={styles.ul}>
        <ListItem>
          <LinkToGoogleCookies />
        </ListItem>
        <ListItem>
          <LinkToIECookies />
        </ListItem>
        <ListItem>
          <LinkToFirefoxCookies />
        </ListItem>
        <ListItem>
          <LinkToSafariDesktopCookies />
        </ListItem>
        <ListItem>
          <LinkToSafariMobileCookies />
        </ListItem>
        <ListItem>
          <LinkToAndroidCookies />
        </ListItem>
      </ul>
      <Paragraph>
        You can learn more and turn off certain third party targeting and
        advertising cookies by visiting the following third party webpages:
      </Paragraph>
      <ul className={styles.ul}>
        <ListItem>
          <LinkToIAB />
        </ListItem>
        <ListItem>
          <LinkToIABEurope />
        </ListItem>
        <ListItem>
          <LinkToYourOnlineChoices />
        </ListItem>
      </ul>
      <Paragraph>
        You can withdraw your consent to personalized advertising experience on
        your device at any time by using your device settings as follows:
      </Paragraph>
      <ul className={styles.ul}>
        <ListItem>
          On iOS, depending on the applicable iOS version, you may withdraw
          consent across all apps by either enabling the “Limit Ad Tracking”
          setting or disabling the “Allow Apps to Request to Track” permission
          in your iOS device settings. Depending on the applicable iOS version,
          instead of withdrawing consent on a device-wide basis, you may also
          have the option of withdrawing consent on a per-app basis by disabling
          tracking permissions for specific apps that appear under the “Allow
          Apps to Request to Track” setting in your iOS device settings (precise
          directions may differ depending on the applicable iOS version).
        </ListItem>
        <ListItem>
          On Android devices, you may withdraw your consent in the Google Ads
          settings within your Android settings by enabling the “Opt out of Ads
          Personalization” setting (precise directions and the name of the
          setting may differ depending on the applicable Android versions and
          device manufacturer).
        </ListItem>
      </ul>

      <Headline level={2}>
        Conditions For Processing Personal Information.
      </Headline>
      <Paragraph>
        This section outlines the underlying purposes and legal bases for the
        processing of your Personal Information:
      </Paragraph>

      <Table
        theadData={processingPersonInformation.theadData}
        tbodyData={processingPersonInformation.tbodyData}
      />

      <Paragraph>
        Special Categories of Personal Information – our processing of your
        Personal Information may also involve special categories of personal
        data, such as your racial or ethnic origin. We will process such
        information, as well as disclose it to competent authorities (such as
        licensing bodies or law enforcement bodies), where it is necessary for
        the following purposes (to the extent permissible by applicable law):
        (i) prevention or detection of an unlawful act, (ii) prevention of
        dishonesty, malpractice or other seriously improper conduct, provided
        that obtaining your consent may prejudice those purposes.
      </Paragraph>

      <Headline level={2}>Data Sharing.</Headline>
      <Paragraph>
        We do not rent, sell, or share your Personal Information with third
        parties (“Recipients”) except as described in this Privacy Policy. The
        Personal Information will be disclosed to Recipients only to the extent
        required for the specific purpose, as stipulated in this Privacy Policy.
      </Paragraph>
      <Paragraph>
        We share Personal Information with any of the following recipients:
      </Paragraph>
      <ul className={styles.ul}>
        <ListItem>
          Any service provider that we engage with to operate the Service;
        </ListItem>
        <ListItem>Our affiliated companies;</ListItem>
        <ListItem>
          Subcontractors and third party service providers, as well as their
          subcontractors, which by way of example include (but is not limited
          to) cloud computing companies, marketing affiliates, fraud prevention
          services, and other data verifiers;
        </ListItem>
        <ListItem>Payment service providers and payment processors;</ListItem>
        <ListItem>
          Auditors, contractors or legal/financial/other advisers of any of our
          business processes;
        </ListItem>
        <ListItem>
          Any third parties who investigate, detect or prevent fraudulent or
          illegal activity or enable us to enforce our policies, including in
          order to ascertain your source of income or funds (e.g. governmental
          authorities, law enforcement bodies, banks and other investigatory
          bodies);
        </ListItem>
        <ListItem>
          Governmental and regulatory bodies, in accordance with applicable laws
          and regulations;
        </ListItem>
        <ListItem>
          Potential purchasers, successors or investors in any of the companies
          within our group companies, or in the event of a corporate transaction
          (e.g. sale of a substantial part of our business, merger,
          reorganization, bankruptcy, consolidation or asset sale of an asset or
          transfer in the operation thereof) in relation to any company within
          our group companies (in such event, the acquiring company or
          transferee will assume the rights and obligations as described in this
          Policy).
        </ListItem>
      </ul>

      <Headline level={2}>International Data Transfers.</Headline>
      <Paragraph>
        We may transfer or disclose Personal Information to our subsidiaries,
        affiliated companies, subcontractors or such other trusted third party
        service providers or partners, who are located in different
        jurisdictions across the world for the purpose described in this Policy.
        Information collected by Company may be stored and processed in any
        jurisdiction where Company or its affiliates, subsidiaries, partners, or
        service providers are located or maintain facilities.
      </Paragraph>
      <Paragraph>
        If we provide any information about you to any such entities, we will
        take appropriate measures to ensure such companies protect your
        information adequately in accordance with this Policy and applicable
        law.
      </Paragraph>

      <Headline level={2}>Third party collection of information.</Headline>
      <Paragraph>
        Our policy only addresses the use and disclosure of information we
        collect from you. To the extent that you disclose your information to
        other parties on our Services or other sites throughout the internet,
        different rules may apply to their use or disclosure of the information
        you disclose to them. For example, when making a payment by credit card
        our third party processors such as the Apple Store and Google Play
        Store, they may collect your credit card information and other
        information necessary for processing/authorizing your credit card
        payments. These payment companies may also provide certain of your
        details to us.
      </Paragraph>
      <Paragraph>
        This Policy does not apply to the practices of companies that we do not
        own or control, or to individuals whom we do not employ or manage,
        including any of the third parties to which we may disclose information
        as set forth in this Policy. The Services may also enable you to
        interact (whether directly or through link) with third party websites,
        mobile software applications and services that are not owned or
        controlled by us (each a "Third Party Services(s)"). We are not
        responsible for the privacy practices or the content of any Third Party
        Services. Please be aware that the Third Party Services may collect
        Personal Information from you. Accordingly, we encourage you to read the
        terms and conditions and privacy policy of each Third Party Service that
        you choose to use or interact with.
      </Paragraph>
      <Paragraph>
        You are knowingly and voluntarily assuming all risks of using any Third
        Party Services. You agree that we shall have no liability whatsoever
        with respect to such Third Party Services and your usage of them.
      </Paragraph>
      <Paragraph>
        This policy informs the user that Tango uses YouTube API Services as an
        online resource to which our application may link or otherwise reference
        that you may access through our application ( “Third-Party Services”).
      </Paragraph>

      <Headline level={2}>Children’s Privacy.</Headline>
      <Paragraph>
        The Services are not directed at children under the age of consent
        (namely, below the age where consent must be given or authorized by the
        holder of parental responsibility over the child, as required under the
        applicable law in the jurisdiction you reside in ( “Age of Consent”)).
        If you are under the Age of Consent you may use the Services only if
        consent is given or authorized by the holder of parental responsibility.
      </Paragraph>
      <Paragraph>
        If Company learns that Personal Information of persons under the Age of
        Consent has been collected on or through the Services without parental
        or guardian consent, Company will take appropriate steps to delete this
        information unless it is needed to comply with any legal or statutory
        obligation.
      </Paragraph>
      <Paragraph>
        If you are the parent or legal guardian of a child under the Age of
        Consent who has become a member, then please contact us at{" "}
        <MailToLegal /> to have that child’s account terminated and the Personal
        Information deleted.
      </Paragraph>

      <Headline level={2}>Retention of Personal Information.</Headline>
      <Paragraph>
        If you have registered with an account through our Services, the Company
        will retain your Personal Information during the period your account is
        active. In addition, the Company will retain your Personal Information
        for additional periods, to enable the Company to meet its legal
        obligations, e.g. Know-Your-Customer and Anti-Money Laundering
        requirements.
      </Paragraph>
      <Paragraph>
        In addition, the Company may retain your Personal Information for longer
        periods, provided that retaining such information is necessary for the
        Company’s legitimate interests, such as fraud prevention and record
        keeping.
      </Paragraph>

      <Headline level={2}>What are Your Rights?</Headline>
      <Paragraph>
        If you reside in the EU or in other locations that provide you with the
        below rights, you may contact us at any time by email (at:{" "}
        <MailToLegal />) and request:
      </Paragraph>
      <ol className={styles.ol}>
        <ListItem>
          To access or delete any Personal Information relating to you;
        </ListItem>
        <ListItem>
          To change or update any Personal Information relating to you (for
          example, if you believe that your Personal Information is incorrect,
          you may ask to have it corrected or deleted). Note that you may also
          request that we will correct errors with regard to your Personal
          Information (except in cases where the information is required to be
          kept in its original format under any applicable laws and
          regulations);
        </ListItem>
        <ListItem>
          That we will restrict any further use of your Personal Information;
        </ListItem>
        <ListItem>
          That we will provide the Personal Information you volunteered to us in
          a machine-readable format;
        </ListItem>
        <ListItem>
          To object to the processing your Personal Information (such as for
          marketing purposes);
        </ListItem>
        <ListItem>
          To withdraw your consent to our processing activities (provided that
          such processing activities rely on your consent, and not on a
          different legal basis);
        </ListItem>
        <ListItem>
          Not to be subject to a decision based solely on automated processing,
          including profiling, which produces legal effects concerning you or
          similarly significant affects you, except where such processing is
          necessary for the performance of the contract between you and us, or
          it is based on your explicit consent, as provided hereunder.
        </ListItem>
      </ol>
      <Paragraph>
        Please note that these rights are not absolute and requests are subject
        to any applicable legal requirements, including any legal and ethical
        reporting or document retention obligations. We may also rectify,
        replenish or remove incomplete or inaccurate information, at any time
        and at our own discretion, in accordance with our internal policies.
      </Paragraph>
      <Paragraph>
        If you are unsatisfied with our response, you can reach out to the
        applicable data protection supervisory authority.
      </Paragraph>

      <Headline level={2}>How to delete your information?</Headline>
      <Paragraph>
        If you have an Apple device, you can request to delete your data by
        clicking on your Profile Menu {">"} Settings {">"} Account Settings{" "}
        {">"} Delete Account ( "Account Deletion Request").
      </Paragraph>
      <Paragraph>
        When you submit an Account Deletion Request, we delete the content you
        have posted, streamed or created, and you won't be able to recover that
        information later. Information that others have shared about you isn't
        part of your account and won't be deleted.
      </Paragraph>
      <Paragraph>
        Please note that all Account Deletion Requests are subject to our
        retention policy, and the Company reserves the right to retain certain
        Personal Information for the purpose of meeting its legal obligations,
        for fraud prevention and for record keeping purposes.
      </Paragraph>
      <Paragraph subParagraph>Registered users.</Paragraph>
      <Paragraph>
        All Account Deletion Requests of registered users will be processed
        within thirty (30) days period ( "Processing Period"). You can
        reactivated your user within the Processing Period. After the Processing
        Period will end, you will no longer we able to access your account and
        the Company will remove all Personal Information related to your
        account.
      </Paragraph>
      <Paragraph subParagraph>Guests.</Paragraph>
      <Paragraph>
        All Account Deletion Requests of guest users will be processed promptly,
        and all Personal Information collected on the guest user will be
        deleted.
      </Paragraph>

      <Headline level={2}>How we keep your information secured.</Headline>
      <Paragraph>
        We take great care in implementing and maintaining the security of the
        Services and your information. We have put in place appropriate physical
        and technological safeguards to help prevent unauthorized access, to
        maintain data security, and to use correctly the information we collect
        online. These safeguards vary based on the sensitivity of the
        information that we collect and store.
      </Paragraph>
      <Paragraph>
        Although we take reasonable steps to safeguard information, we cannot be
        responsible for the acts of those who gain unauthorized access or abuse
        the Services, and we make no warranty, express, implied or otherwise,
        that we will prevent such access.
      </Paragraph>
      <Paragraph>
        User data is stored on the Google Cloud Platform, which is built with
        strong security features that ensure the continued protection of user
        information.
      </Paragraph>

      <Headline level={2}>Changes to the Privacy Policy.</Headline>
      <Paragraph>
        We reserve the right to change this Policy at any time, so please
        re-visit this page frequently. We will provide notice of substantial
        changes of this Policy on our Services and/or we will send you an e-mail
        regarding such changes to the applicable e-mail address that you
        provided to us. Such substantial changes will take effect fourteen (14)
        days after such notice was provided on any of the above mentioned
        methods. Otherwise, all other changes to this Policy are effective as of
        the stated “Last Revised” date, and your continued use of our Services
        after the Last Revised date will constitute acceptance of, and agreement
        to be bound by, those changes.
      </Paragraph>

      <Headline level={2}>How to contact us?</Headline>
      <Paragraph>
        If you have any general questions about the Services or the information
        that we collect about you and how we use it, please contact us via email
        at <MailToLegal /> or by sending a letter to:
      </Paragraph>
      <ul className={styles.ul}>
        <ListItem>
          TangoMe Inc.
          <br />
          Beit Sonol, 52 Menachem Begin st.
          <br />
          Tel Aviv, 6713701
          <br />
          Israel
        </ListItem>
      </ul>
      <Paragraph>
        We will make an effort to reply within a reasonable timeframe. Please
        feel free to reach out to us at any time.
      </Paragraph>

      <Headline level={2}>California Residents.</Headline>
      <Paragraph>
        This part of the Policy addresses the specific disclosure requirements
        under the California Consumer Privacy Act of 2018 (Cal. Civ. §§
        1798.100–1798.199) and the California Consumer Privacy Act Regulations
        by the Attorney General (collectively, “CCPA“ ).
      </Paragraph>
      <Paragraph subParagraph>What Information We Process.</Paragraph>
      <Paragraph>
        In the preceding 12 months, we have collected, and or disclosed the
        following categories of Personal Information:
      </Paragraph>

      <Table
        theadData={personalInformationCategories.theadData}
        tbodyData={personalInformationCategories.tbodyData}
      />

      <Paragraph subParagraph>Sources of Personal Information.</Paragraph>
      <Paragraph>
        In the 12 preceding months, we have collected the above-mentioned
        categories of Personal Information from the following categories of
        sources:
      </Paragraph>
      <ul className={styles.ul}>
        <ListItem>Consumer directly;</ListItem>
        <ListItem>Advertising networks;</ListItem>
        <ListItem>Social Networks;</ListItem>
        <ListItem>KYC Providers;</ListItem>
        <ListItem>Payment processors.</ListItem>
      </ul>
      <Paragraph subParagraph>Sale of Personal Information.</Paragraph>
      <Paragraph>
        We do not "sell" Personal Information about our users as most people
        would typically understand that term. However, we do allow certain
        third-party advertising partners to collect information about consumers
        through our Services for purposes of serving ads that are more relevant,
        for ad campaign measurement and analytics, and for ad fraud detection
        and reporting. Please see our "Cookies and Tracking Technologies"
        section above for more information.
      </Paragraph>
      <Paragraph subParagraph>Users Rights under the CCPA.</Paragraph>
      <Paragraph>
        The CCPA provides consumers with specific rights regarding their
        Personal Information. This section describes your CCPA rights and
        explains how to exercise those rights.
      </Paragraph>
      <ol className={styles.alphabeticalBracketed}>
        <ListItem>
          Access to Personal Information : You may request, up to twice every
          twelve (12) months, that we disclose to you the categories and
          specific pieces of Personal Information that we have collected about
          you, the categories of sources from which your Personal Information is
          collected, the business or commercial purpose for collecting your
          Personal Information, the categories of Personal Information that we
          disclosed for a business purpose, any categories of Personal
          Information about you that we sold, the categories of third-parties
          with whom we have shared your Personal Information, and the business
          or commercial purpose for selling your Personal Information, if
          applicable.
        </ListItem>
        <ListItem>
          Deletion Requests: You have the right to request that we delete any
          Personal Information collected from you and retained, unless an
          exception applies. Once we receive and confirm your verifiable
          consumer request, we will delete (and direct our service providers,
          subcontractors, and consultants to delete) your Personal Information,
          unless an exception applies.
        </ListItem>
        <ListItem>
          Right to Opt-Out of the Sale of Personal Information : In the event
          that we sell your Personal Information, you have the right to submit a
          request to opt-out of the sale of your Personal Information. You may
          change your decision at any time and permit us to sell your Personal
          Information. After you opt-out, we may continue to share some Personal
          Information with our partners (who will function as our service
          providers in such instance) to help us perform business-related
          functions such as, but not limited to, providing the Services,
          ensuring that the Services is working correctly and securely,
          providing aggregate statistics and analytics and/or preventing fraud.
        </ListItem>
        <ListItem>
          Right to non-discrimination : You have the right to be free from any
          discrimination for exercising your rights under the CCPA. Should you
          exercise any of your rights under the CCPA, we will not discriminate
          against you by offering you different pricing or products, or by
          providing you with a different level or quality of services, based
          solely upon your request. However, in some circumstances we may not be
          able to provide a service if you choose to delete your Personal
          Information.
        </ListItem>
      </ol>
      <Paragraph subParagraph>Exercising Your Rights.</Paragraph>
      <Paragraph>
        You can exercise your rights by submitting a verifiable consumer request
        to our physical address (as written above in the "How to contact us"
        section) or to our email address: <MailToLegal />.
      </Paragraph>
      <Paragraph>
        Only you or a person authorized to act on your behalf may make a
        consumer request related to your Personal Information.
      </Paragraph>
      <Paragraph>The request must:</Paragraph>
      <ul className={styles.ul}>
        <ListItem>
          Provide sufficient information to allow us to reasonably verify you
          are the person about whom we collected Personal Information or an
          authorized representative.
        </ListItem>
        <ListItem>
          Describe your request with sufficient details to allow us to properly
          understand, evaluate, and respond to it.
        </ListItem>
      </ul>
      <Paragraph>
        We cannot respond to your request or provide you with Personal
        Information if we cannot verify your identity or authority to make the
        request and confirm the Personal Information relates to you. Making a
        verifiable consumer request does not require you to create an account
        with us. We will only use Personal Information provided in a verifiable
        consumer request to verify the requestor's identity or authority to make
        the request.
      </Paragraph>
      <Paragraph subParagraph>Designating Agents.</Paragraph>
      <Paragraph>
        You can designate an authorized agent to make a request under the CCPA
        on your behalf if:
      </Paragraph>
      <ul className={styles.ul}>
        <ListItem>
          The authorized agent is a natural person or a business entity
          registered with the Secretary of State of California;
        </ListItem>
        <ListItem>
          You sign a written declaration that you authorize the authorized agent
          to act on your behalf.
        </ListItem>
      </ul>
      <Paragraph>
        If you use an authorized agent to submit a request to exercise your
        rights, please mail a certified copy of your written declaration
        authorizing the authorized agent to act on your behalf using the contact
        information below.
      </Paragraph>
      <Paragraph>
        If you provide an authorized agent with power of attorney pursuant to
        Probate Code sections 4000 to 4465, it may not be necessary to perform
        these steps and we will respond to any request from such authorized
        agent in accordance with the CCPA.
      </Paragraph>
    </article>
  </>
);

export default memo(PrivacyPolicy);
