import React from "react";
import classnames from "classnames";
import { useBreakpoint } from "src/ui/hooks/useBreakpoint";
import { VoidCallback } from "src/types/common";
import Typography, {
  TYPOGRAPHY_TYPE,
} from "src/ui/common/typography/Typography";
import styles from "./PostDate.scss";

type PostDateProps = {
  onClick?: VoidCallback;
};

const PostDate: React.FC<PostDateProps> = ({ onClick, children }) => {
  const breakpoint = useBreakpoint();

  return (
    <Typography
      type={TYPOGRAPHY_TYPE.PARAGRAPH5}
      as="time"
      className={classnames(styles.postDate, styles[breakpoint])}
      onClick={onClick}
    >
      {children}
    </Typography>
  );
};

export default PostDate;
