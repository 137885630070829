import { Nullable } from "src/types/common";

export const GROUP_CHAT_ID_PREFIX = "GROUP:";

export default (id: Nullable<string | undefined>) => {
  if (!id) {
    return false;
  }
  return id.startsWith(GROUP_CHAT_ID_PREFIX);
};
