import React, { FC, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { parsePhoneNumber } from "libphonenumber-js/max";
import Title from "src/features/signin/components/Title/Title";
import useGetInitialCountryInfo from "src/features/signin/hooks/useGetInitialCountryInfo";
import {
  SCREEN_NAME,
  SIGNIN_TARGET,
  emitUiAction,
} from "src/features/signin/imports/analytics";
import {
  BottomScreenType,
  Breakpoints,
  LoginProvider,
} from "src/features/signin/imports/enums";
import {
  countriesSelectors,
  loadCountriesForSignIn,
  loginSelectors,
  openSelectCountryBottomScreen,
} from "src/features/signin/imports/state";
import {
  Button,
  ButtonSize,
  ButtonVariant,
  useBreakpoint,
} from "src/features/signin/imports/ui";
import { useMount } from "src/features/signin/imports/utils";
import PhoneInput from "src/features/signin/modal/login/components/PhoneInput";
import { loginWithProvider } from "src/features/signin/state/flows/login";
import { specifyPhoneNumberForPhoneLogin } from "src/features/signin/state/login/actionCreators";
import styles from "./PhoneNumberInput.scss";

const MIN_PHONE_NUMBER_LENGTH = 5;

interface PhoneNumberInputProps {
  autoFocus?: boolean;
  screenType?: BottomScreenType;
}

const PhoneNumberInput: FC<PhoneNumberInputProps> = ({
  autoFocus,
  screenType,
}) => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const countries = useSelector(countriesSelectors.data, shallowEqual);
  const initialCountryInfo = useGetInitialCountryInfo();

  useMount(() => {
    if (!countries.length) {
      dispatch(loadCountriesForSignIn());
    }
  });

  const authenticationState = useSelector(
    loginSelectors.getPhoneNumberAuthenticationState
  );
  const countryInformation = useSelector(
    loginSelectors.getCountryInformation,
    shallowEqual
  );

  const {
    countryCode = initialCountryInfo?.countryCode,
    countryIcon = initialCountryInfo?.countryIcon,
  } = countryInformation;

  const initialPhoneNumber = (
    authenticationState.phoneNumber?.replace(countryCode, "") || ""
  ).slice(1);
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);
  const [isInputWarningDisplayed, setIsInputWarningDisplayed] = useState(false);

  const onConfirmPhoneNumber = useCallback(() => {
    try {
      const parsed = parsePhoneNumber(`+${countryCode || ""}${phoneNumber}`);

      if (!parsed.isValid()) {
        setIsInputWarningDisplayed(true);

        return;
      }

      emitUiAction({
        target: SIGNIN_TARGET.CONTINUE_WITH_PHONE,
        tango_screen: {
          reportedName: SCREEN_NAME.REGISTRATION_SIGN_IN,
        },
      });

      dispatch(specifyPhoneNumberForPhoneLogin(parsed.number));
      dispatch(
        loginWithProvider({
          provider: LoginProvider.TANGO_PHONE_NUMBER,
          screenType,
        })
      );
    } catch (e) {
      setIsInputWarningDisplayed(true);
    }
  }, [dispatch, countryCode, phoneNumber, screenType]);

  const onKeyDown = useCallback(
    (event) => {
      if (phoneNumber.length < MIN_PHONE_NUMBER_LENGTH) {
        return;
      }

      if (event.key === "Enter") {
        onConfirmPhoneNumber();
      }
    },
    [phoneNumber, onConfirmPhoneNumber]
  );

  const showSelectCountryBottomScreen = useCallback(() => {
    dispatch(openSelectCountryBottomScreen());
  }, [dispatch]);

  return (
    <>
      <Title
        description={
          <FormattedMessage
            id="modal.login.phone.continue.header.description"
            defaultMessage="You’ll receive a verification code on this number"
          />
        }
        title={
          <FormattedMessage
            id="modal.login.button.phone"
            defaultMessage="Continue with phone"
          />
        }
      />

      <div
        className={classnames(styles.phoneInputContainer, styles[breakpoint])}
      >
        <PhoneInput
          autoFocus={autoFocus}
          countryCode={countryCode}
          countryIcon={countryIcon}
          initialPhoneNumber={initialPhoneNumber}
          isInputWarningDisplayed={isInputWarningDisplayed}
          onClick={!isDesktop ? showSelectCountryBottomScreen : undefined}
          onKeyDown={onKeyDown}
          setIsInputWarningDisplayed={setIsInputWarningDisplayed}
          setPhoneNumber={setPhoneNumber}
        />

        <Button
          className={styles.continue}
          data-testid={LoginProvider.TANGO_PHONE_NUMBER}
          disabled={phoneNumber.length < MIN_PHONE_NUMBER_LENGTH}
          onClick={onConfirmPhoneNumber}
          size={ButtonSize.BIG_48}
          variant={ButtonVariant.SECONDARY}
        >
          <FormattedMessage
            id="buy-coins.summary.blocked-new-window.continue"
            defaultMessage="Continue"
          />
        </Button>
      </div>
    </>
  );
};

export default PhoneNumberInput;
