import once from "lodash.once";
import {
  getAndroidAppId,
  getGetTheAppAppStoreUrl,
  getGetTheAppGooglePlayUrl,
  getIosAppId,
} from "environment";
import { APPS_FLYER_ONE_LINK_URL } from "src/core/analytics/constants";
import { AppsFlyerPlatform } from "src/core/analytics/enums";

const oneLinkURL = "https://tango.onelink.me/RCIH";
const mediaSource = { keys: ["utm_source"], defaultValue: "Web_Organic" };
const deepLinkValue = {
  keys: ["deep_link_value"],
  defaultValue: "page_unknown",
};
const button_name = {
  paramKey: "button_name",
  keys: ["button_name"],
  defaultValue: "button_unknown",
};
const campaign = { keys: ["utm_campaign"], defaultValue: "Web_Campaign" };
const afSub1 = { keys: ["utm_content"], defaultValue: "Web_Content" };
const afSub2 = { keys: ["utm_medium"], defaultValue: "Web_Medium" };
const afSub3 = { keys: ["utm_term"], defaultValue: "Web_Term" };
const afSub4 = { keys: ["utm_id"], defaultValue: "Web_ID" };
const af_dp = {
  paramKey: "af_dp",
  keys: ["af_dp"],
  defaultValue: "tango%3A%2F%2F",
};
const googleClickIdParam = "af_sub5";
const custom_ss_ui = { paramKey: "af_ss_ui", defaultValue: "true" };

const afParameters = {
  mediaSource,
  deepLinkValue,
  afCustom: [button_name, af_dp, custom_ss_ui],
  campaign,
  afSub1,
  afSub2,
  afSub3,
  afSub4,
  googleClickIdKey: googleClickIdParam,
};

export const setAppsFlyerOneLinkURL = () => {
  const generatedOneLinkURL =
    window.AF_SMART_SCRIPT?.generateOneLinkURL({
      oneLinkURL,
      afParameters,
    })?.clickURL || "";

  localStorage.setItem(APPS_FLYER_ONE_LINK_URL, generatedOneLinkURL);
};

export const getAppsFlyerOneLinkURL = () =>
  localStorage.getItem(APPS_FLYER_ONE_LINK_URL);

const generateAppsFlyerDirectLinkURL = (
  platform: string,
  appid: string,
  redirectURL: string
) =>
  window.AF_SMART_SCRIPT?.generateDirectClickURL({
    afParameters,
    platform,
    app_id: appid,
    redirectURL,
  })?.clickURL || "";

export const handleGetTheAppGooglePlayUrl = once(
  () =>
    generateAppsFlyerDirectLinkURL(
      AppsFlyerPlatform.ANDROID,
      getAndroidAppId(),
      getGetTheAppGooglePlayUrl()
    ) || getGetTheAppGooglePlayUrl()
);

export const handleGetTheAppAppStoreUrl = once(
  () =>
    generateAppsFlyerDirectLinkURL(
      AppsFlyerPlatform.IOS,
      getIosAppId(),
      getGetTheAppAppStoreUrl()
    ) || getGetTheAppAppStoreUrl()
);
