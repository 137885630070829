import { useWebViewDetector } from "src/core/webview/WebViewController/useWebViewDetector";
import { useWebViewEnvironmentPropertyController } from "src/core/webview/WebViewController/useWebViewEnvironmentPropertyController";
import { useWebViewQueryParamRemover } from "src/core/webview/WebViewController/useWebViewQueryParamRemover";

export const WebViewController = () => {
  useWebViewDetector();
  useWebViewEnvironmentPropertyController();
  useWebViewQueryParamRemover();

  return null;
};
