import { ConversationState, MessageIdentifier } from "chat/types";

export enum ContentType {
  PHOTO = "PHOTO",
  VIDEO = "VIDEO",
}

export enum ContentStatus {
  LOCKED = "LOCKED",
  PENDING = "PENDING",
  UNLOCKED = "UNLOCKED",
}

export enum PlatformType {
  MOBILE = "MOBILE",
  WEB = "WEB",
}

export enum DetailsResponseCode {
  DETAILS_ACTION_NOT_PERMITTED = "DETAILS_ACTION_NOT_PERMITTED",
  DETAILS_INVALID_REQUEST = "DETAILS_INVALID_REQUEST",
  DETAILS_SERVER_ERROR = "DETAILS_SERVER_ERROR",
  DETAILS_SUCCESS = "DETAILS_SUCCESS",
}

export enum ShareResponseCode {
  SHARE_INVALID_REQUEST = "SHARE_INVALID_REQUEST",
  SHARE_SERVER_ERROR = "SHARE_SERVER_ERROR",
  SHARE_SUCCESS = "SHARE_SUCCESS",
  SHARE_THRESHOLD_EXCEEDED = "SHARE_THRESHOLD_EXCEEDED",
}

export enum OriginType {
  DIRECT = "DIRECT",
  GROUP = "GROUP",
}

export interface MediaInfo {
  contentUrl?: string;
  duration?: number;
  height: number;
  mediaId?: string;
  thumbnailUrl?: string;
  width: number;
}

export interface PremiumMessageDetails {
  mediaInfo: MediaInfo;
  messageId: string;
  origin?: OriginType;
  status: ContentStatus;
  thumbnailBlurUrl: string;
  type: ContentType;
  unlockCounter?: number;
}

export interface DetailsResponseMessage {
  details: PremiumMessageDetails[];
  responseCode: DetailsResponseCode;
}

export interface ContentSharedPayloadMessage {
  giftId: string;
  mediaInfo: MediaInfo;
  messageId: string;
  origin?: OriginType;
  thumbnailBlurUrl: string;
  type: ContentType;
}

export type PremiumMessageDetailsMap = Record<
  PremiumMessageDetails["messageId"],
  PremiumMessageDetails
>;

interface ShareItemRequest {
  mediaInfo: MediaInfo;
  type: ContentType;
}

export interface ShareRequest {
  exclusive?: boolean;
  giftId: string;
  items: ShareItemRequest[];
  platformType?: PlatformType;
  recipientId: string;
}

interface ShareItemResponse {
  messageId: string;
  state?: ConversationState;
  tc2Id: MessageIdentifier;
}

export interface ShareResponse {
  items: ShareItemResponse[];
  responseCode: ShareResponseCode;
}
