import React, { FC, memo, useCallback, useMemo, useRef } from "react";
import { FormattedMessage, defineMessages } from "react-intl";
import { useSelector } from "react-redux";
import { datadogLogs } from "@datadog/browser-logs";
import classnames from "classnames";
import { emitEvent } from "@analytics/emit";
import {
  BroadcastType,
  EventFields,
  EventNames,
  MultiBroadcastAnswerResult,
  MultiBroadcastInviteAnchorType,
} from "@analytics/enums";
import { PLUS } from "src/constants";
import { DataDogErrorName, ToastType } from "src/enums";
import { isBroadcastExternalStarted } from "src/features/broadcastExternal/exports/utils";
import { broadcastExternalSelectors } from "src/features/broadcastExternal/state/selectors";
import { ToastInviteProps } from "src/types/toast";
import { getInviteFlowHideBannerAfterSec } from "state/abTests";
import { getInvitesAnchorType } from "state/flows/utils/getAnchorType";
import { RoundedNumber } from "ui/common/Formatted";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import useLivePartyInvites from "ui/hooks/useLivePartyInvites";
import { useToast } from "ui/hooks/useToast";
import useIsOnBroadcast from "ui/navigation/useIsOnBroadcast";
import useIsOnStream from "ui/navigation/useIsOnStream";
import Player from "ui/player";
import ToastWrapper from "ui/toast/ToastWrapper/ToastWrapper";
import { isDocumentVisible } from "utils/isDocumentVisible";
import { useMount } from "utils/miniReactUse";
import { ReactComponent as IconCross } from "img/ic_close.svg";
import { ReactComponent as DiamondIcon } from "img/ic_diamond_12.svg";
import { ReactComponent as EyeIcon } from "img/ic_eye_12.svg";
import { ReactComponent as IconChecked } from "img/user_menu_icons/check-mark_32.svg";
import styles from "./Toast.scss";

const messages = defineMessages({
  regularRequest: {
    id: "multi_stream_invite_regular",
    defaultMessage: "Join Request",
  },
  battleRequest: {
    id: "multi_stream_invite_battle",
    defaultMessage: "Battle Request",
  },
});

const ToastInvite: FC<ToastInviteProps> = ({
  id: toastId,
  multiBroadcastInvite,
  broadcastId,
  myAccountId,
  type,
}) => {
  const videoRef = useRef(null);
  const breakpoint = useBreakpointPrecise();
  const isOnStream = useIsOnStream();
  const isOnBroadcast = useIsOnBroadcast();
  const isShader = isOnStream || isOnBroadcast;
  const broadcastExternalStatus = useSelector(
    broadcastExternalSelectors.getBroadcastExternalStatus
  );

  const activeAppAnchorType = getInvitesAnchorType(
    isOnBroadcast,
    isBroadcastExternalStarted(broadcastExternalStatus)
  );

  const { remove, removeByType } = useToast();
  const { acceptInvite, rejectInvite } = useLivePartyInvites({
    accountId: myAccountId,
    invite: multiBroadcastInvite,
    streamId: broadcastId,
  });

  const {
    hostAccountId,
    hostStreamId,
    hostPreviewUrl,
    hostFullName,
    hostTotalPoint,
    hostViewerCount,
    hostLPBonus,
    hostLPBonusV2,
    isBattleRequest,
    requestId,
    id: inviteId,
  } = multiBroadcastInvite;

  const inviteMessage =
    messages[isBattleRequest ? "battleRequest" : "regularRequest"];

  const toastLifeTime = useSelector(getInviteFlowHideBannerAfterSec);

  const hasBonus = hostLPBonus > 0 || hostLPBonusV2 > 0;

  const BIParams = useMemo(
    () => ({
      [EventFields.SENDER_ID]: hostAccountId,
      [EventFields.SENDER_ID_STREAM_ID]: hostStreamId,
      [EventFields.INVITATION_ID]: inviteId,
      [EventFields.RELATION_ID]: requestId,
      [EventFields.ANCHOR_TYPE]: !isDocumentVisible()
        ? MultiBroadcastInviteAnchorType.BG
        : activeAppAnchorType,
    }),
    [activeAppAnchorType, hostAccountId, hostStreamId, inviteId, requestId]
  );

  const buttonVariants = useMemo(
    () =>
      isShader
        ? {
            primary: ButtonVariant.PRIMARY_ACCENT,
            secondary: ButtonVariant.SECONDARY_ON_SHADER,
          }
        : {
            primary: ButtonVariant.PRIMARY,
            secondary: ButtonVariant.SECONDARY,
          },
    [isShader]
  );

  const handleAcceptInvite = useCallback(async () => {
    try {
      await acceptInvite();

      emitEvent(EventNames.MULTI_STREAM_INVITATION_ANSWER, {
        ...BIParams,
        result: MultiBroadcastAnswerResult.ACCEPTED,
      });

      removeByType(ToastType.INVITE);
    } catch {
      datadogLogs.logger.error(DataDogErrorName.ACCEPT_MULTI_BROADCAST_INVITE);
    }
  }, [acceptInvite, BIParams, removeByType]);

  const handleRejectInvite = useCallback(async () => {
    try {
      await rejectInvite();

      emitEvent(EventNames.MULTI_STREAM_INVITATION_ANSWER, {
        ...BIParams,
        result: MultiBroadcastAnswerResult.DECLINED,
      });

      remove(toastId);
    } catch {
      datadogLogs.logger.error(DataDogErrorName.REJECT_MULTI_BROADCAST_INVITE);
    }
  }, [BIParams, rejectInvite, remove, toastId]);

  const handleToastDestroy = useCallback(() => {
    emitEvent(EventNames.MULTI_STREAM_INVITATION_ANSWER, {
      ...BIParams,
      result: MultiBroadcastAnswerResult.EXPIRED,
    });
  }, [BIParams]);

  useMount(() => {
    emitEvent(EventNames.MULTI_STREAM_INVITATION_DISPLAYED, {
      ...BIParams,
      [EventFields.STREAM_ID]: broadcastId,
      [EventFields.STREAM_TYPE]: BroadcastType.OBS,
    });
  });

  return (
    <ToastWrapper
      id={toastId}
      lifetime={toastLifeTime}
      type={type}
      onDestroy={handleToastDestroy}
    >
      <div
        className={classnames(
          styles.root,
          styles[breakpoint],
          {
            [styles.shader]: isShader,
          },
          styles.invite
        )}
      >
        <div className={styles.videoPlayerContainer}>
          <Player
            ref={videoRef}
            // @ts-ignore
            src={hostPreviewUrl}
            className={styles.player}
            forceDisableHd
            muted
          />
        </div>
        <div className={styles.container}>
          <div className={styles.inviteInfoContainer}>
            <div className={styles.title}>
              <Typography
                type={TYPOGRAPHY_TYPE.PARAGRAPH5}
                className={styles.typeTitle}
              >
                <FormattedMessage {...inviteMessage} />
              </Typography>
              {hasBonus && (
                <>
                  <Typography type={TYPOGRAPHY_TYPE.HEADLINE7}>
                    {PLUS}
                  </Typography>
                  <Typography type={TYPOGRAPHY_TYPE.HEADLINE7}>
                    <FormattedMessage id="offer.bonus" defaultMessage="Bonus" />
                  </Typography>
                </>
              )}
            </div>
            <div className={styles.details}>
              <Typography
                className={styles.userName}
                type={TYPOGRAPHY_TYPE.HEADLINE3}
              >
                {hostFullName}
              </Typography>
              <div className={styles.statistic}>
                <Typography
                  type={TYPOGRAPHY_TYPE.HEADLINE6}
                  as="div"
                  className={styles.points}
                  data-testid="points"
                >
                  <DiamondIcon className={styles.diamond} />
                  <RoundedNumber value={hostTotalPoint} />
                </Typography>
                <Typography
                  type={TYPOGRAPHY_TYPE.HEADLINE6}
                  as="div"
                  className={styles.points}
                  data-testid="points"
                >
                  <EyeIcon className={styles.diamond} />
                  {hostViewerCount}
                </Typography>
              </div>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <Button
              className={styles.button}
              size={ButtonSize.CIRCLE_BIG_48}
              variant={buttonVariants.secondary}
              onClick={handleRejectInvite}
            >
              <IconCross />
            </Button>
            <Button
              className={styles.button}
              size={ButtonSize.CIRCLE_BIG_48}
              variant={buttonVariants.primary}
              onClick={handleAcceptInvite}
            >
              <IconChecked />
            </Button>
          </div>
        </div>
      </div>
    </ToastWrapper>
  );
};

export default memo(ToastInvite);
