import { createAction, createReducer } from "@reduxjs/toolkit";
import { Nullable } from "src/types/common";

interface HomePageV1State {
  bgVideo: string;
  // query param that make possible to show content with higher moderation level,
  // specially made to make hardly understandable in query params
  hMarketingCampaign: Nullable<boolean>;
}

interface SetBgVideoPayload {
  bgVideo: string;
}

interface SetModerationLevelPayload {
  hMarketingCampaign: boolean;
}

export const setBgVideo = createAction<SetBgVideoPayload>(
  "lwc/homePageV1/setBgVideo"
);

export const setModerationLevel = createAction<SetModerationLevelPayload>(
  "lwc/homePageV1/setHMarketingCampaign"
);

export default createReducer<HomePageV1State>(
  {
    bgVideo: "",
    hMarketingCampaign: null,
  },
  (builder) => {
    builder
      .addCase(setBgVideo, (state, action) => {
        state.bgVideo = action.payload.bgVideo;
      })
      .addCase(setModerationLevel, (state, action) => {
        state.hMarketingCampaign = action.payload.hMarketingCampaign;
      });
  }
);

export const selectors = {
  getBgVideo: (state: HomePageV1State) => state.bgVideo,
  getHMarketingCampaign: (state: HomePageV1State) => state.hMarketingCampaign,
};
