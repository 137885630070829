import { ExternalMessagePayload, StoredMessage } from "chat/exports/types";
import { ExternalMessageInfo } from "generated/proto/ExternalMessage";
import { TANGO_ACCOUNT_ID } from "src/constants";
import { Nullable } from "src/types/common";
import { parseMessageFromBase64 } from "src/utils/protobufUtil";

export const getParsedExternalMessagePayload = (
  protobufPayload: Nullable<string | undefined>
) => {
  if (!protobufPayload) {
    return null;
  }

  try {
    return parseMessageFromBase64(
      protobufPayload,
      ExternalMessageInfo
    ) as ExternalMessagePayload;
  } catch (e) {
    return null;
  }
};

export const getExternalMessageStyles = (
  styles: Record<string, string>,
  message: StoredMessage
) => {
  const isMessageFromTango = message.from === TANGO_ACCOUNT_ID;
  if (!isMessageFromTango) {
    return {};
  }

  const parsedPayload = getParsedExternalMessagePayload(
    message.sdk_message_payload?.payload
  );

  if (!parsedPayload || !message.sdk_message_payload) {
    return {};
  }

  const { messageText, actionInfo, previewThumbnailUrl } = parsedPayload;

  return {
    [styles.externalMessage]: true,
    [styles.externalMessageWithText]: messageText,
    [styles.externalMessageWithActionInfo]: actionInfo && actionInfo.length > 0,
    [styles.externalMessageWithThumbnail]: previewThumbnailUrl,
  };
};
