export enum SnapchatMethods {
  INIT = "init",
  TRACK = "track",
}

export enum SnapchatEventNames {
  COMPLETED_REGISTRATION = "SIGN_UP",
  PAGE_VIEW = "PAGE_VIEW",
  PURCHASE = "PURCHASE",
}

export enum SnapchatEventFields {
  CLICK_ID = "click_id",
  CLIENT_DEDUP_ID = "client_dedup_id",
  HASHED_EMAIL = "user_hashed_email",
  HASHED_PHONE = "user_hashed_phone_number",
}
