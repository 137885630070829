import { MessageType } from "chat/types";

export const systemMessageTypes = [
  MessageType.GROUP_MEMBER_JOIN,
  MessageType.GROUP_MEMBER_LEAVE,
  MessageType.GROUP_NAME_CHANGE,
  MessageType.LIKE_MESSAGE,
  MessageType.SCREENSHOT_INFO_MESSAGE,
  MessageType.VIDEO_SAVED_INFO_MESSAGE,
  MessageType.PHOTO_SAVED_INFO_MESSAGE,
];

export const ignoredMessagesTypes = [MessageType.GROUP_UPDATE];
