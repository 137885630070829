import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { setMarketingVisitData } from "src/features/marketing/state/slice";
import {
  authorizeGuest,
  tryValidation,
} from "src/features/signin/exports/state/flows";
import { checkInVisitor, clearVisitorData } from "state/actionCreators/visitor";
import { calculateDeviceFingerprint } from "state/flows/deviceInfo";
import {
  connectionManagerSelectors,
  deviceInfoSelectors,
  hasValidAccount,
  loginSelectors,
  serverOwnedConfigSelectors,
  validationSelectors,
  visitorSelectors,
} from "state/selectors";
import useSessionTokenRefresh from "ui/hooks/useSessionTokenRefresh";
import useSplashScreen from "ui/hooks/useSplashScreen";
import useIsPathnameForVisitor from "ui/navigation/hooks/useIsPathnameForVisitor";

const selector = (state) => ({
  hasValidAccount: hasValidAccount(state),
  isLoggedIn: loginSelectors.isLoggedIn(state),
  locale: deviceInfoSelectors.getDeviceLocale(state),
  isNeedToValidate: validationSelectors.isNeedToValidate(state),
  isLimitedSession: loginSelectors.isLimitedSession(state),
  isDeviceFingerprintingInProgress:
    deviceInfoSelectors.isDeviceFingerprintingInProgress(state),
  fingerprint: deviceInfoSelectors.getDeviceFingerprint(state),
  deviceToken: connectionManagerSelectors.getDeviceToken(state),
  visitorData: visitorSelectors.data(state),
  visitorMeta: visitorSelectors.meta(state),
  isServerConfigLoaded:
    serverOwnedConfigSelectors.getIsServerConfigLoaded(state),
});

const LoginGate = ({ children }) => {
  const dispatch = useDispatch();
  const {
    isLoggedIn,
    locale,
    hasValidAccount,
    isLimitedSession,
    isNeedToValidate,
    fingerprint,
    isDeviceFingerprintingInProgress,
    deviceToken,
    visitorData,
    visitorMeta,
    isServerConfigLoaded,
  } = useSelector(selector);

  const { pathname } = useLocation();
  const isVisitorPath = useIsPathnameForVisitor({ pathname });

  useSessionTokenRefresh();

  useEffect(() => {
    if (visitorData.analyticsKey && !visitorData.isVisitor) {
      // TODO remove after NOV 2024. it was done to support back compatibility for visitors without isVisitor flag
      // https://tango-me.atlassian.net/browse/WEB-6998
      dispatch(clearVisitorData());
    }
  }, [dispatch, visitorData.analyticsKey, visitorData.isVisitor]);

  useEffect(() => {
    if (
      isDeviceFingerprintingInProgress ||
      fingerprint ||
      !isServerConfigLoaded
    ) {
      return;
    }

    dispatch(calculateDeviceFingerprint());
  }, [isDeviceFingerprintingInProgress, fingerprint, isServerConfigLoaded]);

  useEffect(() => {
    if (!fingerprint && !isVisitorPath) {
      return;
    }

    if (!isServerConfigLoaded) {
      return;
    }

    if (isLoggedIn) {
      dispatch(tryValidation({ locale, deviceToken }));
    } else if (
      isVisitorPath &&
      !visitorData.isVisitor &&
      !visitorMeta.loading
    ) {
      dispatch(checkInVisitor());
    } else {
      if (isVisitorPath) {
        if (visitorData.analyticsKey && fingerprint) {
          dispatch(
            setMarketingVisitData({
              isVisitRequestNeeded: true,
            })
          );
        }

        return;
      }

      dispatch(authorizeGuest());
    }
  }, [fingerprint, visitorData, isServerConfigLoaded, isVisitorPath]);

  const isGateOpenForGuest =
    hasValidAccount && !(isLimitedSession && isNeedToValidate);

  const isGateOpenForVisitor = isVisitorPath && visitorData.isVisitor;

  const isGateOpen = isGateOpenForGuest || isGateOpenForVisitor;

  useSplashScreen(!isGateOpen);

  return isGateOpen ? children : null;
};

LoginGate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default LoginGate;
