import { batch } from "react-redux";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import {
  BI_LANDING_FIELD,
  EmitUnlockPremiumMessageParams,
  EventFields,
  EventNames,
  MessageStatusResult,
  PERSONAL_OFFERS_SOURCE,
  PURCHASE_ORIGIN,
  PURCHASE_SOURCE,
  UI_COMPONENT,
  emitEvent,
} from "chat/imports/analytics";
import {
  AFTER_BUY_OPERATIONS,
  CASHIER,
  OneClickEndReason,
  OneClickEndResult,
  OneClickOfferTypes,
} from "chat/imports/constants";
import {
  RootState,
  afterPurchaseTaskManagerActions,
  getIsCashierDesignV2DesktopEnabled,
  getIsCashierDesignV2Enabled,
  getIsPaymentFlowV2Enabled,
  getIsRefillV2DesktopEnabled,
  getIsRefillV2MobileEnabled,
  getOneClickPurchaseEnabled,
  getOneClickV2PurchaseEnabled,
  getPersonalOffersCashierRefillEnabled,
  getPremiumMessagesDefaultGiftsList,
  giftsActionCreators,
  giftsCacheSelectors,
  loadGiftsBatch,
  openBuyCoins,
  shopSelectors,
  userSelectors,
  viewerSessionSelectors,
} from "chat/imports/state";
import {
  AnalyticsParams,
  ApplicationThunk,
  Gift,
  VoidCallback,
} from "chat/imports/types";
import {
  findClosestOfferToBuyGift,
  getOneClickAnalyticsParams,
  loadClosestPersonalOffer,
  uniq,
} from "chat/imports/utils";
import { ContentType } from "chat/premiumMessage/types";
import {
  emitUnlockPremiumMessageAndSendGift,
  unlockPremiumMessage,
} from "./asyncAction";
import { unlockPremiumMessageStart } from "./slice";

export const createOnSuccess =
  (
    dispatch: Dispatch,
    params: Omit<UnlockPremiumMessageFlowParams, "isMobile">,
    callback?: VoidCallback
  ) =>
  () => {
    batch(() => {
      dispatch(unlockPremiumMessageStart({ messageId: params.messageId }));
      dispatch(
        afterPurchaseTaskManagerActions.setItemAfterCoinsBuy({
          type: AFTER_BUY_OPERATIONS.UNLOCK_PREMIUM_MESSAGE,
          ...params,
        })
      );
    });

    callback?.();
  };

type UnlockPremiumMessageFlowParams = {
  contentType: ContentType;
  conversationId: string;
  giftId: Gift["id"];
  isMobile: boolean;
  messageId: string;
  recipientId: string;
};

export const unlockPremiumMessageFlow =
  ({
    contentType,
    conversationId,
    giftId,
    isMobile,
    messageId,
    recipientId,
  }: UnlockPremiumMessageFlowParams): ApplicationThunk =>
  async (dispatch, getState) => {
    const state = getState();

    const streamerId = viewerSessionSelectors.getBroadcasterId(state);

    const gift = giftsCacheSelectors.getGiftById(state, giftId);

    const coinsBalance = userSelectors.getCoinsBalance(state);
    const pointsBalance = userSelectors.getPointsBalance(state);
    const isRefillV2MobileEnabled = getIsRefillV2MobileEnabled(state);
    const isRefillV2DesktopEnabled = getIsRefillV2DesktopEnabled(state);
    const isCashierV2MobileEnabled = getIsCashierDesignV2Enabled(state);
    const isCashierV2DesktopEnabled = getIsCashierDesignV2DesktopEnabled(state);
    const isPaymentFlowV2Enabled = getIsPaymentFlowV2Enabled(state);

    const isBuyWithCoins =
      giftsCacheSelectors.isBuyGiftWithCoins(state) || !gift.withdrawInPoint;

    const giftsBalance = userSelectors.getGiftsBalance(state) as Array<{
      amount: number;
      gift: Gift["id"];
    }>;

    const giftBalance = giftsBalance.find(
      (balance) => balance.gift === giftId
    )?.amount;

    const userBalance = isBuyWithCoins ? coinsBalance : pointsBalance;
    const giftPrice = isBuyWithCoins
      ? gift.priceInCredit
      : gift.withdrawInPoint;

    if (userBalance < giftPrice && !giftBalance) {
      let closestOfferToBuyGift = null;
      let offer;
      let isOneClickPurchase = false;

      const isPersonalOffersCashierRefillEnabled =
        getPersonalOffersCashierRefillEnabled(state);

      const isOneClickPurchaseEnabled = getOneClickPurchaseEnabled(state);
      const isOneClickV2PurchaseEnabled = getOneClickV2PurchaseEnabled(state);

      const isOneClickFlowEnabled =
        isPersonalOffersCashierRefillEnabled &&
        (isOneClickPurchaseEnabled || isOneClickV2PurchaseEnabled);

      let oneClickV2PurchaseAnalyticsArgs: AnalyticsParams = {
        [EventFields.CHAT_ID]: conversationId,
        [EventFields.GIFT_ID]: gift.id,
        [EventFields.ONE_CLICK_TYPE]:
          OneClickOfferTypes.ONE_CLICK_PREMIUM_MESSAGES,
      };

      if (isOneClickFlowEnabled) {
        offer = await loadClosestPersonalOffer({
          giftPrice,
          userBalance,
          state,
          ...(isOneClickV2PurchaseEnabled && {
            analyticsParams: oneClickV2PurchaseAnalyticsArgs,
            offerType: OneClickOfferTypes.ONE_CLICK_PREMIUM_MESSAGES,
            source: PERSONAL_OFFERS_SOURCE.ONE_CLICK_PREMIUM_MESSAGES,
          }),
        });

        if (offer) {
          if (Array.isArray(offer.pricePoints) && offer.pricePoints[0]) {
            closestOfferToBuyGift = offer.pricePoints[0];
            isOneClickPurchase = true;

            oneClickV2PurchaseAnalyticsArgs = {
              ...oneClickV2PurchaseAnalyticsArgs,
              [BI_LANDING_FIELD.PRICE_POINT_ID]: closestOfferToBuyGift.id,
              [BI_LANDING_FIELD.MARKET_OFFER_ID]:
                closestOfferToBuyGift?.offers?.[0]?.marketOfferId,
            };
          }

          oneClickV2PurchaseAnalyticsArgs = {
            ...oneClickV2PurchaseAnalyticsArgs,
            [BI_LANDING_FIELD.TRIGGER_ID]: offer?.triggerId,
            [BI_LANDING_FIELD.SERVER_OFFER_ID]: offer?.personalOfferId,
          };
        }
      } else {
        const offers = shopSelectors.getListOfPurchasableItems(state);

        closestOfferToBuyGift = findClosestOfferToBuyGift({
          coinsBalance,
          gift,
          offers,
        });
      }

      let oneClickV2ResultAnalyticsArgs: AnalyticsParams;

      const onError = () => {
        if (isOneClickFlowEnabled) {
          oneClickV2ResultAnalyticsArgs = {
            [EventFields.RESULT]: OneClickEndResult.UNSUCCESSFUL,
            [EventFields.REASON]: OneClickEndReason.PURCHASE_FAILED,
          };
        }
      };

      const onDismiss = () => {
        if (isOneClickFlowEnabled) {
          oneClickV2ResultAnalyticsArgs ??= {
            [EventFields.RESULT]: OneClickEndResult.UNSUCCESSFUL,
            [EventFields.REASON]: OneClickEndReason.PURCHASE_REFUSED,
          };

          emitEvent(EventNames.ONE_CLICK_END, {
            ...oneClickV2ResultAnalyticsArgs,
            ...oneClickV2PurchaseAnalyticsArgs,
          });
        }
      };

      const onPaymentSuccess = () => {
        if (isOneClickFlowEnabled) {
          oneClickV2ResultAnalyticsArgs = {
            [EventFields.RESULT]: OneClickEndResult.SUCCESSFUL,
          };
        }
      };

      const nonOneClickPurchaseOrigin =
        closestOfferToBuyGift && isOneClickPurchaseEnabled
          ? PURCHASE_ORIGIN.WEB_ONE_CLICK
          : undefined;

      const purchaseOrigin = isOneClickPurchase
        ? PERSONAL_OFFERS_SOURCE.ONE_CLICK_PREMIUM_MESSAGES
        : nonOneClickPurchaseOrigin;

      dispatch(
        openBuyCoins({
          analyticsParams: getOneClickAnalyticsParams(offer),
          offer: closestOfferToBuyGift,
          purchaseSource: PURCHASE_SOURCE.OFFLINE_GIFTING_PROFILE,
          purchaseOrigin,
          isMobile,
          streamerId,
          viewType: CASHIER,
          uiComponent: UI_COMPONENT.CASHIER,
          previousUiComponent: UI_COMPONENT.DOWN_CASHIER,
          shouldDismissOnBack: true,
          isRefillV2MobileEnabled,
          isRefillV2DesktopEnabled,
          isCashierV2MobileEnabled,
          isCashierV2DesktopEnabled,
          isPaymentFlowV2Enabled,
          onSuccess: createOnSuccess(
            dispatch,
            {
              contentType,
              conversationId,
              giftId,
              messageId,
              recipientId,
            },
            onPaymentSuccess
          ),
          onError,
          // TODO: move modal.js to TypeScript. JIRA ticket https://tango-me.atlassian.net/browse/WEB-5935.
          // @ts-ignore-next-line
          onDismiss,
        })
      );
    } else {
      const accountId = userSelectors.getMyAccountId(state);

      const emitParams: Omit<EmitUnlockPremiumMessageParams, "result"> = {
        accountId,
        chatId: conversationId,
        giftId,
        messageId,
        recipientAccountId: recipientId,
        type: contentType,
      };

      batch(() => {
        dispatch(unlockPremiumMessageStart({ messageId }));

        dispatch(
          unlockPremiumMessage({
            messageId,
          })
        )
          .unwrap()
          .then(() => {
            dispatch(
              emitUnlockPremiumMessageAndSendGift({
                ...emitParams,
                result: MessageStatusResult.SUCCESS,
              })
            );
          })
          .catch(() => {
            dispatch(
              emitUnlockPremiumMessageAndSendGift({
                ...emitParams,
                result: MessageStatusResult.FAILURE,
              })
            );
          });
      });
    }
  };

export const loadDefaultGifts =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const socGiftIds = getPremiumMessagesDefaultGiftsList(state);

    const defaultPremiumMessageGiftIds =
      giftsCacheSelectors.getDefaultPremiumMessageGiftIds(state);

    const defaultGiftIds = uniq([
      ...defaultPremiumMessageGiftIds,
      ...socGiftIds,
    ]).slice(0, socGiftIds.length);

    dispatch(
      giftsActionCreators.setDefaultPremiumMessageGiftIds(defaultGiftIds)
    );
    await dispatch(
      loadGiftsBatch({
        giftIds: defaultGiftIds,
        ignoreCache: false,
      }) as unknown as AnyAction
    );
  };
