/* eslint-disable react/no-array-index-key */
import React from "react";
import classnames from "classnames";
import { useBreakpoint } from "src/ui/hooks/useBreakpoint";
import Typography, {
  TYPOGRAPHY_TYPE,
} from "src/ui/common/typography/Typography";
import styles from "./Table.scss";

type CellType = React.ReactElement | string;

type RowType = CellType[];

type TableHeadItemProps = {
  item: string;
};

const TableHeadItem: React.FC<TableHeadItemProps> = ({ item }) => (
  <Typography type={TYPOGRAPHY_TYPE.HEADLINE6} as="th" className={styles.th}>
    {item}
  </Typography>
);

type TableRowProps = {
  row: CellType[];
};

const TableRow: React.FC<TableRowProps> = ({ row }) => (
  <tr className={styles.tr}>
    {row.map((item, i) => (
      <Typography
        key={i}
        type={TYPOGRAPHY_TYPE.PARAGRAPH4}
        as="td"
        className={styles.td}
      >
        {item}
      </Typography>
    ))}
  </tr>
);

type TableProps = {
  theadData: string[];
  tbodyData: RowType[];
};

const Table: React.FC<TableProps> = ({ theadData, tbodyData }) => {
  const breakpoint = useBreakpoint();

  return (
    <div className={classnames(styles.tableContainer, styles[breakpoint])}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.tr}>
            {theadData.map((h) => (
              <TableHeadItem key={h} item={h} />
            ))}
          </tr>
        </thead>
        <tbody>
          {tbodyData.map((row, i) => (
            <TableRow key={i} row={row} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
