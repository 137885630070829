import React, { memo } from "react";
import { useLocation } from "react-router-dom";
import { MessageDescriptor } from "react-intl";
import { HTMLDivProps } from "src/types/common";
import LegalNavigationItem from "./LegalNavigationItem";
import styles from "./LegalNavigation.scss";

export interface LegalNavigationConfig {
  to: string;
  message: MessageDescriptor;
  dataTestId?: string;
}

interface LegalNavigationProps extends HTMLDivProps {
  navigationConfig: LegalNavigationConfig[];
}

const LegalNavigation: React.FC<LegalNavigationProps> = ({
  navigationConfig,
}) => {
  const { pathname } = useLocation();

  return (
    <div className={styles.root}>
      {navigationConfig.map(({ message, to, dataTestId }) => (
        <LegalNavigationItem
          key={to}
          to={to}
          message={message}
          selected={to === pathname}
          data-testid={dataTestId}
        />
      ))}
    </div>
  );
};

export default memo(LegalNavigation);
