import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import {
  WEBVIEW_ONLY_ROUTES,
  WEBVIEW_QUERY_PARAM,
} from "src/core/webview/WebViewController/constants";
import { actionCreators } from "state/tree/deviceInfo";
import useQuery from "ui/navigation/useQuery";

const { setWebview } = actionCreators;

/*
Necessary to improve WebView detection by finding simpler and more stable
methods instead of relying on routes and query parameters.
*/
export const useWebViewDetector = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const isWebViewOnlyRoute = !!useRouteMatch(WEBVIEW_ONLY_ROUTES);
  const hasWebViewQueryParam = query.has(WEBVIEW_QUERY_PARAM);
  const isWebView = isWebViewOnlyRoute || hasWebViewQueryParam;

  useLayoutEffect(() => {
    // Set the WebView mode till the end of the user's session
    if (isWebView) {
      dispatch(setWebview(isWebView));
    }
  }, [dispatch, isWebView]);
};
