import React from "react";
import classnames from "classnames";
import Typography, {
  TYPOGRAPHY_TYPE,
} from "src/ui/common/typography/Typography";
import styles from "../../common/LegalArticle.scss";

type ListItemProps = {
  checked?: boolean;
  unchecked?: boolean;
};

const ListItem: React.FC<ListItemProps> = ({
  checked,
  unchecked,
  children,
}) => (
  <Typography
    type={TYPOGRAPHY_TYPE.PARAGRAPH4}
    as="li"
    className={classnames(styles.li, {
      [styles.checked]: checked,
      [styles.unchecked]: unchecked,
    })}
  >
    {children}
  </Typography>
);

export default ListItem;
