import { Nullable } from "src/types/common";

export interface RoundedNumberOptions extends Intl.NumberFormatOptions {
  locale: string;
}

export default (
  count: Nullable<number | string | undefined>,
  { locale, ...rest }: RoundedNumberOptions = {} as RoundedNumberOptions
) => {
  let number = count;
  let abbrev = "";
  if (count == null || typeof count !== "number" || isNaN(count)) {
    number = 0;
  } else if (count >= 1000000) {
    number = Math.round(count / 100000) / 10;
    abbrev = "M";
  } else if (count >= 1000) {
    number = Math.round(count / 100) / 10;
    abbrev = "K";
  }

  const formattedNumber = locale
    ? new Intl.NumberFormat(locale, { useGrouping: false, ...rest }).format(
        number as number
      )
    : number;
  return `${formattedNumber}${abbrev}`;
};
