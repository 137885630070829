import { defineMessages } from "react-intl";

export const phoneSettingsMessages = defineMessages({
  phoneNumberConnectTitle: {
    id: "modal.phone-connect.title",
    defaultMessage: "Connect Phone Number",
  },
  phoneNumberConnectDescription: {
    id: "modal.phone-connect.description",
    defaultMessage: "Verification code will be sent to this number",
  },
  phoneNumberTitle: {
    id: "buy-coins.checkout.fawry.phone-number",
    defaultMessage: "Phone Number",
  },
  phoneNumberEditDescription: {
    id: "modal.email-edit.description",
    defaultMessage:
      "Used only to contact you for special promotions and support. Not visible to anyone in Tango.",
  },
  phonePlaceholder: {
    id: "modal.phone-edit.placeholder",
    defaultMessage: "Phone number",
  },
  phoneNumberVerificationTitle: {
    id: "modal.phone-verification.title",
    defaultMessage: "Enter Verification Code",
  },
  phoneNumberVerificationDescription: {
    id: "modal.login.phone.verification.code.description",
    defaultMessage: "Code is sent to {phoneNumber}",
  },
  phoneNumberSelectCountry: {
    id: "modal.login.phone.select.country.header.title",
    defaultMessage: "Select country",
  },
  invalidVerificationCode: {
    id: "modal.code-verification.invalid",
    defaultMessage: "Invalid code. Please try again.",
  },
  phoneNumberUsed: {
    id: "modal.phone-verification.used",
    defaultMessage: "This phone number is already used",
  },
  phoneNumberNoCountriesFound: {
    id: "modal.login.phone.select.country.content.empty",
    defaultMessage: "Oops, no results found",
  },
  phoneNumberDeleteDescription: {
    id: "modal.phone-delete.description",
    defaultMessage:
      "Are you sure you want to disconnect this phone number from your account?",
  },
  phoneNumberDisconnect: {
    id: "disconnect",
    defaultMessage: "Disconnect",
  },
});

export const MIN_PHONE_NUMBER_LENGTH = 5;

export const WEB_PLATFORM_INDICATOR = 6;

export const SMS_VERIFICATION_PROVIDER = 1;
