import { useLocation } from "react-router-dom";
import { EventFields, STREAM_SOURCE } from "@analytics/enums";
import { CombinedFeedType, StreamSourceChatType } from "src/enums";
import { Nullable } from "src/types/common";
import {
  sourceForLeaderboardTimeframe,
  sourceForLiveStreamsFeedMode,
} from "./streamSource";

export const defaultLocationState = {
  [EventFields.STREAM_SOURCE]: STREAM_SOURCE.DIRECT_LINK,
};

export type LiveStreamLocationState = {
  LiveSessionStreamSource: number;
  mode: string;
  rank_in_list: number;
  shouldScrollToCurrentStream?: boolean;
  tag?: string;
};

export const createLocationStateForLiveStreamFeed = ({
  mode,
  rank,
  shouldScrollToCurrentStream,
}: {
  mode: CombinedFeedType;
  rank: number;
  shouldScrollToCurrentStream?: boolean;
}): LiveStreamLocationState => {
  const source = sourceForLiveStreamsFeedMode(mode);
  return {
    [EventFields.STREAM_SOURCE]: source,
    [EventFields.STREAM_RANK_IN_LIST]: rank,
    [EventFields.STREAM_MODE]: mode,
    shouldScrollToCurrentStream,
    ...(source === STREAM_SOURCE.TAGGED_FEED && {
      [EventFields.STREAM_TAG]: mode,
    }),
  };
};

export type LeaderboardLocationState = {
  LiveSessionStreamSource: number;
  rank_in_list: number;
};

export const createLocationStateForLeaderboard = ({
  timeframe,
  rank,
}: {
  rank: number;
  timeframe: string;
}) => ({
  [EventFields.STREAM_SOURCE]: sourceForLeaderboardTimeframe(timeframe),
  [EventFields.STREAM_RANK_IN_LIST]: rank,
});

export const createLocationStateForNotification = () => ({
  [EventFields.STREAM_SOURCE]: STREAM_SOURCE.SYSTEM_NOTIFICATION,
});

export const createLocationStateForLiveButtonInProfile = () => ({
  [EventFields.STREAM_SOURCE]: STREAM_SOURCE.PROFILE_LIVE_BUTTON,
});

export type ChatLocationState = {
  LiveSessionStreamSource: number;
  chat_type: StreamSourceChatType;
};
export const createLocationStateForChat = (
  chatType: StreamSourceChatType
): ChatLocationState => ({
  [EventFields.STREAM_SOURCE]: STREAM_SOURCE.CHAT,
  [EventFields.STREAM_CHAT_SOURCE_TYPE]: chatType,
});

export type ViralityLinkLocationState = {
  LiveSessionStreamSource: number;
  uid?: string;
};
export const createLocationStateForViralityLink = (
  uid: Nullable<string>
): ViralityLinkLocationState => ({
  [EventFields.STREAM_SOURCE]: STREAM_SOURCE.DIRECT_LINK,
  ...(uid && { uid }),
});

export const createLocationStateForHappyMoment = () => ({
  [EventFields.STREAM_SOURCE]: STREAM_SOURCE.HAPPY_MOMENT,
});

export const useStreamLocationState = () => {
  const { state: locationState = defaultLocationState } = useLocation<
    LiveStreamLocationState | ViralityLinkLocationState | undefined
  >();
  return locationState;
};
