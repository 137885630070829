import React, { FC, SyntheticEvent, useCallback, useRef } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import {
  EventFields,
  SCREEN_NAME,
  ScreenViewReporter,
} from "src/features/signin/imports/analytics";
import { deviceInfoSelectors } from "src/features/signin/imports/state";
import { TYPOGRAPHY_TYPE, Typography } from "src/features/signin/imports/ui";
import { loginSetSelectCountryStep } from "src/features/signin/state/login/actionCreators";
import { ReactComponent as ChevIcon } from "img/countryPicker/chev.svg";
import styles from "src/features/signin/modal/login/steps/PhoneNumberInput/PhoneNumberInput.scss";

interface PhoneInputProps {
  autoFocus?: boolean;
  countryCode?: string;
  countryIcon?: string;
  errorMessage?: string;
  initialPhoneNumber: number | string;
  isInputWarningDisplayed: boolean;
  onClick?: () => void;
  onKeyDown?: (event: SyntheticEvent) => void;
  setIsInputWarningDisplayed: (bool: boolean) => void;
  setPhoneNumber: (phoneNumber: string) => void;
}

const messages = defineMessages({
  phoneNumber: {
    id: "buy-coins.checkout.fawry.phone-number",
    defaultMessage: "Phone Number",
  },
  phoneNumberDescription: {
    id: "modal.login.phoneNumberDescription",
    defaultMessage: "Invalid phone number. Try again.",
  },
});

const PhoneInput: FC<PhoneInputProps> = ({
  setPhoneNumber,
  countryCode = "",
  countryIcon,
  isInputWarningDisplayed,
  setIsInputWarningDisplayed,
  autoFocus,
  errorMessage,
  initialPhoneNumber,
  onKeyDown,
  onClick,
}) => {
  const dispatch = useDispatch();
  const phoneNumberInputRef = useRef<HTMLInputElement | null>(null);
  const isAnyMobileDevice = useSelector(deviceInfoSelectors.isAnyMobileDevice);
  const { formatMessage } = useIntl();

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isInputWarningDisplayed) {
        setIsInputWarningDisplayed(false);
      }

      const phoneNumber = event.target.value.trim();

      setPhoneNumber(phoneNumber);
    },
    [isInputWarningDisplayed, setIsInputWarningDisplayed, setPhoneNumber]
  );

  const handleCountryPickerClick = useCallback(() => {
    if (onClick) {
      onClick();

      return;
    }

    dispatch(loginSetSelectCountryStep(true));
  }, [dispatch, onClick]);

  return (
    <>
      <div
        className={classnames(styles.inputContainer, {
          [styles.error]: isInputWarningDisplayed,
        })}
      >
        <div
          className={styles.countryPicker}
          onClick={handleCountryPickerClick}
        >
          <div
            className={styles.countryFlag}
            style={{
              backgroundImage: `url(${countryIcon})`,
            }}
          />
          <ChevIcon />
        </div>
        <Typography
          type={TYPOGRAPHY_TYPE.PARAGRAPH2}
          className={styles.countryCode}
        >
          {`+${countryCode}`}
        </Typography>
        <input
          ref={phoneNumberInputRef}
          data-testid="phone-number-input"
          inputMode="tel"
          autoFocus={autoFocus || !isAnyMobileDevice}
          className={styles.phoneInput}
          onChange={onChange}
          onKeyDown={onKeyDown}
          defaultValue={initialPhoneNumber}
          placeholder={formatMessage(messages.phoneNumber)}
        />
      </div>
      {isInputWarningDisplayed && (
        <>
          <ScreenViewReporter
            name={SCREEN_NAME.REGISTRATION_SIGN_IN}
            additionalParams={{
              [EventFields.SCREEN_STATE]: "incorrect_phone",
            }}
          />
          <Typography
            className={styles.phoneInputDescription}
            as="div"
            type={TYPOGRAPHY_TYPE.PARAGRAPH5}
          >
            <FormattedMessage {...messages.phoneNumberDescription} />
          </Typography>
        </>
      )}
      {errorMessage && (
        <Typography
          className={styles.phoneInputDescription}
          as="div"
          type={TYPOGRAPHY_TYPE.PARAGRAPH5}
        >
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

export default PhoneInput;
