import { emitUiAction } from "@analytics/emitUiAction";
import { EventFields, SCREEN_NAME } from "@analytics/enums";
import { StreamKind } from "src/types/richFragment/Stream";
import isGroupChatId from "src/utils/isGroupChatId";

interface EmitMessageTranslateEventParams {
  chatId?: string;
  intValue: number;
  itemId: TranslateItemIds;
  itemType: string;
  peerId?: string;
  screenName: string;
  streamId?: string;
  translateFromLang?: string;
  translateToLang: string;
}

export enum TranslateScreenNames {
  CHAT_GROUP = SCREEN_NAME.CHAT_GROUP,
  CHAT_SINGLE = SCREEN_NAME.CHAT_SINGLE,
  LIVE_PLAYER_CHAT = "live.player.chat",
  LIVE_PLAYER_PRIVATE = "live.player.private",
  LIVE_PLAYER_PUBLIC = "live.player.public",
}

export enum TranslateItemIds {
  OFFLINE_CHAT = "offline_chat",
  STREAM_CHAT = "stream_chat",
}

const getScreenNameByStreamKind = (kind: string) => {
  switch (kind) {
    case StreamKind.PUBLIC:
      return TranslateScreenNames.LIVE_PLAYER_PUBLIC;
    case StreamKind.PRIVATE:
    case StreamKind.TICKET_PRIVATE:
      return TranslateScreenNames.LIVE_PLAYER_PRIVATE;
    case StreamKind.CHAT:
      return TranslateScreenNames.LIVE_PLAYER_CHAT;
    default:
      return "";
  }
};

const getScreenNameByConversationId = (conversationId: string) =>
  isGroupChatId(conversationId)
    ? TranslateScreenNames.CHAT_GROUP
    : TranslateScreenNames.CHAT_SINGLE;

const emitMessageTranslateEvent = ({
  chatId,
  translateToLang,
  screenName,
  streamId,
  peerId,
  intValue,
  itemId,
  itemType,
  translateFromLang,
}: EmitMessageTranslateEventParams) => {
  emitUiAction({
    target: "chat_message",
    additionalParams: {
      [EventFields.SCREEN_NAME]: screenName,
      [EventFields.PEER_ID]: peerId,
      [EventFields.COMMENT]: translateFromLang,
      [EventFields.INT_VALUE]: intValue,
      [EventFields.ITEM_ID]: itemId,
      [EventFields.ITEM_TYPE]: itemType,
      [EventFields.LANGUAGE]: translateToLang,
      ...(streamId && { [EventFields.STREAM_ID]: streamId }),
      ...(chatId && { [EventFields.CHAT_ID]: chatId }),
    },
  });
};

export { getScreenNameByConversationId, getScreenNameByStreamKind };
export default emitMessageTranslateEvent;
