import { useCallback, useContext } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import copy from "copy-to-clipboard";
import { useSnackbar } from "notistack";
import {
  SHARE_TARGET_COPY_LINK,
  SHARE_TARGET_MORE,
  emitShareAction,
} from "@analytics/emitShareEvent";
import ScreenViewReportingContext from "@analytics/screenView/ScreenViewReportingContext";
import { VoidCallback } from "src/types/common";
import {
  activateClipboard,
  copyAndRemove,
} from "src/utils/copyToAppleClipboard";
import { deviceInfoSelectors, userSelectors } from "state/selectors";
import sharedMessages from "ui/common/intl/sharedMessages";
import useUiAction from "ui/hooks/useUiAction";

export interface Options {
  afterLinkCopied?: VoidCallback;
  afterShare?: VoidCallback;
  emitOnly?: boolean;
  link: string;
  onUnableToShare?: VoidCallback;
  title: string;
}

export default ({
  link,
  title,
  afterShare,
  afterLinkCopied,
  onUnableToShare,
  emitOnly = false,
}: Options) => {
  const deviceType = useSelector(deviceInfoSelectors.getDeviceType);
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const { getStack } = useContext(ScreenViewReportingContext);
  const [screen] = getStack();
  const accountId = useSelector(userSelectors.getMyAccountId);
  const routeParams = useRouteMatch<{ id: string }>("*/:id");
  const sourceId = routeParams?.params?.id;

  const showCopiedMessage = useCallback(() => {
    enqueueSnackbar(intl.formatMessage(sharedMessages.linkCopied), {
      autoHideDuration: 1500,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      variant: "clipboard",
    });
  }, [intl, enqueueSnackbar]);

  const emitShareEvent = useCallback(
    (target: string) =>
      emitShareAction({
        screen,
        target,
        peerId: accountId,
        sourceId,
      }),
    [screen, accountId, sourceId]
  );

  const callback = useCallback(() => {
    setTimeout(copyAndRemove, 525);
    if (navigator.share) {
      navigator
        .share({
          title,
          url: link,
        })
        .then(() => {
          emitShareEvent(SHARE_TARGET_MORE);
          afterShare?.();
        })
        .catch((e) => {
          if (e.name === "NotAllowedError") {
            activateClipboard(link, deviceType);
            showCopiedMessage();
            afterLinkCopied?.();
          } else {
            onUnableToShare?.();
          }
        });
    } else {
      copy(link);
      emitShareEvent(SHARE_TARGET_COPY_LINK);
      showCopiedMessage();
      afterLinkCopied?.();
    }
  }, [
    link,
    title,
    afterShare,
    deviceType,
    showCopiedMessage,
    afterLinkCopied,
    onUnableToShare,
  ]);

  return useUiAction({
    target: "share",
    callback: emitOnly ? afterShare : callback,
  });
};
