import {
  EventFields,
  UIActionTarget,
  emitUiAction,
} from "@analytics/messages/imports/analytics";

export const emitChatInboxClicked = (size: number) => {
  emitUiAction({
    target: UIActionTarget.CHAT_REQUESTS,
    additionalParams: {
      [EventFields.SIZE]: size,
    },
  });
};
