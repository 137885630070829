import { defineMessages } from "react-intl";

export const messages = defineMessages({
  codeWasSentViaSms: {
    id: "modal.login.phone.verification.code.description",
    defaultMessage: "Code is sent to {phoneNumber}",
  },
  codeWasSentViaTango: {
    id: "modal.login.phone.send.code.to.tango.app",
    defaultMessage:
      "Check your {tangoApp}. We have sent a {tangoChat} message with a 4 digit code.",
  },
  codeWasSentViaWhatsApp: {
    id: "code.was.sent.to.via.provider",
    defaultMessage: "Code was sent to {phoneNumber} via {provider}",
  },
  tangoApp: {
    id: "modal.login.tangoApp",
    defaultMessage: "Tango app",
  },
  tangoChat: {
    id: "modal.login.tangoChat",
    defaultMessage: "Tango chat",
  },
  whatsApp: {
    id: "WhatsApp",
    defaultMessage: "WhatsApp",
  },
});
