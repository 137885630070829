import {
  ChatMessageSentFlags,
  ChatMessageSentReason,
  ConversationScreenState,
  MessageStatusResult,
} from "@analytics/messages/chatMessageTypes";
import {
  EventFields,
  EventNames,
  SCREEN_NAME,
  emitEvent,
} from "@analytics/messages/imports/analytics";

export enum SingleChatMessageSentComment {
  CHAT_REQUEST_ACCEPTED = "chat_request_accepted",
  CHAT_REQUEST_SENT = "chat_request_sent",
  EMPTY = "",
}

export interface ChatMessageSentAnalyticsParams {
  isChatRequest: boolean;
  isGroupChat: boolean;
  isResend: boolean;
}

export interface EmitSingleChatMessageSentParams {
  chatId: string;
  comment: SingleChatMessageSentComment | null;
  flags: ChatMessageSentFlags[];
  peerId?: string;
  reason: ChatMessageSentReason;
  recipientAccountId: string;
  result: MessageStatusResult;
  screenState: ConversationScreenState;
}

export const emitSingleChatMessageSent = ({
  recipientAccountId,
  chatId,
  flags,
  peerId = "",
  comment,
  screenState,
  result,
  reason,
}: EmitSingleChatMessageSentParams) => {
  emitEvent(EventNames.SINGLE_CHAT_MESSAGE_SENT, {
    [EventFields.RECIPIENT_ACCOUNT_ID]: recipientAccountId,
    [EventFields.CHAT_ID]: chatId,
    [EventFields.FLAGS]: flags,
    [EventFields.PEER_ID]: peerId,
    [EventFields.COMMENT]: comment ?? null,
    [EventFields.SCREEN]: SCREEN_NAME.CHAT_SINGLE,
    [EventFields.SCREEN_STATE]: screenState,
    [EventFields.RESULT]: result,
    [EventFields.REASON]: reason,
  });
};
