import React, { useCallback, useEffect, useMemo, useState } from "react";
import usePersistentState from "ui/hooks/usePersistentState";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { DARK_THEME } from "src/constants";
import dimens from "sassPartials/_dimens.scss";
import emptyFunction from "fbjs/lib/emptyFunction";
import { ThemeType } from "src/enums";
import { VoidCallback } from "src/types/common";
import styles from "./Theme.scss";

const themeBase = (type: ThemeType) => ({
  typography: {
    fontFamily: "Lato,sans-serif",
  },
  palette: {
    type,
    primary: {
      light: "#fc3d6b",
      main: "#fc3d6b",
      dark: "#fc3d6b",
      contrastText: "#fff",
    },
  },
  zIndex: { modal: (dimens.modalZ as number) + 10 },
  overrides: {
    MuiPaper: {
      root: {
        transition: "none !important",
      },
    },
  },
});

const darkMuiTheme = createTheme(themeBase(ThemeType.DARK));
const lightMuiTheme = createTheme(themeBase(ThemeType.LIGHT));

interface ThemeContext {
  dark: boolean;
  setDark: (value: boolean) => void;
  lockTheme: (type: ThemeType) => void;
  unLockTheme: VoidCallback;
}

export const themeLockClassnames: Record<ThemeType, string> = {
  [ThemeType.LIGHT]: styles.themeLight,
  [ThemeType.DARK]: styles.themeDark,
};

export const ThemeContext = React.createContext<ThemeContext>({
  dark: true,
  setDark: emptyFunction,
  lockTheme: emptyFunction,
  unLockTheme: emptyFunction,
});

export const ThemeProvider: React.FC = ({ children }) => {
  const [dark, setDark] = usePersistentState({
    key: DARK_THEME,
    initialValue: true,
  });
  const [lockedTheme, setLockTheme] = useState<null | ThemeType>(null);

  const isDarkTheme = useMemo(
    () => (!lockedTheme ? dark : lockedTheme === ThemeType.DARK),
    [lockedTheme, dark]
  );

  const getMuiTheme = useCallback(
    (isDarkTheme) => (isDarkTheme ? darkMuiTheme : lightMuiTheme),
    []
  );

  const [muiTheme, setMuiTheme] = useState(getMuiTheme(dark));

  const setRootThemeClassname = useCallback((isDark: boolean) => {
    document.body.classList.remove(
      isDark ? themeLockClassnames.light : themeLockClassnames.dark
    );
    document.body.classList.add(
      isDark ? themeLockClassnames.dark : themeLockClassnames.light,
      styles.noTransition
    );
    // preventing transitions on theme change
    window.getComputedStyle(document.body).opacity;
    document.body.classList.remove(styles.noTransition);

    setMuiTheme(getMuiTheme(isDark));
  }, []);

  const lockTheme = useCallback((type: ThemeType) => setLockTheme(type), []);
  const unLockTheme = useCallback(() => setLockTheme(null), []);

  const contextValues = useMemo(
    () => ({
      dark: isDarkTheme,
      setDark,
      lockTheme,
      unLockTheme,
    }),
    [dark, setDark, lockTheme, unLockTheme, isDarkTheme]
  );

  useEffect(() => {
    setRootThemeClassname(isDarkTheme);
  }, [isDarkTheme, lockedTheme]);

  return (
    <ThemeContext.Provider value={contextValues}>
      <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
