import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import siftWrapper from "@analytics/sift/siftWrapper";
import { getSiftBeaconKey } from "state/abTests";
import { RootState } from "state/delegate";
import { userSelectors } from "state/selectors";

const selector = (state: RootState) => ({
  accountId: userSelectors.getMyAccountId(state),
  siftBeaconKey: getSiftBeaconKey(state),
});

const useSiftSetup = () => {
  const { accountId, siftBeaconKey } = useSelector(selector, shallowEqual);

  useEffect(() => {
    if (siftBeaconKey) {
      siftWrapper.init(siftBeaconKey);
    }
  }, [siftBeaconKey]);

  useEffect(() => {
    if (accountId) {
      siftWrapper.setUserId(accountId);
    } else {
      siftWrapper.unsetUserId();
    }
  }, [accountId]);
};

export default useSiftSetup;
