/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.FeedApi || ($protobuf.roots.FeedApi = {});

export const TimelineResponse = $root.TimelineResponse = (() => {

    function TimelineResponse(properties) {
        this.posts = [];
        this.availableUnlockPostsMap = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    TimelineResponse.prototype.code = 1;
    TimelineResponse.prototype.posts = $util.emptyArray;
    TimelineResponse.prototype.nextCursor = "";
    TimelineResponse.prototype.previousCursor = "";
    TimelineResponse.prototype.availableUnlockPostsMap = $util.emptyArray;

    TimelineResponse.create = function create(properties) {
        return new TimelineResponse(properties);
    };

    TimelineResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.posts != null && message.posts.length)
            for (let i = 0; i < message.posts.length; ++i)
                $root.Post.encode(message.posts[i], writer.uint32(18).fork()).ldelim();
        if (message.nextCursor != null && Object.hasOwnProperty.call(message, "nextCursor"))
            writer.uint32(26).string(message.nextCursor);
        if (message.previousCursor != null && Object.hasOwnProperty.call(message, "previousCursor"))
            writer.uint32(34).string(message.previousCursor);
        if (message.availableUnlockPostsMap != null && message.availableUnlockPostsMap.length)
            for (let i = 0; i < message.availableUnlockPostsMap.length; ++i)
                $root.AvailableUnlockPostsMap.encode(message.availableUnlockPostsMap[i], writer.uint32(42).fork()).ldelim();
        return writer;
    };

    TimelineResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    TimelineResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TimelineResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.posts && message.posts.length))
                        message.posts = [];
                    message.posts.push($root.Post.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.nextCursor = reader.string();
                    break;
                }
            case 4: {
                    message.previousCursor = reader.string();
                    break;
                }
            case 5: {
                    if (!(message.availableUnlockPostsMap && message.availableUnlockPostsMap.length))
                        message.availableUnlockPostsMap = [];
                    message.availableUnlockPostsMap.push($root.AvailableUnlockPostsMap.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    TimelineResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    TimelineResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.TimelineResponse)
            return object;
        let message = new $root.TimelineResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "FAILED_BAD_REQUEST":
        case 4:
            message.code = 4;
            break;
        case "FAILED_NOT_FOUND":
        case 5:
            message.code = 5;
            break;
        }
        if (object.posts) {
            if (!Array.isArray(object.posts))
                throw TypeError(".TimelineResponse.posts: array expected");
            message.posts = [];
            for (let i = 0; i < object.posts.length; ++i) {
                if (typeof object.posts[i] !== "object")
                    throw TypeError(".TimelineResponse.posts: object expected");
                message.posts[i] = $root.Post.fromObject(object.posts[i]);
            }
        }
        if (object.nextCursor != null)
            message.nextCursor = String(object.nextCursor);
        if (object.previousCursor != null)
            message.previousCursor = String(object.previousCursor);
        if (object.availableUnlockPostsMap) {
            if (!Array.isArray(object.availableUnlockPostsMap))
                throw TypeError(".TimelineResponse.availableUnlockPostsMap: array expected");
            message.availableUnlockPostsMap = [];
            for (let i = 0; i < object.availableUnlockPostsMap.length; ++i) {
                if (typeof object.availableUnlockPostsMap[i] !== "object")
                    throw TypeError(".TimelineResponse.availableUnlockPostsMap: object expected");
                message.availableUnlockPostsMap[i] = $root.AvailableUnlockPostsMap.fromObject(object.availableUnlockPostsMap[i]);
            }
        }
        return message;
    };

    TimelineResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.posts = [];
            object.availableUnlockPostsMap = [];
        }
        if (options.defaults) {
            object.code = options.enums === String ? "OK" : 1;
            object.nextCursor = "";
            object.previousCursor = "";
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.posts && message.posts.length) {
            object.posts = [];
            for (let j = 0; j < message.posts.length; ++j)
                object.posts[j] = $root.Post.toObject(message.posts[j], options);
        }
        if (message.nextCursor != null && message.hasOwnProperty("nextCursor"))
            object.nextCursor = message.nextCursor;
        if (message.previousCursor != null && message.hasOwnProperty("previousCursor"))
            object.previousCursor = message.previousCursor;
        if (message.availableUnlockPostsMap && message.availableUnlockPostsMap.length) {
            object.availableUnlockPostsMap = [];
            for (let j = 0; j < message.availableUnlockPostsMap.length; ++j)
                object.availableUnlockPostsMap[j] = $root.AvailableUnlockPostsMap.toObject(message.availableUnlockPostsMap[j], options);
        }
        return object;
    };

    TimelineResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    TimelineResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TimelineResponse";
    };

    return TimelineResponse;
})();

export const AvailableUnlockPostsMap = $root.AvailableUnlockPostsMap = (() => {

    function AvailableUnlockPostsMap(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    AvailableUnlockPostsMap.prototype.accountId = "";
    AvailableUnlockPostsMap.prototype.count = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    AvailableUnlockPostsMap.create = function create(properties) {
        return new AvailableUnlockPostsMap(properties);
    };

    AvailableUnlockPostsMap.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.accountId);
        writer.uint32(16).int64(message.count);
        return writer;
    };

    AvailableUnlockPostsMap.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    AvailableUnlockPostsMap.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AvailableUnlockPostsMap();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.accountId = reader.string();
                    break;
                }
            case 2: {
                    message.count = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("accountId"))
            throw $util.ProtocolError("missing required 'accountId'", { instance: message });
        if (!message.hasOwnProperty("count"))
            throw $util.ProtocolError("missing required 'count'", { instance: message });
        return message;
    };

    AvailableUnlockPostsMap.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    AvailableUnlockPostsMap.fromObject = function fromObject(object) {
        if (object instanceof $root.AvailableUnlockPostsMap)
            return object;
        let message = new $root.AvailableUnlockPostsMap();
        if (object.accountId != null)
            message.accountId = String(object.accountId);
        if (object.count != null)
            if ($util.Long)
                (message.count = $util.Long.fromValue(object.count)).unsigned = false;
            else if (typeof object.count === "string")
                message.count = parseInt(object.count, 10);
            else if (typeof object.count === "number")
                message.count = object.count;
            else if (typeof object.count === "object")
                message.count = new $util.LongBits(object.count.low >>> 0, object.count.high >>> 0).toNumber();
        return message;
    };

    AvailableUnlockPostsMap.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.accountId = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.count = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.count = options.longs === String ? "0" : 0;
        }
        if (message.accountId != null && message.hasOwnProperty("accountId"))
            object.accountId = message.accountId;
        if (message.count != null && message.hasOwnProperty("count"))
            if (typeof message.count === "number")
                object.count = options.longs === String ? String(message.count) : message.count;
            else
                object.count = options.longs === String ? $util.Long.prototype.toString.call(message.count) : options.longs === Number ? new $util.LongBits(message.count.low >>> 0, message.count.high >>> 0).toNumber() : message.count;
        return object;
    };

    AvailableUnlockPostsMap.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    AvailableUnlockPostsMap.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AvailableUnlockPostsMap";
    };

    return AvailableUnlockPostsMap;
})();

export const FeedByAccountResponse = $root.FeedByAccountResponse = (() => {

    function FeedByAccountResponse(properties) {
        this.posts = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    FeedByAccountResponse.prototype.code = 1;
    FeedByAccountResponse.prototype.posts = $util.emptyArray;
    FeedByAccountResponse.prototype.nextCursor = "";
    FeedByAccountResponse.prototype.previousCursor = "";
    FeedByAccountResponse.prototype.availableUnlockPostsCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    FeedByAccountResponse.create = function create(properties) {
        return new FeedByAccountResponse(properties);
    };

    FeedByAccountResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.posts != null && message.posts.length)
            for (let i = 0; i < message.posts.length; ++i)
                $root.Post.encode(message.posts[i], writer.uint32(18).fork()).ldelim();
        if (message.nextCursor != null && Object.hasOwnProperty.call(message, "nextCursor"))
            writer.uint32(26).string(message.nextCursor);
        if (message.previousCursor != null && Object.hasOwnProperty.call(message, "previousCursor"))
            writer.uint32(34).string(message.previousCursor);
        if (message.availableUnlockPostsCount != null && Object.hasOwnProperty.call(message, "availableUnlockPostsCount"))
            writer.uint32(40).int64(message.availableUnlockPostsCount);
        return writer;
    };

    FeedByAccountResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    FeedByAccountResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.FeedByAccountResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.posts && message.posts.length))
                        message.posts = [];
                    message.posts.push($root.Post.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.nextCursor = reader.string();
                    break;
                }
            case 4: {
                    message.previousCursor = reader.string();
                    break;
                }
            case 5: {
                    message.availableUnlockPostsCount = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    FeedByAccountResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    FeedByAccountResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.FeedByAccountResponse)
            return object;
        let message = new $root.FeedByAccountResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "FAILED_BAD_REQUEST":
        case 4:
            message.code = 4;
            break;
        case "FAILED_NOT_FOUND":
        case 5:
            message.code = 5;
            break;
        }
        if (object.posts) {
            if (!Array.isArray(object.posts))
                throw TypeError(".FeedByAccountResponse.posts: array expected");
            message.posts = [];
            for (let i = 0; i < object.posts.length; ++i) {
                if (typeof object.posts[i] !== "object")
                    throw TypeError(".FeedByAccountResponse.posts: object expected");
                message.posts[i] = $root.Post.fromObject(object.posts[i]);
            }
        }
        if (object.nextCursor != null)
            message.nextCursor = String(object.nextCursor);
        if (object.previousCursor != null)
            message.previousCursor = String(object.previousCursor);
        if (object.availableUnlockPostsCount != null)
            if ($util.Long)
                (message.availableUnlockPostsCount = $util.Long.fromValue(object.availableUnlockPostsCount)).unsigned = false;
            else if (typeof object.availableUnlockPostsCount === "string")
                message.availableUnlockPostsCount = parseInt(object.availableUnlockPostsCount, 10);
            else if (typeof object.availableUnlockPostsCount === "number")
                message.availableUnlockPostsCount = object.availableUnlockPostsCount;
            else if (typeof object.availableUnlockPostsCount === "object")
                message.availableUnlockPostsCount = new $util.LongBits(object.availableUnlockPostsCount.low >>> 0, object.availableUnlockPostsCount.high >>> 0).toNumber();
        return message;
    };

    FeedByAccountResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.posts = [];
        if (options.defaults) {
            object.code = options.enums === String ? "OK" : 1;
            object.nextCursor = "";
            object.previousCursor = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.availableUnlockPostsCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.availableUnlockPostsCount = options.longs === String ? "0" : 0;
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.posts && message.posts.length) {
            object.posts = [];
            for (let j = 0; j < message.posts.length; ++j)
                object.posts[j] = $root.Post.toObject(message.posts[j], options);
        }
        if (message.nextCursor != null && message.hasOwnProperty("nextCursor"))
            object.nextCursor = message.nextCursor;
        if (message.previousCursor != null && message.hasOwnProperty("previousCursor"))
            object.previousCursor = message.previousCursor;
        if (message.availableUnlockPostsCount != null && message.hasOwnProperty("availableUnlockPostsCount"))
            if (typeof message.availableUnlockPostsCount === "number")
                object.availableUnlockPostsCount = options.longs === String ? String(message.availableUnlockPostsCount) : message.availableUnlockPostsCount;
            else
                object.availableUnlockPostsCount = options.longs === String ? $util.Long.prototype.toString.call(message.availableUnlockPostsCount) : options.longs === Number ? new $util.LongBits(message.availableUnlockPostsCount.low >>> 0, message.availableUnlockPostsCount.high >>> 0).toNumber() : message.availableUnlockPostsCount;
        return object;
    };

    FeedByAccountResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    FeedByAccountResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FeedByAccountResponse";
    };

    return FeedByAccountResponse;
})();

export const PublicFeedByAccountResponse = $root.PublicFeedByAccountResponse = (() => {

    function PublicFeedByAccountResponse(properties) {
        this.posts = [];
        this.moments = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    PublicFeedByAccountResponse.prototype.code = 1;
    PublicFeedByAccountResponse.prototype.posts = $util.emptyArray;
    PublicFeedByAccountResponse.prototype.moments = $util.emptyArray;

    PublicFeedByAccountResponse.create = function create(properties) {
        return new PublicFeedByAccountResponse(properties);
    };

    PublicFeedByAccountResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.posts != null && message.posts.length)
            for (let i = 0; i < message.posts.length; ++i)
                $root.Post.encode(message.posts[i], writer.uint32(18).fork()).ldelim();
        if (message.moments != null && message.moments.length)
            for (let i = 0; i < message.moments.length; ++i)
                $root.Post.encode(message.moments[i], writer.uint32(26).fork()).ldelim();
        return writer;
    };

    PublicFeedByAccountResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    PublicFeedByAccountResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PublicFeedByAccountResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.posts && message.posts.length))
                        message.posts = [];
                    message.posts.push($root.Post.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    if (!(message.moments && message.moments.length))
                        message.moments = [];
                    message.moments.push($root.Post.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    PublicFeedByAccountResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    PublicFeedByAccountResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.PublicFeedByAccountResponse)
            return object;
        let message = new $root.PublicFeedByAccountResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "FAILED_BAD_REQUEST":
        case 4:
            message.code = 4;
            break;
        case "FAILED_NOT_FOUND":
        case 5:
            message.code = 5;
            break;
        }
        if (object.posts) {
            if (!Array.isArray(object.posts))
                throw TypeError(".PublicFeedByAccountResponse.posts: array expected");
            message.posts = [];
            for (let i = 0; i < object.posts.length; ++i) {
                if (typeof object.posts[i] !== "object")
                    throw TypeError(".PublicFeedByAccountResponse.posts: object expected");
                message.posts[i] = $root.Post.fromObject(object.posts[i]);
            }
        }
        if (object.moments) {
            if (!Array.isArray(object.moments))
                throw TypeError(".PublicFeedByAccountResponse.moments: array expected");
            message.moments = [];
            for (let i = 0; i < object.moments.length; ++i) {
                if (typeof object.moments[i] !== "object")
                    throw TypeError(".PublicFeedByAccountResponse.moments: object expected");
                message.moments[i] = $root.Post.fromObject(object.moments[i]);
            }
        }
        return message;
    };

    PublicFeedByAccountResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.posts = [];
            object.moments = [];
        }
        if (options.defaults)
            object.code = options.enums === String ? "OK" : 1;
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.posts && message.posts.length) {
            object.posts = [];
            for (let j = 0; j < message.posts.length; ++j)
                object.posts[j] = $root.Post.toObject(message.posts[j], options);
        }
        if (message.moments && message.moments.length) {
            object.moments = [];
            for (let j = 0; j < message.moments.length; ++j)
                object.moments[j] = $root.Post.toObject(message.moments[j], options);
        }
        return object;
    };

    PublicFeedByAccountResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    PublicFeedByAccountResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PublicFeedByAccountResponse";
    };

    return PublicFeedByAccountResponse;
})();

export const FeedArchivedCountResponse = $root.FeedArchivedCountResponse = (() => {

    function FeedArchivedCountResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    FeedArchivedCountResponse.prototype.code = 1;
    FeedArchivedCountResponse.prototype.archivedCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    FeedArchivedCountResponse.create = function create(properties) {
        return new FeedArchivedCountResponse(properties);
    };

    FeedArchivedCountResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.archivedCount != null && Object.hasOwnProperty.call(message, "archivedCount"))
            writer.uint32(16).int64(message.archivedCount);
        return writer;
    };

    FeedArchivedCountResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    FeedArchivedCountResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.FeedArchivedCountResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.archivedCount = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    FeedArchivedCountResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    FeedArchivedCountResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.FeedArchivedCountResponse)
            return object;
        let message = new $root.FeedArchivedCountResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "FAILED_BAD_REQUEST":
        case 4:
            message.code = 4;
            break;
        case "FAILED_NOT_FOUND":
        case 5:
            message.code = 5;
            break;
        }
        if (object.archivedCount != null)
            if ($util.Long)
                (message.archivedCount = $util.Long.fromValue(object.archivedCount)).unsigned = false;
            else if (typeof object.archivedCount === "string")
                message.archivedCount = parseInt(object.archivedCount, 10);
            else if (typeof object.archivedCount === "number")
                message.archivedCount = object.archivedCount;
            else if (typeof object.archivedCount === "object")
                message.archivedCount = new $util.LongBits(object.archivedCount.low >>> 0, object.archivedCount.high >>> 0).toNumber();
        return message;
    };

    FeedArchivedCountResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.code = options.enums === String ? "OK" : 1;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.archivedCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.archivedCount = options.longs === String ? "0" : 0;
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.archivedCount != null && message.hasOwnProperty("archivedCount"))
            if (typeof message.archivedCount === "number")
                object.archivedCount = options.longs === String ? String(message.archivedCount) : message.archivedCount;
            else
                object.archivedCount = options.longs === String ? $util.Long.prototype.toString.call(message.archivedCount) : options.longs === Number ? new $util.LongBits(message.archivedCount.low >>> 0, message.archivedCount.high >>> 0).toNumber() : message.archivedCount;
        return object;
    };

    FeedArchivedCountResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    FeedArchivedCountResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FeedArchivedCountResponse";
    };

    return FeedArchivedCountResponse;
})();

export const FeedArchivedResponse = $root.FeedArchivedResponse = (() => {

    function FeedArchivedResponse(properties) {
        this.posts = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    FeedArchivedResponse.prototype.code = 1;
    FeedArchivedResponse.prototype.posts = $util.emptyArray;
    FeedArchivedResponse.prototype.nextCursor = "";
    FeedArchivedResponse.prototype.previousCursor = "";

    FeedArchivedResponse.create = function create(properties) {
        return new FeedArchivedResponse(properties);
    };

    FeedArchivedResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.posts != null && message.posts.length)
            for (let i = 0; i < message.posts.length; ++i)
                $root.Post.encode(message.posts[i], writer.uint32(18).fork()).ldelim();
        if (message.nextCursor != null && Object.hasOwnProperty.call(message, "nextCursor"))
            writer.uint32(26).string(message.nextCursor);
        if (message.previousCursor != null && Object.hasOwnProperty.call(message, "previousCursor"))
            writer.uint32(34).string(message.previousCursor);
        return writer;
    };

    FeedArchivedResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    FeedArchivedResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.FeedArchivedResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.posts && message.posts.length))
                        message.posts = [];
                    message.posts.push($root.Post.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.nextCursor = reader.string();
                    break;
                }
            case 4: {
                    message.previousCursor = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    FeedArchivedResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    FeedArchivedResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.FeedArchivedResponse)
            return object;
        let message = new $root.FeedArchivedResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "FAILED_BAD_REQUEST":
        case 4:
            message.code = 4;
            break;
        case "FAILED_NOT_FOUND":
        case 5:
            message.code = 5;
            break;
        }
        if (object.posts) {
            if (!Array.isArray(object.posts))
                throw TypeError(".FeedArchivedResponse.posts: array expected");
            message.posts = [];
            for (let i = 0; i < object.posts.length; ++i) {
                if (typeof object.posts[i] !== "object")
                    throw TypeError(".FeedArchivedResponse.posts: object expected");
                message.posts[i] = $root.Post.fromObject(object.posts[i]);
            }
        }
        if (object.nextCursor != null)
            message.nextCursor = String(object.nextCursor);
        if (object.previousCursor != null)
            message.previousCursor = String(object.previousCursor);
        return message;
    };

    FeedArchivedResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.posts = [];
        if (options.defaults) {
            object.code = options.enums === String ? "OK" : 1;
            object.nextCursor = "";
            object.previousCursor = "";
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.posts && message.posts.length) {
            object.posts = [];
            for (let j = 0; j < message.posts.length; ++j)
                object.posts[j] = $root.Post.toObject(message.posts[j], options);
        }
        if (message.nextCursor != null && message.hasOwnProperty("nextCursor"))
            object.nextCursor = message.nextCursor;
        if (message.previousCursor != null && message.hasOwnProperty("previousCursor"))
            object.previousCursor = message.previousCursor;
        return object;
    };

    FeedArchivedResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    FeedArchivedResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FeedArchivedResponse";
    };

    return FeedArchivedResponse;
})();

export const ExclusivePostBannerResponse = $root.ExclusivePostBannerResponse = (() => {

    function ExclusivePostBannerResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ExclusivePostBannerResponse.prototype.code = 1;
    ExclusivePostBannerResponse.prototype.exclusivePostsCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    ExclusivePostBannerResponse.prototype.postThumbnailUrl = "";

    ExclusivePostBannerResponse.create = function create(properties) {
        return new ExclusivePostBannerResponse(properties);
    };

    ExclusivePostBannerResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.exclusivePostsCount != null && Object.hasOwnProperty.call(message, "exclusivePostsCount"))
            writer.uint32(16).int64(message.exclusivePostsCount);
        if (message.postThumbnailUrl != null && Object.hasOwnProperty.call(message, "postThumbnailUrl"))
            writer.uint32(26).string(message.postThumbnailUrl);
        return writer;
    };

    ExclusivePostBannerResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ExclusivePostBannerResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ExclusivePostBannerResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.exclusivePostsCount = reader.int64();
                    break;
                }
            case 3: {
                    message.postThumbnailUrl = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    ExclusivePostBannerResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ExclusivePostBannerResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.ExclusivePostBannerResponse)
            return object;
        let message = new $root.ExclusivePostBannerResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "FAILED_BAD_REQUEST":
        case 4:
            message.code = 4;
            break;
        case "FAILED_NOT_FOUND":
        case 5:
            message.code = 5;
            break;
        }
        if (object.exclusivePostsCount != null)
            if ($util.Long)
                (message.exclusivePostsCount = $util.Long.fromValue(object.exclusivePostsCount)).unsigned = false;
            else if (typeof object.exclusivePostsCount === "string")
                message.exclusivePostsCount = parseInt(object.exclusivePostsCount, 10);
            else if (typeof object.exclusivePostsCount === "number")
                message.exclusivePostsCount = object.exclusivePostsCount;
            else if (typeof object.exclusivePostsCount === "object")
                message.exclusivePostsCount = new $util.LongBits(object.exclusivePostsCount.low >>> 0, object.exclusivePostsCount.high >>> 0).toNumber();
        if (object.postThumbnailUrl != null)
            message.postThumbnailUrl = String(object.postThumbnailUrl);
        return message;
    };

    ExclusivePostBannerResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.code = options.enums === String ? "OK" : 1;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.exclusivePostsCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.exclusivePostsCount = options.longs === String ? "0" : 0;
            object.postThumbnailUrl = "";
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.exclusivePostsCount != null && message.hasOwnProperty("exclusivePostsCount"))
            if (typeof message.exclusivePostsCount === "number")
                object.exclusivePostsCount = options.longs === String ? String(message.exclusivePostsCount) : message.exclusivePostsCount;
            else
                object.exclusivePostsCount = options.longs === String ? $util.Long.prototype.toString.call(message.exclusivePostsCount) : options.longs === Number ? new $util.LongBits(message.exclusivePostsCount.low >>> 0, message.exclusivePostsCount.high >>> 0).toNumber() : message.exclusivePostsCount;
        if (message.postThumbnailUrl != null && message.hasOwnProperty("postThumbnailUrl"))
            object.postThumbnailUrl = message.postThumbnailUrl;
        return object;
    };

    ExclusivePostBannerResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ExclusivePostBannerResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ExclusivePostBannerResponse";
    };

    return ExclusivePostBannerResponse;
})();

export const PostLikesResponse = $root.PostLikesResponse = (() => {

    function PostLikesResponse(properties) {
        this.likers = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    PostLikesResponse.prototype.code = 1;
    PostLikesResponse.prototype.totalCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    PostLikesResponse.prototype.likedByMe = false;
    PostLikesResponse.prototype.likers = $util.emptyArray;
    PostLikesResponse.prototype.nextCursor = "";
    PostLikesResponse.prototype.previousCursor = "";

    PostLikesResponse.create = function create(properties) {
        return new PostLikesResponse(properties);
    };

    PostLikesResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.totalCount != null && Object.hasOwnProperty.call(message, "totalCount"))
            writer.uint32(16).int64(message.totalCount);
        if (message.likedByMe != null && Object.hasOwnProperty.call(message, "likedByMe"))
            writer.uint32(24).bool(message.likedByMe);
        if (message.likers != null && message.likers.length)
            for (let i = 0; i < message.likers.length; ++i)
                $root.PostUserProfile.encode(message.likers[i], writer.uint32(34).fork()).ldelim();
        if (message.nextCursor != null && Object.hasOwnProperty.call(message, "nextCursor"))
            writer.uint32(42).string(message.nextCursor);
        if (message.previousCursor != null && Object.hasOwnProperty.call(message, "previousCursor"))
            writer.uint32(50).string(message.previousCursor);
        return writer;
    };

    PostLikesResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    PostLikesResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PostLikesResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.totalCount = reader.int64();
                    break;
                }
            case 3: {
                    message.likedByMe = reader.bool();
                    break;
                }
            case 4: {
                    if (!(message.likers && message.likers.length))
                        message.likers = [];
                    message.likers.push($root.PostUserProfile.decode(reader, reader.uint32()));
                    break;
                }
            case 5: {
                    message.nextCursor = reader.string();
                    break;
                }
            case 6: {
                    message.previousCursor = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    PostLikesResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    PostLikesResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.PostLikesResponse)
            return object;
        let message = new $root.PostLikesResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "FAILED_BAD_REQUEST":
        case 4:
            message.code = 4;
            break;
        case "FAILED_NOT_FOUND":
        case 5:
            message.code = 5;
            break;
        }
        if (object.totalCount != null)
            if ($util.Long)
                (message.totalCount = $util.Long.fromValue(object.totalCount)).unsigned = false;
            else if (typeof object.totalCount === "string")
                message.totalCount = parseInt(object.totalCount, 10);
            else if (typeof object.totalCount === "number")
                message.totalCount = object.totalCount;
            else if (typeof object.totalCount === "object")
                message.totalCount = new $util.LongBits(object.totalCount.low >>> 0, object.totalCount.high >>> 0).toNumber();
        if (object.likedByMe != null)
            message.likedByMe = Boolean(object.likedByMe);
        if (object.likers) {
            if (!Array.isArray(object.likers))
                throw TypeError(".PostLikesResponse.likers: array expected");
            message.likers = [];
            for (let i = 0; i < object.likers.length; ++i) {
                if (typeof object.likers[i] !== "object")
                    throw TypeError(".PostLikesResponse.likers: object expected");
                message.likers[i] = $root.PostUserProfile.fromObject(object.likers[i]);
            }
        }
        if (object.nextCursor != null)
            message.nextCursor = String(object.nextCursor);
        if (object.previousCursor != null)
            message.previousCursor = String(object.previousCursor);
        return message;
    };

    PostLikesResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.likers = [];
        if (options.defaults) {
            object.code = options.enums === String ? "OK" : 1;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.totalCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.totalCount = options.longs === String ? "0" : 0;
            object.likedByMe = false;
            object.nextCursor = "";
            object.previousCursor = "";
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.totalCount != null && message.hasOwnProperty("totalCount"))
            if (typeof message.totalCount === "number")
                object.totalCount = options.longs === String ? String(message.totalCount) : message.totalCount;
            else
                object.totalCount = options.longs === String ? $util.Long.prototype.toString.call(message.totalCount) : options.longs === Number ? new $util.LongBits(message.totalCount.low >>> 0, message.totalCount.high >>> 0).toNumber() : message.totalCount;
        if (message.likedByMe != null && message.hasOwnProperty("likedByMe"))
            object.likedByMe = message.likedByMe;
        if (message.likers && message.likers.length) {
            object.likers = [];
            for (let j = 0; j < message.likers.length; ++j)
                object.likers[j] = $root.PostUserProfile.toObject(message.likers[j], options);
        }
        if (message.nextCursor != null && message.hasOwnProperty("nextCursor"))
            object.nextCursor = message.nextCursor;
        if (message.previousCursor != null && message.hasOwnProperty("previousCursor"))
            object.previousCursor = message.previousCursor;
        return object;
    };

    PostLikesResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    PostLikesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PostLikesResponse";
    };

    return PostLikesResponse;
})();

export const PostLikeUnlikeResponse = $root.PostLikeUnlikeResponse = (() => {

    function PostLikeUnlikeResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    PostLikeUnlikeResponse.prototype.code = 1;
    PostLikeUnlikeResponse.prototype.totalCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    PostLikeUnlikeResponse.prototype.likedByMe = false;
    PostLikeUnlikeResponse.prototype.lastLiker = null;

    PostLikeUnlikeResponse.create = function create(properties) {
        return new PostLikeUnlikeResponse(properties);
    };

    PostLikeUnlikeResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.totalCount != null && Object.hasOwnProperty.call(message, "totalCount"))
            writer.uint32(16).int64(message.totalCount);
        if (message.likedByMe != null && Object.hasOwnProperty.call(message, "likedByMe"))
            writer.uint32(24).bool(message.likedByMe);
        if (message.lastLiker != null && Object.hasOwnProperty.call(message, "lastLiker"))
            $root.PostUserProfile.encode(message.lastLiker, writer.uint32(34).fork()).ldelim();
        return writer;
    };

    PostLikeUnlikeResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    PostLikeUnlikeResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PostLikeUnlikeResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.totalCount = reader.int64();
                    break;
                }
            case 3: {
                    message.likedByMe = reader.bool();
                    break;
                }
            case 4: {
                    message.lastLiker = $root.PostUserProfile.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    PostLikeUnlikeResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    PostLikeUnlikeResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.PostLikeUnlikeResponse)
            return object;
        let message = new $root.PostLikeUnlikeResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "FAILED_BAD_REQUEST":
        case 4:
            message.code = 4;
            break;
        case "FAILED_NOT_FOUND":
        case 5:
            message.code = 5;
            break;
        }
        if (object.totalCount != null)
            if ($util.Long)
                (message.totalCount = $util.Long.fromValue(object.totalCount)).unsigned = false;
            else if (typeof object.totalCount === "string")
                message.totalCount = parseInt(object.totalCount, 10);
            else if (typeof object.totalCount === "number")
                message.totalCount = object.totalCount;
            else if (typeof object.totalCount === "object")
                message.totalCount = new $util.LongBits(object.totalCount.low >>> 0, object.totalCount.high >>> 0).toNumber();
        if (object.likedByMe != null)
            message.likedByMe = Boolean(object.likedByMe);
        if (object.lastLiker != null) {
            if (typeof object.lastLiker !== "object")
                throw TypeError(".PostLikeUnlikeResponse.lastLiker: object expected");
            message.lastLiker = $root.PostUserProfile.fromObject(object.lastLiker);
        }
        return message;
    };

    PostLikeUnlikeResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.code = options.enums === String ? "OK" : 1;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.totalCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.totalCount = options.longs === String ? "0" : 0;
            object.likedByMe = false;
            object.lastLiker = null;
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.totalCount != null && message.hasOwnProperty("totalCount"))
            if (typeof message.totalCount === "number")
                object.totalCount = options.longs === String ? String(message.totalCount) : message.totalCount;
            else
                object.totalCount = options.longs === String ? $util.Long.prototype.toString.call(message.totalCount) : options.longs === Number ? new $util.LongBits(message.totalCount.low >>> 0, message.totalCount.high >>> 0).toNumber() : message.totalCount;
        if (message.likedByMe != null && message.hasOwnProperty("likedByMe"))
            object.likedByMe = message.likedByMe;
        if (message.lastLiker != null && message.hasOwnProperty("lastLiker"))
            object.lastLiker = $root.PostUserProfile.toObject(message.lastLiker, options);
        return object;
    };

    PostLikeUnlikeResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    PostLikeUnlikeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PostLikeUnlikeResponse";
    };

    return PostLikeUnlikeResponse;
})();

export const PostCommentsResponse = $root.PostCommentsResponse = (() => {

    function PostCommentsResponse(properties) {
        this.comments = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    PostCommentsResponse.prototype.code = 1;
    PostCommentsResponse.prototype.nextCursor = "";
    PostCommentsResponse.prototype.previousCursor = "";
    PostCommentsResponse.prototype.comments = $util.emptyArray;

    PostCommentsResponse.create = function create(properties) {
        return new PostCommentsResponse(properties);
    };

    PostCommentsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.nextCursor != null && Object.hasOwnProperty.call(message, "nextCursor"))
            writer.uint32(18).string(message.nextCursor);
        if (message.previousCursor != null && Object.hasOwnProperty.call(message, "previousCursor"))
            writer.uint32(26).string(message.previousCursor);
        if (message.comments != null && message.comments.length)
            for (let i = 0; i < message.comments.length; ++i)
                $root.PostComment.encode(message.comments[i], writer.uint32(34).fork()).ldelim();
        return writer;
    };

    PostCommentsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    PostCommentsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PostCommentsResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.nextCursor = reader.string();
                    break;
                }
            case 3: {
                    message.previousCursor = reader.string();
                    break;
                }
            case 4: {
                    if (!(message.comments && message.comments.length))
                        message.comments = [];
                    message.comments.push($root.PostComment.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    PostCommentsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    PostCommentsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.PostCommentsResponse)
            return object;
        let message = new $root.PostCommentsResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "FAILED_BAD_REQUEST":
        case 4:
            message.code = 4;
            break;
        case "FAILED_NOT_FOUND":
        case 5:
            message.code = 5;
            break;
        }
        if (object.nextCursor != null)
            message.nextCursor = String(object.nextCursor);
        if (object.previousCursor != null)
            message.previousCursor = String(object.previousCursor);
        if (object.comments) {
            if (!Array.isArray(object.comments))
                throw TypeError(".PostCommentsResponse.comments: array expected");
            message.comments = [];
            for (let i = 0; i < object.comments.length; ++i) {
                if (typeof object.comments[i] !== "object")
                    throw TypeError(".PostCommentsResponse.comments: object expected");
                message.comments[i] = $root.PostComment.fromObject(object.comments[i]);
            }
        }
        return message;
    };

    PostCommentsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.comments = [];
        if (options.defaults) {
            object.code = options.enums === String ? "OK" : 1;
            object.nextCursor = "";
            object.previousCursor = "";
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.nextCursor != null && message.hasOwnProperty("nextCursor"))
            object.nextCursor = message.nextCursor;
        if (message.previousCursor != null && message.hasOwnProperty("previousCursor"))
            object.previousCursor = message.previousCursor;
        if (message.comments && message.comments.length) {
            object.comments = [];
            for (let j = 0; j < message.comments.length; ++j)
                object.comments[j] = $root.PostComment.toObject(message.comments[j], options);
        }
        return object;
    };

    PostCommentsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    PostCommentsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PostCommentsResponse";
    };

    return PostCommentsResponse;
})();

export const AddCommentRequest = $root.AddCommentRequest = (() => {

    function AddCommentRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    AddCommentRequest.prototype.content = "";
    AddCommentRequest.prototype.type = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    AddCommentRequest.prototype.tag = "";
    AddCommentRequest.prototype.postOwnerId = "";

    AddCommentRequest.create = function create(properties) {
        return new AddCommentRequest(properties);
    };

    AddCommentRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.content);
        writer.uint32(16).int64(message.type);
        if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
            writer.uint32(26).string(message.tag);
        if (message.postOwnerId != null && Object.hasOwnProperty.call(message, "postOwnerId"))
            writer.uint32(34).string(message.postOwnerId);
        return writer;
    };

    AddCommentRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    AddCommentRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AddCommentRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.content = reader.string();
                    break;
                }
            case 2: {
                    message.type = reader.int64();
                    break;
                }
            case 3: {
                    message.tag = reader.string();
                    break;
                }
            case 4: {
                    message.postOwnerId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("content"))
            throw $util.ProtocolError("missing required 'content'", { instance: message });
        if (!message.hasOwnProperty("type"))
            throw $util.ProtocolError("missing required 'type'", { instance: message });
        return message;
    };

    AddCommentRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    AddCommentRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.AddCommentRequest)
            return object;
        let message = new $root.AddCommentRequest();
        if (object.content != null)
            message.content = String(object.content);
        if (object.type != null)
            if ($util.Long)
                (message.type = $util.Long.fromValue(object.type)).unsigned = false;
            else if (typeof object.type === "string")
                message.type = parseInt(object.type, 10);
            else if (typeof object.type === "number")
                message.type = object.type;
            else if (typeof object.type === "object")
                message.type = new $util.LongBits(object.type.low >>> 0, object.type.high >>> 0).toNumber();
        if (object.tag != null)
            message.tag = String(object.tag);
        if (object.postOwnerId != null)
            message.postOwnerId = String(object.postOwnerId);
        return message;
    };

    AddCommentRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.content = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.type = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.type = options.longs === String ? "0" : 0;
            object.tag = "";
            object.postOwnerId = "";
        }
        if (message.content != null && message.hasOwnProperty("content"))
            object.content = message.content;
        if (message.type != null && message.hasOwnProperty("type"))
            if (typeof message.type === "number")
                object.type = options.longs === String ? String(message.type) : message.type;
            else
                object.type = options.longs === String ? $util.Long.prototype.toString.call(message.type) : options.longs === Number ? new $util.LongBits(message.type.low >>> 0, message.type.high >>> 0).toNumber() : message.type;
        if (message.tag != null && message.hasOwnProperty("tag"))
            object.tag = message.tag;
        if (message.postOwnerId != null && message.hasOwnProperty("postOwnerId"))
            object.postOwnerId = message.postOwnerId;
        return object;
    };

    AddCommentRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    AddCommentRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AddCommentRequest";
    };

    return AddCommentRequest;
})();

export const AddCommentResponse = $root.AddCommentResponse = (() => {

    function AddCommentResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    AddCommentResponse.prototype.code = 1;
    AddCommentResponse.prototype.commentId = "";
    AddCommentResponse.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    AddCommentResponse.prototype.tag = "";

    AddCommentResponse.create = function create(properties) {
        return new AddCommentResponse(properties);
    };

    AddCommentResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.commentId != null && Object.hasOwnProperty.call(message, "commentId"))
            writer.uint32(18).string(message.commentId);
        if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
            writer.uint32(24).int64(message.timestamp);
        if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
            writer.uint32(34).string(message.tag);
        return writer;
    };

    AddCommentResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    AddCommentResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AddCommentResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.commentId = reader.string();
                    break;
                }
            case 3: {
                    message.timestamp = reader.int64();
                    break;
                }
            case 4: {
                    message.tag = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    AddCommentResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    AddCommentResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.AddCommentResponse)
            return object;
        let message = new $root.AddCommentResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "FAILED_BAD_REQUEST":
        case 4:
            message.code = 4;
            break;
        case "FAILED_NOT_FOUND":
        case 5:
            message.code = 5;
            break;
        }
        if (object.commentId != null)
            message.commentId = String(object.commentId);
        if (object.timestamp != null)
            if ($util.Long)
                (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
            else if (typeof object.timestamp === "string")
                message.timestamp = parseInt(object.timestamp, 10);
            else if (typeof object.timestamp === "number")
                message.timestamp = object.timestamp;
            else if (typeof object.timestamp === "object")
                message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
        if (object.tag != null)
            message.tag = String(object.tag);
        return message;
    };

    AddCommentResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.code = options.enums === String ? "OK" : 1;
            object.commentId = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.timestamp = options.longs === String ? "0" : 0;
            object.tag = "";
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.commentId != null && message.hasOwnProperty("commentId"))
            object.commentId = message.commentId;
        if (message.timestamp != null && message.hasOwnProperty("timestamp"))
            if (typeof message.timestamp === "number")
                object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
            else
                object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
        if (message.tag != null && message.hasOwnProperty("tag"))
            object.tag = message.tag;
        return object;
    };

    AddCommentResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    AddCommentResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AddCommentResponse";
    };

    return AddCommentResponse;
})();

export const PostDetailsRequest = $root.PostDetailsRequest = (() => {

    function PostDetailsRequest(properties) {
        this.postIds = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    PostDetailsRequest.prototype.postIds = $util.emptyArray;

    PostDetailsRequest.create = function create(properties) {
        return new PostDetailsRequest(properties);
    };

    PostDetailsRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.postIds != null && message.postIds.length)
            for (let i = 0; i < message.postIds.length; ++i)
                writer.uint32(8).int64(message.postIds[i]);
        return writer;
    };

    PostDetailsRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    PostDetailsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PostDetailsRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.postIds && message.postIds.length))
                        message.postIds = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.postIds.push(reader.int64());
                    } else
                        message.postIds.push(reader.int64());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    PostDetailsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    PostDetailsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.PostDetailsRequest)
            return object;
        let message = new $root.PostDetailsRequest();
        if (object.postIds) {
            if (!Array.isArray(object.postIds))
                throw TypeError(".PostDetailsRequest.postIds: array expected");
            message.postIds = [];
            for (let i = 0; i < object.postIds.length; ++i)
                if ($util.Long)
                    (message.postIds[i] = $util.Long.fromValue(object.postIds[i])).unsigned = false;
                else if (typeof object.postIds[i] === "string")
                    message.postIds[i] = parseInt(object.postIds[i], 10);
                else if (typeof object.postIds[i] === "number")
                    message.postIds[i] = object.postIds[i];
                else if (typeof object.postIds[i] === "object")
                    message.postIds[i] = new $util.LongBits(object.postIds[i].low >>> 0, object.postIds[i].high >>> 0).toNumber();
        }
        return message;
    };

    PostDetailsRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.postIds = [];
        if (message.postIds && message.postIds.length) {
            object.postIds = [];
            for (let j = 0; j < message.postIds.length; ++j)
                if (typeof message.postIds[j] === "number")
                    object.postIds[j] = options.longs === String ? String(message.postIds[j]) : message.postIds[j];
                else
                    object.postIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.postIds[j]) : options.longs === Number ? new $util.LongBits(message.postIds[j].low >>> 0, message.postIds[j].high >>> 0).toNumber() : message.postIds[j];
        }
        return object;
    };

    PostDetailsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    PostDetailsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PostDetailsRequest";
    };

    return PostDetailsRequest;
})();

export const PostDetailsResponse = $root.PostDetailsResponse = (() => {

    function PostDetailsResponse(properties) {
        this.posts = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    PostDetailsResponse.prototype.code = 1;
    PostDetailsResponse.prototype.posts = $util.emptyArray;

    PostDetailsResponse.create = function create(properties) {
        return new PostDetailsResponse(properties);
    };

    PostDetailsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.posts != null && message.posts.length)
            for (let i = 0; i < message.posts.length; ++i)
                $root.Post.encode(message.posts[i], writer.uint32(18).fork()).ldelim();
        return writer;
    };

    PostDetailsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    PostDetailsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PostDetailsResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.posts && message.posts.length))
                        message.posts = [];
                    message.posts.push($root.Post.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    PostDetailsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    PostDetailsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.PostDetailsResponse)
            return object;
        let message = new $root.PostDetailsResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "FAILED_BAD_REQUEST":
        case 4:
            message.code = 4;
            break;
        case "FAILED_NOT_FOUND":
        case 5:
            message.code = 5;
            break;
        }
        if (object.posts) {
            if (!Array.isArray(object.posts))
                throw TypeError(".PostDetailsResponse.posts: array expected");
            message.posts = [];
            for (let i = 0; i < object.posts.length; ++i) {
                if (typeof object.posts[i] !== "object")
                    throw TypeError(".PostDetailsResponse.posts: object expected");
                message.posts[i] = $root.Post.fromObject(object.posts[i]);
            }
        }
        return message;
    };

    PostDetailsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.posts = [];
        if (options.defaults)
            object.code = options.enums === String ? "OK" : 1;
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.posts && message.posts.length) {
            object.posts = [];
            for (let j = 0; j < message.posts.length; ++j)
                object.posts[j] = $root.Post.toObject(message.posts[j], options);
        }
        return object;
    };

    PostDetailsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    PostDetailsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PostDetailsResponse";
    };

    return PostDetailsResponse;
})();

export const AddPostRequest = $root.AddPostRequest = (() => {

    function AddPostRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    AddPostRequest.prototype.type = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    AddPostRequest.prototype.content = "";
    AddPostRequest.prototype.tag = "";
    AddPostRequest.prototype.subscription = null;

    AddPostRequest.create = function create(properties) {
        return new AddPostRequest(properties);
    };

    AddPostRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int64(message.type);
        writer.uint32(18).string(message.content);
        if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
            writer.uint32(26).string(message.tag);
        if (message.subscription != null && Object.hasOwnProperty.call(message, "subscription"))
            $root.SubscriptionDetails.encode(message.subscription, writer.uint32(34).fork()).ldelim();
        return writer;
    };

    AddPostRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    AddPostRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AddPostRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.type = reader.int64();
                    break;
                }
            case 2: {
                    message.content = reader.string();
                    break;
                }
            case 3: {
                    message.tag = reader.string();
                    break;
                }
            case 4: {
                    message.subscription = $root.SubscriptionDetails.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("type"))
            throw $util.ProtocolError("missing required 'type'", { instance: message });
        if (!message.hasOwnProperty("content"))
            throw $util.ProtocolError("missing required 'content'", { instance: message });
        return message;
    };

    AddPostRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    AddPostRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.AddPostRequest)
            return object;
        let message = new $root.AddPostRequest();
        if (object.type != null)
            if ($util.Long)
                (message.type = $util.Long.fromValue(object.type)).unsigned = false;
            else if (typeof object.type === "string")
                message.type = parseInt(object.type, 10);
            else if (typeof object.type === "number")
                message.type = object.type;
            else if (typeof object.type === "object")
                message.type = new $util.LongBits(object.type.low >>> 0, object.type.high >>> 0).toNumber();
        if (object.content != null)
            message.content = String(object.content);
        if (object.tag != null)
            message.tag = String(object.tag);
        if (object.subscription != null) {
            if (typeof object.subscription !== "object")
                throw TypeError(".AddPostRequest.subscription: object expected");
            message.subscription = $root.SubscriptionDetails.fromObject(object.subscription);
        }
        return message;
    };

    AddPostRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.type = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.type = options.longs === String ? "0" : 0;
            object.content = "";
            object.tag = "";
            object.subscription = null;
        }
        if (message.type != null && message.hasOwnProperty("type"))
            if (typeof message.type === "number")
                object.type = options.longs === String ? String(message.type) : message.type;
            else
                object.type = options.longs === String ? $util.Long.prototype.toString.call(message.type) : options.longs === Number ? new $util.LongBits(message.type.low >>> 0, message.type.high >>> 0).toNumber() : message.type;
        if (message.content != null && message.hasOwnProperty("content"))
            object.content = message.content;
        if (message.tag != null && message.hasOwnProperty("tag"))
            object.tag = message.tag;
        if (message.subscription != null && message.hasOwnProperty("subscription"))
            object.subscription = $root.SubscriptionDetails.toObject(message.subscription, options);
        return object;
    };

    AddPostRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    AddPostRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AddPostRequest";
    };

    return AddPostRequest;
})();

export const AddPostResponse = $root.AddPostResponse = (() => {

    function AddPostResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    AddPostResponse.prototype.code = 1;
    AddPostResponse.prototype.post = null;
    AddPostResponse.prototype.tag = "";

    AddPostResponse.create = function create(properties) {
        return new AddPostResponse(properties);
    };

    AddPostResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.post != null && Object.hasOwnProperty.call(message, "post"))
            $root.Post.encode(message.post, writer.uint32(18).fork()).ldelim();
        if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
            writer.uint32(26).string(message.tag);
        return writer;
    };

    AddPostResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    AddPostResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AddPostResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.post = $root.Post.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.tag = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    AddPostResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    AddPostResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.AddPostResponse)
            return object;
        let message = new $root.AddPostResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "FAILED_BAD_REQUEST":
        case 4:
            message.code = 4;
            break;
        case "FAILED_NOT_FOUND":
        case 5:
            message.code = 5;
            break;
        }
        if (object.post != null) {
            if (typeof object.post !== "object")
                throw TypeError(".AddPostResponse.post: object expected");
            message.post = $root.Post.fromObject(object.post);
        }
        if (object.tag != null)
            message.tag = String(object.tag);
        return message;
    };

    AddPostResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.code = options.enums === String ? "OK" : 1;
            object.post = null;
            object.tag = "";
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.post != null && message.hasOwnProperty("post"))
            object.post = $root.Post.toObject(message.post, options);
        if (message.tag != null && message.hasOwnProperty("tag"))
            object.tag = message.tag;
        return object;
    };

    AddPostResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    AddPostResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AddPostResponse";
    };

    return AddPostResponse;
})();

export const FeedDeleteResponse = $root.FeedDeleteResponse = (() => {

    function FeedDeleteResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    FeedDeleteResponse.prototype.code = 1;

    FeedDeleteResponse.create = function create(properties) {
        return new FeedDeleteResponse(properties);
    };

    FeedDeleteResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        return writer;
    };

    FeedDeleteResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    FeedDeleteResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.FeedDeleteResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    FeedDeleteResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    FeedDeleteResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.FeedDeleteResponse)
            return object;
        let message = new $root.FeedDeleteResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "FAILED_BAD_REQUEST":
        case 4:
            message.code = 4;
            break;
        case "FAILED_NOT_FOUND":
        case 5:
            message.code = 5;
            break;
        }
        return message;
    };

    FeedDeleteResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.code = options.enums === String ? "OK" : 1;
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        return object;
    };

    FeedDeleteResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    FeedDeleteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FeedDeleteResponse";
    };

    return FeedDeleteResponse;
})();

export const UnlockExclusivePostResponse = $root.UnlockExclusivePostResponse = (() => {

    function UnlockExclusivePostResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    UnlockExclusivePostResponse.prototype.code = 1;
    UnlockExclusivePostResponse.prototype.post = null;
    UnlockExclusivePostResponse.prototype.availableUnlockPostsCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    UnlockExclusivePostResponse.create = function create(properties) {
        return new UnlockExclusivePostResponse(properties);
    };

    UnlockExclusivePostResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.post != null && Object.hasOwnProperty.call(message, "post"))
            $root.Post.encode(message.post, writer.uint32(18).fork()).ldelim();
        if (message.availableUnlockPostsCount != null && Object.hasOwnProperty.call(message, "availableUnlockPostsCount"))
            writer.uint32(24).int64(message.availableUnlockPostsCount);
        return writer;
    };

    UnlockExclusivePostResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    UnlockExclusivePostResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UnlockExclusivePostResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.post = $root.Post.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.availableUnlockPostsCount = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    UnlockExclusivePostResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    UnlockExclusivePostResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.UnlockExclusivePostResponse)
            return object;
        let message = new $root.UnlockExclusivePostResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "UNLOCK_EXCLUSIVE_POST_DISABLED":
        case 2:
            message.code = 2;
            break;
        case "POST_NOT_EXISTS":
        case 3:
            message.code = 3;
            break;
        case "EXCLUSIVE_POST_NOT_EXISTS":
        case 4:
            message.code = 4;
            break;
        case "SUBSCRIPTION_NOT_EXISTS":
        case 5:
            message.code = 5;
            break;
        case "EXCEEDED_UNLOCK_LIMIT":
        case 6:
            message.code = 6;
            break;
        case "ALREADY_UNLOCKED":
        case 7:
            message.code = 7;
            break;
        case "FAILED_GENERAL":
        case 8:
            message.code = 8;
            break;
        }
        if (object.post != null) {
            if (typeof object.post !== "object")
                throw TypeError(".UnlockExclusivePostResponse.post: object expected");
            message.post = $root.Post.fromObject(object.post);
        }
        if (object.availableUnlockPostsCount != null)
            if ($util.Long)
                (message.availableUnlockPostsCount = $util.Long.fromValue(object.availableUnlockPostsCount)).unsigned = false;
            else if (typeof object.availableUnlockPostsCount === "string")
                message.availableUnlockPostsCount = parseInt(object.availableUnlockPostsCount, 10);
            else if (typeof object.availableUnlockPostsCount === "number")
                message.availableUnlockPostsCount = object.availableUnlockPostsCount;
            else if (typeof object.availableUnlockPostsCount === "object")
                message.availableUnlockPostsCount = new $util.LongBits(object.availableUnlockPostsCount.low >>> 0, object.availableUnlockPostsCount.high >>> 0).toNumber();
        return message;
    };

    UnlockExclusivePostResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.code = options.enums === String ? "OK" : 1;
            object.post = null;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.availableUnlockPostsCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.availableUnlockPostsCount = options.longs === String ? "0" : 0;
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.UnlockExclusivePostResponseCode[message.code] === undefined ? message.code : $root.UnlockExclusivePostResponseCode[message.code] : message.code;
        if (message.post != null && message.hasOwnProperty("post"))
            object.post = $root.Post.toObject(message.post, options);
        if (message.availableUnlockPostsCount != null && message.hasOwnProperty("availableUnlockPostsCount"))
            if (typeof message.availableUnlockPostsCount === "number")
                object.availableUnlockPostsCount = options.longs === String ? String(message.availableUnlockPostsCount) : message.availableUnlockPostsCount;
            else
                object.availableUnlockPostsCount = options.longs === String ? $util.Long.prototype.toString.call(message.availableUnlockPostsCount) : options.longs === Number ? new $util.LongBits(message.availableUnlockPostsCount.low >>> 0, message.availableUnlockPostsCount.high >>> 0).toNumber() : message.availableUnlockPostsCount;
        return object;
    };

    UnlockExclusivePostResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    UnlockExclusivePostResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UnlockExclusivePostResponse";
    };

    return UnlockExclusivePostResponse;
})();

export const PinPostResponse = $root.PinPostResponse = (() => {

    function PinPostResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    PinPostResponse.prototype.code = 1;

    PinPostResponse.create = function create(properties) {
        return new PinPostResponse(properties);
    };

    PinPostResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        return writer;
    };

    PinPostResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    PinPostResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PinPostResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    PinPostResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    PinPostResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.PinPostResponse)
            return object;
        let message = new $root.PinPostResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "FAILED_BAD_REQUEST":
        case 4:
            message.code = 4;
            break;
        case "FAILED_NOT_FOUND":
        case 5:
            message.code = 5;
            break;
        }
        return message;
    };

    PinPostResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.code = options.enums === String ? "OK" : 1;
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        return object;
    };

    PinPostResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    PinPostResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PinPostResponse";
    };

    return PinPostResponse;
})();

export const UnlockExclusivePostResponseCode = $root.UnlockExclusivePostResponseCode = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "OK"] = 1;
    values[valuesById[2] = "UNLOCK_EXCLUSIVE_POST_DISABLED"] = 2;
    values[valuesById[3] = "POST_NOT_EXISTS"] = 3;
    values[valuesById[4] = "EXCLUSIVE_POST_NOT_EXISTS"] = 4;
    values[valuesById[5] = "SUBSCRIPTION_NOT_EXISTS"] = 5;
    values[valuesById[6] = "EXCEEDED_UNLOCK_LIMIT"] = 6;
    values[valuesById[7] = "ALREADY_UNLOCKED"] = 7;
    values[valuesById[8] = "FAILED_GENERAL"] = 8;
    return values;
})();

export const ResponseCode = $root.ResponseCode = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "OK"] = 1;
    values[valuesById[2] = "FAILED_GENERAL"] = 2;
    values[valuesById[3] = "FAILED_UNAUTHORIZED"] = 3;
    values[valuesById[4] = "FAILED_BAD_REQUEST"] = 4;
    values[valuesById[5] = "FAILED_NOT_FOUND"] = 5;
    return values;
})();

export const Post = $root.Post = (() => {

    function Post(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Post.prototype.postId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Post.prototype.type = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Post.prototype.content = "";
    Post.prototype.owner = null;
    Post.prototype.createdTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Post.prototype.unlocked = false;
    Post.prototype.subscriptionDetails = null;
    Post.prototype.likes = null;
    Post.prototype.gifts = null;
    Post.prototype.originalPost = null;
    Post.prototype.commentsCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Post.prototype.isPinned = false;
    Post.prototype.family = null;

    Post.create = function create(properties) {
        return new Post(properties);
    };

    Post.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int64(message.postId);
        writer.uint32(16).int64(message.type);
        writer.uint32(26).string(message.content);
        if (message.owner != null && Object.hasOwnProperty.call(message, "owner"))
            $root.PostUserProfile.encode(message.owner, writer.uint32(34).fork()).ldelim();
        if (message.createdTimestamp != null && Object.hasOwnProperty.call(message, "createdTimestamp"))
            writer.uint32(40).int64(message.createdTimestamp);
        if (message.unlocked != null && Object.hasOwnProperty.call(message, "unlocked"))
            writer.uint32(48).bool(message.unlocked);
        if (message.subscriptionDetails != null && Object.hasOwnProperty.call(message, "subscriptionDetails"))
            $root.SubscriptionDetails.encode(message.subscriptionDetails, writer.uint32(58).fork()).ldelim();
        if (message.likes != null && Object.hasOwnProperty.call(message, "likes"))
            $root.PostLikes.encode(message.likes, writer.uint32(66).fork()).ldelim();
        if (message.gifts != null && Object.hasOwnProperty.call(message, "gifts"))
            $root.PostGifts.encode(message.gifts, writer.uint32(74).fork()).ldelim();
        if (message.originalPost != null && Object.hasOwnProperty.call(message, "originalPost"))
            $root.Post.encode(message.originalPost, writer.uint32(82).fork()).ldelim();
        if (message.commentsCount != null && Object.hasOwnProperty.call(message, "commentsCount"))
            writer.uint32(88).int64(message.commentsCount);
        if (message.isPinned != null && Object.hasOwnProperty.call(message, "isPinned"))
            writer.uint32(96).bool(message.isPinned);
        if (message.family != null && Object.hasOwnProperty.call(message, "family"))
            $root.Family.encode(message.family, writer.uint32(106).fork()).ldelim();
        return writer;
    };

    Post.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Post.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Post();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.postId = reader.int64();
                    break;
                }
            case 2: {
                    message.type = reader.int64();
                    break;
                }
            case 3: {
                    message.content = reader.string();
                    break;
                }
            case 4: {
                    message.owner = $root.PostUserProfile.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.createdTimestamp = reader.int64();
                    break;
                }
            case 6: {
                    message.unlocked = reader.bool();
                    break;
                }
            case 7: {
                    message.subscriptionDetails = $root.SubscriptionDetails.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.likes = $root.PostLikes.decode(reader, reader.uint32());
                    break;
                }
            case 9: {
                    message.gifts = $root.PostGifts.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.originalPost = $root.Post.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    message.commentsCount = reader.int64();
                    break;
                }
            case 12: {
                    message.isPinned = reader.bool();
                    break;
                }
            case 13: {
                    message.family = $root.Family.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("postId"))
            throw $util.ProtocolError("missing required 'postId'", { instance: message });
        if (!message.hasOwnProperty("type"))
            throw $util.ProtocolError("missing required 'type'", { instance: message });
        if (!message.hasOwnProperty("content"))
            throw $util.ProtocolError("missing required 'content'", { instance: message });
        return message;
    };

    Post.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Post.fromObject = function fromObject(object) {
        if (object instanceof $root.Post)
            return object;
        let message = new $root.Post();
        if (object.postId != null)
            if ($util.Long)
                (message.postId = $util.Long.fromValue(object.postId)).unsigned = false;
            else if (typeof object.postId === "string")
                message.postId = parseInt(object.postId, 10);
            else if (typeof object.postId === "number")
                message.postId = object.postId;
            else if (typeof object.postId === "object")
                message.postId = new $util.LongBits(object.postId.low >>> 0, object.postId.high >>> 0).toNumber();
        if (object.type != null)
            if ($util.Long)
                (message.type = $util.Long.fromValue(object.type)).unsigned = false;
            else if (typeof object.type === "string")
                message.type = parseInt(object.type, 10);
            else if (typeof object.type === "number")
                message.type = object.type;
            else if (typeof object.type === "object")
                message.type = new $util.LongBits(object.type.low >>> 0, object.type.high >>> 0).toNumber();
        if (object.content != null)
            message.content = String(object.content);
        if (object.owner != null) {
            if (typeof object.owner !== "object")
                throw TypeError(".Post.owner: object expected");
            message.owner = $root.PostUserProfile.fromObject(object.owner);
        }
        if (object.createdTimestamp != null)
            if ($util.Long)
                (message.createdTimestamp = $util.Long.fromValue(object.createdTimestamp)).unsigned = false;
            else if (typeof object.createdTimestamp === "string")
                message.createdTimestamp = parseInt(object.createdTimestamp, 10);
            else if (typeof object.createdTimestamp === "number")
                message.createdTimestamp = object.createdTimestamp;
            else if (typeof object.createdTimestamp === "object")
                message.createdTimestamp = new $util.LongBits(object.createdTimestamp.low >>> 0, object.createdTimestamp.high >>> 0).toNumber();
        if (object.unlocked != null)
            message.unlocked = Boolean(object.unlocked);
        if (object.subscriptionDetails != null) {
            if (typeof object.subscriptionDetails !== "object")
                throw TypeError(".Post.subscriptionDetails: object expected");
            message.subscriptionDetails = $root.SubscriptionDetails.fromObject(object.subscriptionDetails);
        }
        if (object.likes != null) {
            if (typeof object.likes !== "object")
                throw TypeError(".Post.likes: object expected");
            message.likes = $root.PostLikes.fromObject(object.likes);
        }
        if (object.gifts != null) {
            if (typeof object.gifts !== "object")
                throw TypeError(".Post.gifts: object expected");
            message.gifts = $root.PostGifts.fromObject(object.gifts);
        }
        if (object.originalPost != null) {
            if (typeof object.originalPost !== "object")
                throw TypeError(".Post.originalPost: object expected");
            message.originalPost = $root.Post.fromObject(object.originalPost);
        }
        if (object.commentsCount != null)
            if ($util.Long)
                (message.commentsCount = $util.Long.fromValue(object.commentsCount)).unsigned = false;
            else if (typeof object.commentsCount === "string")
                message.commentsCount = parseInt(object.commentsCount, 10);
            else if (typeof object.commentsCount === "number")
                message.commentsCount = object.commentsCount;
            else if (typeof object.commentsCount === "object")
                message.commentsCount = new $util.LongBits(object.commentsCount.low >>> 0, object.commentsCount.high >>> 0).toNumber();
        if (object.isPinned != null)
            message.isPinned = Boolean(object.isPinned);
        if (object.family != null) {
            if (typeof object.family !== "object")
                throw TypeError(".Post.family: object expected");
            message.family = $root.Family.fromObject(object.family);
        }
        return message;
    };

    Post.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.postId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.postId = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.type = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.type = options.longs === String ? "0" : 0;
            object.content = "";
            object.owner = null;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.createdTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.createdTimestamp = options.longs === String ? "0" : 0;
            object.unlocked = false;
            object.subscriptionDetails = null;
            object.likes = null;
            object.gifts = null;
            object.originalPost = null;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.commentsCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.commentsCount = options.longs === String ? "0" : 0;
            object.isPinned = false;
            object.family = null;
        }
        if (message.postId != null && message.hasOwnProperty("postId"))
            if (typeof message.postId === "number")
                object.postId = options.longs === String ? String(message.postId) : message.postId;
            else
                object.postId = options.longs === String ? $util.Long.prototype.toString.call(message.postId) : options.longs === Number ? new $util.LongBits(message.postId.low >>> 0, message.postId.high >>> 0).toNumber() : message.postId;
        if (message.type != null && message.hasOwnProperty("type"))
            if (typeof message.type === "number")
                object.type = options.longs === String ? String(message.type) : message.type;
            else
                object.type = options.longs === String ? $util.Long.prototype.toString.call(message.type) : options.longs === Number ? new $util.LongBits(message.type.low >>> 0, message.type.high >>> 0).toNumber() : message.type;
        if (message.content != null && message.hasOwnProperty("content"))
            object.content = message.content;
        if (message.owner != null && message.hasOwnProperty("owner"))
            object.owner = $root.PostUserProfile.toObject(message.owner, options);
        if (message.createdTimestamp != null && message.hasOwnProperty("createdTimestamp"))
            if (typeof message.createdTimestamp === "number")
                object.createdTimestamp = options.longs === String ? String(message.createdTimestamp) : message.createdTimestamp;
            else
                object.createdTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.createdTimestamp) : options.longs === Number ? new $util.LongBits(message.createdTimestamp.low >>> 0, message.createdTimestamp.high >>> 0).toNumber() : message.createdTimestamp;
        if (message.unlocked != null && message.hasOwnProperty("unlocked"))
            object.unlocked = message.unlocked;
        if (message.subscriptionDetails != null && message.hasOwnProperty("subscriptionDetails"))
            object.subscriptionDetails = $root.SubscriptionDetails.toObject(message.subscriptionDetails, options);
        if (message.likes != null && message.hasOwnProperty("likes"))
            object.likes = $root.PostLikes.toObject(message.likes, options);
        if (message.gifts != null && message.hasOwnProperty("gifts"))
            object.gifts = $root.PostGifts.toObject(message.gifts, options);
        if (message.originalPost != null && message.hasOwnProperty("originalPost"))
            object.originalPost = $root.Post.toObject(message.originalPost, options);
        if (message.commentsCount != null && message.hasOwnProperty("commentsCount"))
            if (typeof message.commentsCount === "number")
                object.commentsCount = options.longs === String ? String(message.commentsCount) : message.commentsCount;
            else
                object.commentsCount = options.longs === String ? $util.Long.prototype.toString.call(message.commentsCount) : options.longs === Number ? new $util.LongBits(message.commentsCount.low >>> 0, message.commentsCount.high >>> 0).toNumber() : message.commentsCount;
        if (message.isPinned != null && message.hasOwnProperty("isPinned"))
            object.isPinned = message.isPinned;
        if (message.family != null && message.hasOwnProperty("family"))
            object.family = $root.Family.toObject(message.family, options);
        return object;
    };

    Post.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Post.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Post";
    };

    return Post;
})();

export const PostLikes = $root.PostLikes = (() => {

    function PostLikes(properties) {
        this.lastLikers = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    PostLikes.prototype.totalCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    PostLikes.prototype.likedByMe = false;
    PostLikes.prototype.lastLikers = $util.emptyArray;

    PostLikes.create = function create(properties) {
        return new PostLikes(properties);
    };

    PostLikes.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int64(message.totalCount);
        writer.uint32(16).bool(message.likedByMe);
        if (message.lastLikers != null && message.lastLikers.length)
            for (let i = 0; i < message.lastLikers.length; ++i)
                $root.PostUserProfile.encode(message.lastLikers[i], writer.uint32(26).fork()).ldelim();
        return writer;
    };

    PostLikes.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    PostLikes.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PostLikes();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.totalCount = reader.int64();
                    break;
                }
            case 2: {
                    message.likedByMe = reader.bool();
                    break;
                }
            case 3: {
                    if (!(message.lastLikers && message.lastLikers.length))
                        message.lastLikers = [];
                    message.lastLikers.push($root.PostUserProfile.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("totalCount"))
            throw $util.ProtocolError("missing required 'totalCount'", { instance: message });
        if (!message.hasOwnProperty("likedByMe"))
            throw $util.ProtocolError("missing required 'likedByMe'", { instance: message });
        return message;
    };

    PostLikes.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    PostLikes.fromObject = function fromObject(object) {
        if (object instanceof $root.PostLikes)
            return object;
        let message = new $root.PostLikes();
        if (object.totalCount != null)
            if ($util.Long)
                (message.totalCount = $util.Long.fromValue(object.totalCount)).unsigned = false;
            else if (typeof object.totalCount === "string")
                message.totalCount = parseInt(object.totalCount, 10);
            else if (typeof object.totalCount === "number")
                message.totalCount = object.totalCount;
            else if (typeof object.totalCount === "object")
                message.totalCount = new $util.LongBits(object.totalCount.low >>> 0, object.totalCount.high >>> 0).toNumber();
        if (object.likedByMe != null)
            message.likedByMe = Boolean(object.likedByMe);
        if (object.lastLikers) {
            if (!Array.isArray(object.lastLikers))
                throw TypeError(".PostLikes.lastLikers: array expected");
            message.lastLikers = [];
            for (let i = 0; i < object.lastLikers.length; ++i) {
                if (typeof object.lastLikers[i] !== "object")
                    throw TypeError(".PostLikes.lastLikers: object expected");
                message.lastLikers[i] = $root.PostUserProfile.fromObject(object.lastLikers[i]);
            }
        }
        return message;
    };

    PostLikes.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.lastLikers = [];
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.totalCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.totalCount = options.longs === String ? "0" : 0;
            object.likedByMe = false;
        }
        if (message.totalCount != null && message.hasOwnProperty("totalCount"))
            if (typeof message.totalCount === "number")
                object.totalCount = options.longs === String ? String(message.totalCount) : message.totalCount;
            else
                object.totalCount = options.longs === String ? $util.Long.prototype.toString.call(message.totalCount) : options.longs === Number ? new $util.LongBits(message.totalCount.low >>> 0, message.totalCount.high >>> 0).toNumber() : message.totalCount;
        if (message.likedByMe != null && message.hasOwnProperty("likedByMe"))
            object.likedByMe = message.likedByMe;
        if (message.lastLikers && message.lastLikers.length) {
            object.lastLikers = [];
            for (let j = 0; j < message.lastLikers.length; ++j)
                object.lastLikers[j] = $root.PostUserProfile.toObject(message.lastLikers[j], options);
        }
        return object;
    };

    PostLikes.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    PostLikes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PostLikes";
    };

    return PostLikes;
})();

export const PostGifts = $root.PostGifts = (() => {

    function PostGifts(properties) {
        this.topGifters = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    PostGifts.prototype.pointsCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    PostGifts.prototype.topGifters = $util.emptyArray;

    PostGifts.create = function create(properties) {
        return new PostGifts(properties);
    };

    PostGifts.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int64(message.pointsCount);
        if (message.topGifters != null && message.topGifters.length)
            for (let i = 0; i < message.topGifters.length; ++i)
                $root.PostUserProfile.encode(message.topGifters[i], writer.uint32(18).fork()).ldelim();
        return writer;
    };

    PostGifts.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    PostGifts.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PostGifts();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.pointsCount = reader.int64();
                    break;
                }
            case 2: {
                    if (!(message.topGifters && message.topGifters.length))
                        message.topGifters = [];
                    message.topGifters.push($root.PostUserProfile.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("pointsCount"))
            throw $util.ProtocolError("missing required 'pointsCount'", { instance: message });
        return message;
    };

    PostGifts.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    PostGifts.fromObject = function fromObject(object) {
        if (object instanceof $root.PostGifts)
            return object;
        let message = new $root.PostGifts();
        if (object.pointsCount != null)
            if ($util.Long)
                (message.pointsCount = $util.Long.fromValue(object.pointsCount)).unsigned = false;
            else if (typeof object.pointsCount === "string")
                message.pointsCount = parseInt(object.pointsCount, 10);
            else if (typeof object.pointsCount === "number")
                message.pointsCount = object.pointsCount;
            else if (typeof object.pointsCount === "object")
                message.pointsCount = new $util.LongBits(object.pointsCount.low >>> 0, object.pointsCount.high >>> 0).toNumber();
        if (object.topGifters) {
            if (!Array.isArray(object.topGifters))
                throw TypeError(".PostGifts.topGifters: array expected");
            message.topGifters = [];
            for (let i = 0; i < object.topGifters.length; ++i) {
                if (typeof object.topGifters[i] !== "object")
                    throw TypeError(".PostGifts.topGifters: object expected");
                message.topGifters[i] = $root.PostUserProfile.fromObject(object.topGifters[i]);
            }
        }
        return message;
    };

    PostGifts.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.topGifters = [];
        if (options.defaults)
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.pointsCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.pointsCount = options.longs === String ? "0" : 0;
        if (message.pointsCount != null && message.hasOwnProperty("pointsCount"))
            if (typeof message.pointsCount === "number")
                object.pointsCount = options.longs === String ? String(message.pointsCount) : message.pointsCount;
            else
                object.pointsCount = options.longs === String ? $util.Long.prototype.toString.call(message.pointsCount) : options.longs === Number ? new $util.LongBits(message.pointsCount.low >>> 0, message.pointsCount.high >>> 0).toNumber() : message.pointsCount;
        if (message.topGifters && message.topGifters.length) {
            object.topGifters = [];
            for (let j = 0; j < message.topGifters.length; ++j)
                object.topGifters[j] = $root.PostUserProfile.toObject(message.topGifters[j], options);
        }
        return object;
    };

    PostGifts.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    PostGifts.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PostGifts";
    };

    return PostGifts;
})();

export const PostUserProfile = $root.PostUserProfile = (() => {

    function PostUserProfile(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    PostUserProfile.prototype.accountId = "";
    PostUserProfile.prototype.firstName = "";
    PostUserProfile.prototype.lastName = "";
    PostUserProfile.prototype.vipConfigId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    PostUserProfile.prototype.thumbnailUrl = "";

    PostUserProfile.create = function create(properties) {
        return new PostUserProfile(properties);
    };

    PostUserProfile.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.accountId);
        if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
            writer.uint32(18).string(message.firstName);
        if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
            writer.uint32(26).string(message.lastName);
        if (message.vipConfigId != null && Object.hasOwnProperty.call(message, "vipConfigId"))
            writer.uint32(32).uint64(message.vipConfigId);
        if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
            writer.uint32(42).string(message.thumbnailUrl);
        return writer;
    };

    PostUserProfile.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    PostUserProfile.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PostUserProfile();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.accountId = reader.string();
                    break;
                }
            case 2: {
                    message.firstName = reader.string();
                    break;
                }
            case 3: {
                    message.lastName = reader.string();
                    break;
                }
            case 4: {
                    message.vipConfigId = reader.uint64();
                    break;
                }
            case 5: {
                    message.thumbnailUrl = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("accountId"))
            throw $util.ProtocolError("missing required 'accountId'", { instance: message });
        return message;
    };

    PostUserProfile.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    PostUserProfile.fromObject = function fromObject(object) {
        if (object instanceof $root.PostUserProfile)
            return object;
        let message = new $root.PostUserProfile();
        if (object.accountId != null)
            message.accountId = String(object.accountId);
        if (object.firstName != null)
            message.firstName = String(object.firstName);
        if (object.lastName != null)
            message.lastName = String(object.lastName);
        if (object.vipConfigId != null)
            if ($util.Long)
                (message.vipConfigId = $util.Long.fromValue(object.vipConfigId)).unsigned = true;
            else if (typeof object.vipConfigId === "string")
                message.vipConfigId = parseInt(object.vipConfigId, 10);
            else if (typeof object.vipConfigId === "number")
                message.vipConfigId = object.vipConfigId;
            else if (typeof object.vipConfigId === "object")
                message.vipConfigId = new $util.LongBits(object.vipConfigId.low >>> 0, object.vipConfigId.high >>> 0).toNumber(true);
        if (object.thumbnailUrl != null)
            message.thumbnailUrl = String(object.thumbnailUrl);
        return message;
    };

    PostUserProfile.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.accountId = "";
            object.firstName = "";
            object.lastName = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.vipConfigId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.vipConfigId = options.longs === String ? "0" : 0;
            object.thumbnailUrl = "";
        }
        if (message.accountId != null && message.hasOwnProperty("accountId"))
            object.accountId = message.accountId;
        if (message.firstName != null && message.hasOwnProperty("firstName"))
            object.firstName = message.firstName;
        if (message.lastName != null && message.hasOwnProperty("lastName"))
            object.lastName = message.lastName;
        if (message.vipConfigId != null && message.hasOwnProperty("vipConfigId"))
            if (typeof message.vipConfigId === "number")
                object.vipConfigId = options.longs === String ? String(message.vipConfigId) : message.vipConfigId;
            else
                object.vipConfigId = options.longs === String ? $util.Long.prototype.toString.call(message.vipConfigId) : options.longs === Number ? new $util.LongBits(message.vipConfigId.low >>> 0, message.vipConfigId.high >>> 0).toNumber(true) : message.vipConfigId;
        if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
            object.thumbnailUrl = message.thumbnailUrl;
        return object;
    };

    PostUserProfile.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    PostUserProfile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PostUserProfile";
    };

    return PostUserProfile;
})();

export const PostComment = $root.PostComment = (() => {

    function PostComment(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    PostComment.prototype.id = "";
    PostComment.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    PostComment.prototype.type = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    PostComment.prototype.content = "";
    PostComment.prototype.author = null;

    PostComment.create = function create(properties) {
        return new PostComment(properties);
    };

    PostComment.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.id);
        writer.uint32(16).int64(message.timestamp);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(24).int64(message.type);
        if (message.content != null && Object.hasOwnProperty.call(message, "content"))
            writer.uint32(34).string(message.content);
        if (message.author != null && Object.hasOwnProperty.call(message, "author"))
            $root.PostUserProfile.encode(message.author, writer.uint32(42).fork()).ldelim();
        return writer;
    };

    PostComment.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    PostComment.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PostComment();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.timestamp = reader.int64();
                    break;
                }
            case 3: {
                    message.type = reader.int64();
                    break;
                }
            case 4: {
                    message.content = reader.string();
                    break;
                }
            case 5: {
                    message.author = $root.PostUserProfile.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        if (!message.hasOwnProperty("timestamp"))
            throw $util.ProtocolError("missing required 'timestamp'", { instance: message });
        return message;
    };

    PostComment.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    PostComment.fromObject = function fromObject(object) {
        if (object instanceof $root.PostComment)
            return object;
        let message = new $root.PostComment();
        if (object.id != null)
            message.id = String(object.id);
        if (object.timestamp != null)
            if ($util.Long)
                (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
            else if (typeof object.timestamp === "string")
                message.timestamp = parseInt(object.timestamp, 10);
            else if (typeof object.timestamp === "number")
                message.timestamp = object.timestamp;
            else if (typeof object.timestamp === "object")
                message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
        if (object.type != null)
            if ($util.Long)
                (message.type = $util.Long.fromValue(object.type)).unsigned = false;
            else if (typeof object.type === "string")
                message.type = parseInt(object.type, 10);
            else if (typeof object.type === "number")
                message.type = object.type;
            else if (typeof object.type === "object")
                message.type = new $util.LongBits(object.type.low >>> 0, object.type.high >>> 0).toNumber();
        if (object.content != null)
            message.content = String(object.content);
        if (object.author != null) {
            if (typeof object.author !== "object")
                throw TypeError(".PostComment.author: object expected");
            message.author = $root.PostUserProfile.fromObject(object.author);
        }
        return message;
    };

    PostComment.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.timestamp = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.type = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.type = options.longs === String ? "0" : 0;
            object.content = "";
            object.author = null;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.timestamp != null && message.hasOwnProperty("timestamp"))
            if (typeof message.timestamp === "number")
                object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
            else
                object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
        if (message.type != null && message.hasOwnProperty("type"))
            if (typeof message.type === "number")
                object.type = options.longs === String ? String(message.type) : message.type;
            else
                object.type = options.longs === String ? $util.Long.prototype.toString.call(message.type) : options.longs === Number ? new $util.LongBits(message.type.low >>> 0, message.type.high >>> 0).toNumber() : message.type;
        if (message.content != null && message.hasOwnProperty("content"))
            object.content = message.content;
        if (message.author != null && message.hasOwnProperty("author"))
            object.author = $root.PostUserProfile.toObject(message.author, options);
        return object;
    };

    PostComment.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    PostComment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PostComment";
    };

    return PostComment;
})();

export const SubscriptionDetails = $root.SubscriptionDetails = (() => {

    function SubscriptionDetails(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SubscriptionDetails.prototype.streamer = null;

    SubscriptionDetails.create = function create(properties) {
        return new SubscriptionDetails(properties);
    };

    SubscriptionDetails.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.streamer != null && Object.hasOwnProperty.call(message, "streamer"))
            $root.StreamerSubscriptionDetails.encode(message.streamer, writer.uint32(10).fork()).ldelim();
        return writer;
    };

    SubscriptionDetails.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SubscriptionDetails.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SubscriptionDetails();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.streamer = $root.StreamerSubscriptionDetails.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    SubscriptionDetails.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SubscriptionDetails.fromObject = function fromObject(object) {
        if (object instanceof $root.SubscriptionDetails)
            return object;
        let message = new $root.SubscriptionDetails();
        if (object.streamer != null) {
            if (typeof object.streamer !== "object")
                throw TypeError(".SubscriptionDetails.streamer: object expected");
            message.streamer = $root.StreamerSubscriptionDetails.fromObject(object.streamer);
        }
        return message;
    };

    SubscriptionDetails.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.streamer = null;
        if (message.streamer != null && message.hasOwnProperty("streamer"))
            object.streamer = $root.StreamerSubscriptionDetails.toObject(message.streamer, options);
        return object;
    };

    SubscriptionDetails.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    SubscriptionDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SubscriptionDetails";
    };

    return SubscriptionDetails;
})();

export const StreamerSubscriptionDetails = $root.StreamerSubscriptionDetails = (() => {

    function StreamerSubscriptionDetails(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StreamerSubscriptionDetails.prototype.level = 0;

    StreamerSubscriptionDetails.create = function create(properties) {
        return new StreamerSubscriptionDetails(properties);
    };

    StreamerSubscriptionDetails.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.level != null && Object.hasOwnProperty.call(message, "level"))
            writer.uint32(8).int32(message.level);
        return writer;
    };

    StreamerSubscriptionDetails.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StreamerSubscriptionDetails.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StreamerSubscriptionDetails();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.level = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    StreamerSubscriptionDetails.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StreamerSubscriptionDetails.fromObject = function fromObject(object) {
        if (object instanceof $root.StreamerSubscriptionDetails)
            return object;
        let message = new $root.StreamerSubscriptionDetails();
        if (object.level != null)
            message.level = object.level | 0;
        return message;
    };

    StreamerSubscriptionDetails.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.level = 0;
        if (message.level != null && message.hasOwnProperty("level"))
            object.level = message.level;
        return object;
    };

    StreamerSubscriptionDetails.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StreamerSubscriptionDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreamerSubscriptionDetails";
    };

    return StreamerSubscriptionDetails;
})();

export const Family = $root.Family = (() => {

    function Family(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Family.prototype.id = "";
    Family.prototype.name = "";
    Family.prototype.pictureUrl = "";

    Family.create = function create(properties) {
        return new Family(properties);
    };

    Family.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(10).string(message.id);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(18).string(message.name);
        if (message.pictureUrl != null && Object.hasOwnProperty.call(message, "pictureUrl"))
            writer.uint32(26).string(message.pictureUrl);
        return writer;
    };

    Family.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Family.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Family();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.name = reader.string();
                    break;
                }
            case 3: {
                    message.pictureUrl = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    Family.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Family.fromObject = function fromObject(object) {
        if (object instanceof $root.Family)
            return object;
        let message = new $root.Family();
        if (object.id != null)
            message.id = String(object.id);
        if (object.name != null)
            message.name = String(object.name);
        if (object.pictureUrl != null)
            message.pictureUrl = String(object.pictureUrl);
        return message;
    };

    Family.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.name = "";
            object.pictureUrl = "";
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.pictureUrl != null && message.hasOwnProperty("pictureUrl"))
            object.pictureUrl = message.pictureUrl;
        return object;
    };

    Family.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Family.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Family";
    };

    return Family;
})();

export { $root as default };
