import { createReducer } from "@reduxjs/toolkit";
import { ResponseCode } from "chat/api/chat";
import { ACME_RECEIVED } from "chat/imports/state";
import {
  AsyncState,
  addAsyncCasesToBuilderV2,
  addUserSessionScopeReducer,
  initialFetcherStateMeta,
} from "chat/messageRequest/imports/state";
import { Nullable } from "chat/messageRequest/imports/types";
import * as actions from "chat/messageRequest/state/asyncAction";
import { StoredConversation, StoredMessage } from "chat/messageRequest/types";

export type MessagesRequestState = AsyncState<{
  canLoadMore: boolean;
  conversations: Record<string, StoredConversation>;
  currentConversationId: Nullable<string>;
  messages: Record<string, StoredMessage[]>;
  totalConversationRequestCount: number;
  totalUnreadCount: number;
}>;

export const messageRequestInitialState: MessagesRequestState = {
  data: {
    currentConversationId: null,
    conversations: {},
    messages: {},
    canLoadMore: false,
    totalUnreadCount: 0,
    totalConversationRequestCount: 0,
  },
  meta: { ...initialFetcherStateMeta, stale: false },
};

const getUniqMessages = (messages: StoredMessage[]) =>
  Object.values(
    messages.reduce((acc: Record<number, StoredMessage>, next) => {
      const id = next.id.id;

      if (!acc[id]) {
        acc[id] = next;
      } else {
        acc[id] = { ...acc[id], ...next };
      }

      return acc;
    }, {})
  ).sort((a, b) => b.id.ts - a.id.ts);

const reducer = createReducer<MessagesRequestState>(
  messageRequestInitialState,
  (builder) => {
    addUserSessionScopeReducer(
      addAsyncCasesToBuilderV2({
        builder,
        action: actions.fetchMessagesRequest,
        prepareData: (prevData, nextData, meta) => {
          const { isRefreshing } = meta.arg;
          const currentData = {
            ...prevData,
            canLoadMore: isRefreshing
              ? prevData.canLoadMore
              : !!nextData.hasMoreConversations,
            totalUnreadCount: Number(nextData.totalUnreadCount),
            totalConversationRequestCount: Number(
              nextData.totalConversationRequestCount
            ),
          };

          return nextData.conversations
            ? nextData.conversations.reduce((acc, next) => {
                const { conversation, messages = [] } = next;
                const currentConversation =
                  acc.conversations[conversation.conversationId] || [];
                const currentMessages =
                  acc.messages[conversation.conversationId] || [];

                acc.conversations[conversation.conversationId] = {
                  ...currentConversation,
                  ...conversation,
                  lastMessageTs:
                    conversation.lastMessageTs !== undefined
                      ? conversation.lastMessageTs.toString()
                      : undefined,
                  lastUpdateTs:
                    conversation.lastUpdateTs !== undefined
                      ? conversation.lastUpdateTs.toString()
                      : undefined,
                };

                acc.messages[conversation.conversationId] = getUniqMessages([
                  ...currentMessages,
                  ...messages,
                ]);

                return acc;
              }, currentData)
            : currentData;
        },
        initialData: messageRequestInitialState.data,
      })
        .addCase(actions.setCurrentConversationId, (state, action) => {
          state.data.currentConversationId = action.payload;
        })
        .addCase(actions.removeRequestsConversation, (state, action) => {
          delete state.data.conversations[action.payload];
          delete state.data.messages[action.payload];
        })
        .addCase(
          actions.fetchConversationMessageRequest.pending,
          (state, action) => {
            const { conversationId } = action.meta.arg;
            const currentConversation = state.data.conversations[
              conversationId
            ] || {
              conversation_id: conversationId,
              account_info: {
                account_id: conversationId,
              },
            };
            state.data.conversations[conversationId] = Object.assign(
              currentConversation,
              { isLoading: true, isLoadingFailed: false }
            );
          }
        )
        .addCase(
          actions.fetchConversationMessageRequest.fulfilled,
          (state, action) => {
            const { conversation_id: conversationId } =
              action.payload?.conversation?.conversation || {};
            if (!conversationId) {
              return state;
            }

            if (action.payload?.conversation) {
              const { conversation, has_more_messages, messages } =
                action.payload.conversation;
              state.data.conversations[conversationId] = Object.assign(
                state.data.conversations[conversationId] || {},
                conversation,
                {
                  hasMoreMessages: has_more_messages,
                  isLoading: false,
                  isLoadingFailed: false,
                }
              );

              if (messages) {
                state.data.messages[conversationId] = getUniqMessages([
                  ...(state.data.messages[conversationId] || []),
                  ...messages,
                ]);
              }

              return state;
            }

            if (
              action.payload.status.code ===
              ResponseCode.RESPONSE_STATUS_CODE_MESSAGE_NONEXISTENT
            ) {
              state.data.conversations[conversationId] = Object.assign(
                state.data.conversations[conversationId] || {},
                {
                  isLoading: false,
                  isLoadingFailed: false,
                }
              );
            }
          }
        )
        .addCase(actions.readMessageRequest.fulfilled, (state, action) => {
          const { conversation_id } = action.meta.arg;
          const {
            unreadCount,
            totalUnreadCount,
            status: { timestamp },
          } = action.payload;
          state.data.totalUnreadCount = Number(totalUnreadCount);
          state.data.conversations[conversation_id] = Object.assign(
            state.data.conversations[conversation_id],
            {
              unreadMessageCount: unreadCount,
              lastSelfReadMessageTs: timestamp,
            }
          );
        })
        .addCase(actions.removeMessagesRequest.pending, (state) => {
          state.meta.loading = true;
        })
        .addCase(actions.removeMessagesRequest.fulfilled, (state, action) => {
          if (action.meta.arg.conversationId) {
            delete state.data.conversations[action.meta.arg.conversationId];
            delete state.data.messages[action.meta.arg.conversationId];
          }
          state.meta.loading = false;
        })
        .addCase(actions.deleteAllMessageRequests.fulfilled, (state) => {
          state.data.conversations = {};
        })
        .addMatcher(
          (action) => action.type === ACME_RECEIVED,
          (state) => {
            state.meta.stale = true;
          }
        ),
      () => messageRequestInitialState
    );
  }
);

export default reducer;
