import React, { useCallback, memo, useMemo } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { useBreakpoint } from "src/ui/hooks/useBreakpoint";
import { Breakpoints } from "src/enums";
import emptyFunction from "fbjs/lib/emptyFunction";
import { ReactComponent as PlusIcon } from "img/add_32.svg";
import { ReactComponent as MinusIcon } from "img/minus_32.svg";
import Typography, {
  TYPOGRAPHY_TYPE,
} from "src/ui/common/typography/Typography";
import styles from "./LegalAccordionItem.scss";

type AccordionItemProps = {
  to: string;
  message: MessageDescriptor;
  onClick?: (id: string) => void;
  expanded?: boolean;
  active?: boolean;
  dataTestId?: string;
};

const LegalAccordionItem: React.FC<AccordionItemProps> = ({
  to,
  message,
  onClick = emptyFunction,
  expanded,
  active = false,
  dataTestId,
}) => {
  const breakpoint = useBreakpoint();
  const as: React.ElementType = active ? "li" : Link;

  const typographyType = useMemo(() => {
    if (breakpoint === Breakpoints.TABLET) {
      return active ? TYPOGRAPHY_TYPE.HEADLINE4 : TYPOGRAPHY_TYPE.PARAGRAPH2;
    }

    return active ? TYPOGRAPHY_TYPE.HEADLINE5 : TYPOGRAPHY_TYPE.PARAGRAPH3;
  }, [active, breakpoint]);

  const handleClick = useCallback(() => {
    onClick(to);
  }, [to, onClick]);

  return (
    <Typography
      type={typographyType}
      as={as}
      to={!active ? to : null}
      onClick={handleClick}
      className={classnames(
        styles.accordionItem,
        styles[breakpoint],
        active && styles.active
      )}
      data-testid={dataTestId}
    >
      <FormattedMessage {...message} />
      {active && (expanded ? <MinusIcon /> : <PlusIcon />)}
    </Typography>
  );
};

export default memo(LegalAccordionItem);
