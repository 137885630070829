import { EventFields, SearchParams } from "@analytics/enums";
import { getTwitterPurchaseId } from "environment";
import {
  AXON_OFFER_QUANTITY,
  AxonEventFields,
  AxonEventNames,
  AxonMethods,
} from "src/core/analytics/enumsAxon";
import {
  FBEventFields,
  FBEventNames,
  FBMethods,
} from "src/core/analytics/enumsFB";
import {
  MicrosoftAdsEventFields,
  MicrosoftAdsEventNames,
  MicrosoftAdsMethods,
} from "src/core/analytics/enumsMSAds";
import {
  SnapchatEventFields,
  SnapchatEventNames,
  SnapchatMethods,
} from "src/core/analytics/enumsSnapchat";
import {
  TOK_TOK_CONTENT_TYPE,
  TikTokEventFields,
  TikTokEventNames,
} from "src/core/analytics/enumsTikTok";
import {
  TwitterEventFields,
  TwitterMethods,
} from "src/core/analytics/enumsTwitter";
import { Transaction } from "src/core/analytics/types";
import { marketingSelectors } from "src/features/marketing/exports/state";
import { userSelectors } from "state/selectors";
import { getInMemoryStore } from "utils/getStoreHandler";
import { omitUndefinedProps } from "utils/omitUndefinedProps";

const CURRENCY = "USD";

export const emitMarketingPurchaseSuccess = (
  selectedTransaction: Transaction
) => {
  const userHashedInfo =
    marketingSelectors.getUserHashedInfo(getInMemoryStore());
  const snapchatClickId = localStorage.getItem(SearchParams.SNAPCHAT);
  const accountId = userSelectors.getMyAccountId(getInMemoryStore());

  window.fbq?.(
    FBMethods.TRACK,
    FBEventNames.PURCHASE,
    {
      [FBEventFields.CURRENCY]: CURRENCY,
      [FBEventFields.VALUE]: selectedTransaction.amount_usd,
    },
    {
      [FBEventFields.EVENT_ID]: selectedTransaction.tango_transaction_id,
    }
  );

  window.twq?.(
    TwitterMethods.EVENT,
    getTwitterPurchaseId(),
    omitUndefinedProps({
      currency: CURRENCY,
      value: selectedTransaction.amount_usd,
      [TwitterEventFields.CLIENT_DEDUP_ID]:
        selectedTransaction.tango_transaction_id,
      [TwitterEventFields.EMAIL_ADDRESS]: userHashedInfo?.email,
      [TwitterEventFields.PHONE_NUMBER]: userHashedInfo?.phoneNumber
        ? `+${userHashedInfo?.phoneNumber}`
        : undefined,
    })
  );

  window.snaptr?.(SnapchatMethods.TRACK, SnapchatEventNames.PURCHASE, {
    [EventFields.TRANSACTION_ID]: selectedTransaction.tango_transaction_id,
    [SnapchatEventFields.CLIENT_DEDUP_ID]:
      selectedTransaction.tango_transaction_id,
    [SnapchatEventFields.HASHED_PHONE]: userHashedInfo?.hashedPhoneNumber,
    [SnapchatEventFields.HASHED_EMAIL]: userHashedInfo?.hashedEmail,
    [SnapchatEventFields.CLICK_ID]: snapchatClickId,
  });

  window.uetq?.push(
    MicrosoftAdsMethods.EVENT,
    MicrosoftAdsEventNames.PURCHASE,
    {
      [MicrosoftAdsEventFields.REVENUE_VALUE]: selectedTransaction.amount_usd,
      [MicrosoftAdsEventFields.CURRENCY]: CURRENCY,
      pid: {
        [MicrosoftAdsEventFields.EMAIL]: userHashedInfo?.email,
        [MicrosoftAdsEventFields.PHONE_NUMBER]: userHashedInfo?.phoneNumber
          ? `+${userHashedInfo?.phoneNumber}`
          : undefined,
      },
    }
  );

  window.axon?.(AxonMethods.TRACK, AxonEventNames.PURCHASE, {
    [AxonEventFields.CURRENCY]: CURRENCY,
    [AxonEventFields.TRANSACTION_ID]: selectedTransaction.tango_transaction_id,
    [AxonEventFields.USER_ID]: accountId,
    [AxonEventFields.VALUE]: selectedTransaction.amount_usd,
    [AxonEventFields.ITEMS]: [
      {
        [AxonEventFields.ITEM_ID]: selectedTransaction.server_offer_id,
        [AxonEventFields.ITEM_NAME]: selectedTransaction.personal_offer_type,
        [AxonEventFields.PRICE]: selectedTransaction.amount_usd,
        [AxonEventFields.QUANTITY]: AXON_OFFER_QUANTITY,
      },
    ],
  });

  window.ttq?.identify({
    [TikTokEventFields.EMAIL]: userHashedInfo?.email || "",
    [TikTokEventFields.PHONE_NUMBER]: `+${userHashedInfo?.phoneNumber}` || "",
  });

  window.ttq?.track(
    TikTokEventNames.COMPLETE_PAYMENT,
    {
      contents: [
        {
          [TikTokEventFields.CONTENT_ID]: selectedTransaction.server_offer_id,
          [TikTokEventFields.CONTENT_TYPE]: TOK_TOK_CONTENT_TYPE,
        },
      ],
      [TikTokEventFields.VALUE]: selectedTransaction.amount_usd,
      [TikTokEventFields.CURRENCY]: CURRENCY,
    },
    {
      [TikTokEventFields.EVENT_ID]: selectedTransaction.tango_transaction_id,
    }
  );
};
