import {
  CHAT_CONVERSATIONS_LIST_DEFAULT_MESSAGES_LIMIT,
  CHAT_CONVERSATIONS_PAGE_SIZE,
} from "chat/imports/constants";
import { getProxycadorHost } from "chat/imports/environment";
import { enhancedFetch as fetch } from "chat/imports/utils";
import {
  Conversation,
  ConversationState,
  GetMessageTranslationParams,
  MessageIdentifier,
  MessageMedia,
} from "chat/types";

export enum Direction {
  FORWARD = "FORWARD",
  REVERSE = "REVERSE",
}

export enum ParamConversationState {
  ACTIVE,
  CHAT_REQUEST,
}

export enum ApiMessageType {
  AUDIO_MESSAGE = 2,
  DEEPLINK_MESSAGE = 59, // message sent by SCREAM (???), check if needed
  DEVICE_LOGIN_INFO_MESSAGE = 111,
  FAMILY_INVITE_MESSAGE = 104,
  GIF_MESSAGE = 117,
  GIFT_IN_CHAT = 18, // using payload
  GO_LIVE_TO_BC_MESSAGE = 101, // no payload
  GROUP_MEMBER_JOIN = 11,
  GROUP_MEMBER_LEAVE = 12,
  GROUP_NAME_CHANGE = 13,
  GROUP_PICTURE_CHANGE = 114,
  GROUP_SETTING_CHANGED = 116,
  GROUP_UPDATE = 17, // invisible, used only to notify client group muted setting is changed via regular ts-based request
  IMAGE_MESSAGE = 3,
  KYC_VERIFICATION_REQUESTED = 112,
  LIKE_MESSAGE = 61, // invisible, returned separately
  LIVE_STREAM = 89, // chat stream message, no payload
  MISSED_CALL_MESSAGE = 36,
  NORMAL_CALL_MESSAGE = 35,
  PHOTO_SAVED_INFO_MESSAGE = 108,
  PREMIUM_MESSAGE_SHARED = 113,
  PROFILE_MESSAGE = 34, // share profile message, using payload
  REFERRAL_MESSAGE = 110,
  SCREENSHOT_INFO_MESSAGE = 107,
  SDK_EXTERNAL_MESSAGE = 20, // used to sent messages from SCREAM, SUBSCRIPTION
  SOCIAL_POST_MESSAGE = 33, // feed repost message, using payload
  STICKER_MESSAGE = 58, // regular sticker, using payload
  SUBSCRIPTION = 100, // !!! payload is sent via BODY as JSON
  TANGO_SURPRISE_MESSAGE = 5, // animated sticker message, using payload
  TEXT_MESSAGE = 0,
  TEXT_MESSAGE_WITH_LINK = 115,
  UNKNOWN = 127,
  UNSUPPORTED = 126,
  VIDEO_MESSAGE = 1,
  VIDEO_SAVED_INFO_MESSAGE = 109,
  VIP_ASSIGNMENT_MESSAGE = 103,

  VOICE_MESSAGE_2 = 102,
}

export enum ResponseCode {
  RESPONSE_STATUS_CODE_OK,
  // Request is not authenticated
  RESPONSE_STATUS_CODE_UNAUTHENTICATED,
  // User is not allowed to perform the requested operation because not a group member
  RESPONSE_STATUS_CODE_UNAUTHORIZED_NOT_GROUP_MEMBER,
  // Group cannot be found in DB
  RESPONSE_STATUS_CODE_NONEXISTENT_GROUP,
  // Group id cannot be decoded
  RESPONSE_STATUS_CODE_INVALID_GROUP_ID,
  // Account id cannot be decoded
  RESPONSE_STATUS_CODE_INVALID_ACCOUNT_ID,
  // Peer id (account id or group id) cannot be decoded
  RESPONSE_STATUS_CODE_INVALID_PEER_ID,
  // Request not well formed
  RESPONSE_STATUS_CODE_INVALID_REQUEST,
  // User is not allowed to perform the requested operation
  RESPONSE_STATUS_CODE_UNAUTHORIZED,
  // User is blocked from sending to account
  RESPONSE_STATUS_CODE_BLOCKED,
  // Group size too big
  RESPONSE_GROUP_SIZE_TOO_BIG,
  // Message not found
  RESPONSE_STATUS_CODE_MESSAGE_NONEXISTENT,
  // Could not add user to group
  RESPONSE_STATUS_CODE_UNABLE_TO_ADD_USER,
  // Current message is sent but the next one would be blocked
  RESPONSE_STATUS_CODE_MESSAGE_QUOTA_EXCEEDED,
  // Server error
  RESPONSE_STATUS_CODE_SVR_ERR = 100,
}

type ResponseStatus = {
  code: ResponseCode;
  message?: string;
  timestamp: number;
};

export type GetConversationsParams = {
  direction: Direction;
  include_account_info?: boolean;
  include_group_info?: boolean;
  include_group_members?: boolean;
  include_messages?: boolean;
  last_update_request_timestamp?: number;
  limit_conversations?: number;
  limit_messages_per_conversation?: number;
  state?: ParamConversationState;
};

export type GetConversationsResponse = {
  conversations?: Conversation[];
  has_more_conversations?: boolean;
  status: ResponseStatus;
  total_conversation_request_count?: number;
  total_unread_count?: number;
};

export const getConversations = (
  {
    direction,
    // 0 - when using direction FORWARD only.
    last_update_request_timestamp,
    limit_conversations = CHAT_CONVERSATIONS_PAGE_SIZE,
    limit_messages_per_conversation = CHAT_CONVERSATIONS_LIST_DEFAULT_MESSAGES_LIMIT,
    ...rest
  }: GetConversationsParams = {} as GetConversationsParams
): Promise<GetConversationsResponse> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/chats/v1/conversations/get`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        direction,
        last_update_request_timestamp,
        limit_conversations,
        limit_messages_per_conversation,
        ...rest,
      }),
    }
  ).then((resp) => resp.json());

export type GetConversationParams = {
  direction?: Direction; // FORWARD or REVERSE, should NOT be set when range is used, i.e. both start_timestamp and end_timestamp are provided
  end_timestamp?: number; // used in range or when the direction = REVERSE
  include_group_info?: boolean;
  include_group_members?: boolean;
  limit?: number; // -1 - no limit
  start_timestamp?: number; // used in range or when the direction = FORWARD
};

export type GetConversationResponse = {
  conversation?: Conversation;
  status: ResponseStatus;
};

export const getConversation = (
  conversationId: string,
  params: GetConversationParams
): Promise<GetConversationResponse> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/chats/v1/messages/get?conversationId=${conversationId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(params),
    }
  ).then((resp) => resp.json());

export type ReadConversationParams = {
  conversation_id: string;
  last_read_message_ts: number;
  notify_sender?: boolean;
};

export type ReadConversationResponse = {
  status: ResponseStatus;
  total_unread_count?: number;
  unread_count?: number;
};

export const readConversationMessages = (
  params: ReadConversationParams
): Promise<ReadConversationResponse> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/chats/v1/messages/read`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(params),
    }
  ).then((resp) => resp.json());

export type SendMessage = {
  body?: string;
  forwardInfo?: {
    from_account_id?: string;
    from_account_name?: string;
  };
  media?: MessageMedia[];
  message_type: ApiMessageType;
  payload?: string;
  reply_message_id?: MessageIdentifier;
  sequence?: number;
};

export type SendMessageParams = {
  messages: SendMessage[];
  options?: {
    store_message_for_recipient?: boolean;
    store_message_for_sender?: boolean;
  };
  to: string[];
};

export type SendMessageResponse = {
  details?: {
    id?: MessageIdentifier;
    sequence?: number;
    state?: ConversationState;
  }[];
  status: ResponseStatus;
};

export const sendMessage = (
  params: SendMessageParams
): Promise<SendMessageResponse> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/chats/v1/messages/send`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(params),
    }
  ).then((resp) => resp.json());

export type GetMessageTranslationResponse = {
  code: string;
  translated: string;
};

export const detectMessageLang = async ({
  message,
  locale,
}: Omit<GetMessageTranslationParams, "conversationId" | "messageId">) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/translator/v1/detectLanguage`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        text: message,
        deviceLocale: locale,
        suggestedLanguageISO: locale,
      }),
    }
  ).then((resp) => resp.json());

export const getMessageTranslation = async ({
  message,
  locale,
}: GetMessageTranslationParams): Promise<GetMessageTranslationResponse> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/translator/v1/stream/client/v2/chat/translate`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ text: message, language: locale }),
    }
  ).then((resp) => resp.json());

export const getMessageTranslationOnlineChat = async ({
  message,
  locale,
}: Omit<
  GetMessageTranslationParams,
  "conversationId" | "messageId" | "senderId"
>): Promise<GetMessageTranslationResponse> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/translator/v1/client/v2/stream/translate?language=${locale}&text=${message}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    }
  ).then((resp) => resp.json());
