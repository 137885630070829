import React from "react";
import classnames from "classnames";
import Typography, {
  TYPOGRAPHY_TYPE,
} from "src/ui/common/typography/Typography";
import styles from "./LegalTypography.scss";

type ParagraphProps = {
  subParagraph?: boolean;
  subSubParagraph?: boolean;
};

const Paragraph: React.FC<ParagraphProps> = ({
  subParagraph,
  subSubParagraph,
  children,
}) => (
  <Typography
    type={TYPOGRAPHY_TYPE.PARAGRAPH4}
    as="p"
    className={classnames(styles.paragraph, {
      [styles.subParagraph]: subParagraph,
      [styles.subSubParagraph]: subSubParagraph,
    })}
  >
    {children}
  </Typography>
);

export default Paragraph;
