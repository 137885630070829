import React, { memo } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import Typography, {
  TYPOGRAPHY_TYPE,
} from "src/ui/common/typography/Typography";
import styles from "./LegalNavigationItem.scss";

interface LegalNavigationItemProps {
  to: string;
  selected?: boolean;
  message: MessageDescriptor;
  dataTestId?: string;
}

const LegalNavigationItem: React.FC<LegalNavigationItemProps> = ({
  to,
  message,
  selected,
  dataTestId,
}) => (
  <Typography
    type={selected ? TYPOGRAPHY_TYPE.HEADLINE4 : TYPOGRAPHY_TYPE.PARAGRAPH2}
    as={Link}
    to={to}
    className={classnames(
      styles.tab,
      selected ? styles.active : styles.inactive
    )}
    data-testid={dataTestId}
  >
    <FormattedMessage {...message} />
  </Typography>
);

export default memo(LegalNavigationItem);
