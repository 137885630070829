import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Location } from "history";
import tangoAnalytics from "src/core/analytics";
import {
  EventFields,
  EventNames,
  Platforms,
  StorageKeys,
} from "src/core/analytics/enums";
import { createObservable } from "src/core/analytics/observable";
import { emitBIPageViewSuccess } from "src/core/analytics/utils/emitBIPageViewSuccess";
import { sendMarketingEventFlow } from "src/features/marketing/state/flow";
import { getBIHistoryListenEnabled } from "state/abTests";
import { loginSelectors } from "state/selectors";

const historyListenObservable = createObservable(false);
export { historyListenObservable };

export default () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isBIHistoryListenEnabled = useSelector(getBIHistoryListenEnabled);
  const isAuthorized = useSelector(loginSelectors.isAuthorized);
  const isLoggedIn = useSelector(loginSelectors.isLoggedIn);

  const handleTrackAnalytics = useCallback((screen: string) => {
    tangoAnalytics.then((analytics) => {
      const localData = analytics.Local.getItem(StorageKeys.ROUTE_DATA);
      const sessionData = analytics.Session.getItem(StorageKeys.ROUTE_DATA);
      analytics.Session.setItem(StorageKeys.ROUTE_DATA, {
        [EventFields.TANGO_SCREEN]: screen,
        [EventFields.TANGO_PREVIOUS_SCREEN]:
          sessionData?.[EventFields.TANGO_SCREEN],
      });

      analytics.track(EventNames.PAGE, {
        ...localData,
        ...sessionData,
        [EventFields.TANGO_SCREEN]: screen,
        [EventFields.TANGO_PLATFORM]: Platforms.WEB,
        [EventFields.TANGO_VERSION]: window.twcVersion,
      });
    });
  }, []);

  useEffect(() => {
    if (!isBIHistoryListenEnabled) {
      return;
    }

    if (isAuthorized || isLoggedIn) {
      dispatch(sendMarketingEventFlow(emitBIPageViewSuccess));
    }
  }, [isBIHistoryListenEnabled, dispatch, isAuthorized, isLoggedIn, location]);

  useEffect(() => {
    if (!isBIHistoryListenEnabled) {
      return;
    }

    if (location.pathname.includes("stream")) {
      let sendLocation: Location;
      historyListenObservable.subscribe((idReadyToGTM) => {
        if (idReadyToGTM && sendLocation !== location) {
          handleTrackAnalytics(location.pathname);
          sendLocation = location;
        }
      });

      return;
    }

    handleTrackAnalytics(location.pathname);
  }, [handleTrackAnalytics, isBIHistoryListenEnabled, location]);
};
