import React, { Component, ErrorInfo } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import { getSendErrorsToDatadog } from "environment";
import ErrorView from "ui/common/errorView/ErrorView";
import { toggleSplashScreen } from "ui/hooks/useSplashScreen";

interface ErrorBoundaryProps {
  errorView?: React.ReactNode;
  renderErrorView?: boolean;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = { hasError: false };

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    if (getSendErrorsToDatadog()) {
      datadogLogs.logger.error(error.message, info);
    }
  }

  componentDidUpdate() {
    if (this.state.hasError || this.props.renderErrorView) {
      toggleSplashScreen(false);
    }
  }

  render() {
    const { hasError } = this.state;
    const { children, renderErrorView, errorView } = this.props;

    return hasError || renderErrorView
      ? errorView || <ErrorView testId="ui-error" />
      : children;
  }
}
