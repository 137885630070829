import { Nullable } from "src/types/common";
import { EmitChatMessageSentParams } from "./emitChatMessageSent";

export enum MessageStatusResult {
  FAILURE = 0,
  SUCCESS = 1,
}

export enum ChatMessageSentFlags {
  AUDIO = "audio",
  GIFT = "gift",
  IMAGE = "image",
  PROFILE = "profile",
  TEXT = "text",
  VIDEO = "video",
}

export type ChatMessageSentReason = Nullable<string>;

export enum ConversationScreenState {
  CHAT_REQUEST = "chat_request",
  ONE_ON_ONE_CHAT = "1on1_chat",
}

export type ChatMessageAnalyticsParams = Omit<
  EmitChatMessageSentParams["params"],
  "reason" | "result"
>;

export enum SINGLE_CHAT_MESSAGE_SENT_FLAGS {
  GIFT = "gift",
}

export enum SINGLE_CHAT_MESSAGE_SENT_ANCHOR_ID {
  PREMIUM_MESSAGE = "premium_message",
}

export enum SINGLE_CHAT_MESSAGE_SENT_ANCHOR_TYPE {
  RECEIVED = "received",
  SENT = "sent",
}
