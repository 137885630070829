import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { messages } from "src/features/signin/components/CodeRecipientMessage/messages";
import { loginSelectors } from "src/features/signin/imports/state";
import { ReactComponent as WhatsAppIcon } from "img/login/whatsapp.svg";
import styles from "./CodeRecipientMessage.scss";

enum CodeProvider {
  WHATSAPP = "wassup",
}

export const CodeRecipientMessage = () => {
  const { allowOnlySmsValidation, codeProvider, phoneNumber } = useSelector(
    loginSelectors.getPhoneNumberAuthenticationState
  );

  const codeWasSentViaTango = !allowOnlySmsValidation;
  const codeWasSentViaWhatsApp = codeProvider === CodeProvider.WHATSAPP;

  if (codeWasSentViaTango) {
    return (
      <FormattedMessage
        {...messages.codeWasSentViaTango}
        values={{
          tangoApp: <FormattedMessage {...messages.tangoApp} />,
          tangoChat: <FormattedMessage {...messages.tangoChat} />,
        }}
      />
    );
  }

  if (codeWasSentViaWhatsApp) {
    return (
      <FormattedMessage
        {...messages.codeWasSentViaWhatsApp}
        values={{
          phoneNumber,
          provider: (
            <>
              <br />
              <span className={styles.codeProvider}>
                <WhatsAppIcon /> <FormattedMessage {...messages.whatsApp} />
              </span>
            </>
          ),
        }}
      />
    );
  }

  return (
    <FormattedMessage
      {...messages.codeWasSentViaSms}
      values={{ phoneNumber }}
    />
  );
};
